export class Operant {
    static readonly EQUALS: Operant = new Operant('EQUALS', 'Is equal to', '');

    static readonly NOT_EQUALS: Operant = new Operant('NOT_EQUALS', 'Is not equal to', 'not', Operant.EQUALS);

    static readonly GREATER_THAN = new Operant('GREATER_THAN', 'Greater than', 'greater than');

    static readonly GREATER_THAN_EQUALS = new Operant(
        'GREATER_THAN_EQUALS',
        'is greater than or equal to',
        'greater than or equal to',
    );

    static readonly LESS_THAN = new Operant('LESS_THAN', 'Less than', 'less than');

    static readonly LESS_THAN_EQUALS = new Operant(
        'LESS_THAN_EQUALS',
        'is less than or equal to',
        'less than or equal to',
    );

    static readonly CONTAINS = new Operant('CONTAINS', 'Contains', 'containing');

    static readonly NOT_CONTAINS = new Operant('NOT_CONTAINS', 'Does not contain', 'not containing', Operant.CONTAINS);

    static readonly STARTS_WITH = new Operant('STARTS_WITH', 'Starts with', 'starting with');

    static readonly ENDS_WITH = new Operant('ENDS_WITH', 'Ends with', 'ending with');

    readonly key: string;

    readonly displayLabel: string;

    readonly policyLabel: string;

    readonly opposite: Operant | null;

    constructor(key: string, displayLabel: string, policyLabel: string, opposite: Operant | null = null) {
        this.key = key;
        this.policyLabel = policyLabel;
        this.displayLabel = displayLabel;
        this.opposite = opposite;
    }

    static all(): Operant[] {
        return [
            this.EQUALS,
            this.NOT_EQUALS,
            // this.GREATER_THAN_EQUALS,
            // this.GREATER_THAN,
            // this.LESS_THAN_EQUALS,
            // this.LESS_THAN,
            // this.CONTAINS,
            // this.NOT_CONTAINS,
            // this.STARTS_WITH,
            // this.ENDS_WITH,
        ];
    }

    getOpposite(): Operant | null {
        if (this.opposite) {
            return this.opposite;
        }
        for (let i = 0; i < Operant.all().length; i++) {
            const operant = Operant.all()[i];
            if (operant.opposite === this) {
                return operant;
            }
        }

        return null;
    }

    static find(key: string): Operant | null {
        for (let i = 0; i < this.all().length; i++) {
            const operant = this.all()[i];
            if (operant.key === key) {
                return operant;
            }
        }

        return null;
    }
}
