import { AxiosRequestConfig } from 'axios';

const endpoint = '/api/mqtt';

export default {
    resetPassword: (username: string, jobId: number) => {
        return {
            method: 'PUT',
            url: `${endpoint}/users/${username}/jobs/${jobId}/reset-password`,
        } as AxiosRequestConfig;
    },
    testCredentialsAndCreateSample: (data: any, fileType: string) =>
        ({
            method: 'POST',
            url: `${endpoint}/user/consume/${fileType}`,
            data,
        } as AxiosRequestConfig),
};
