





































































































import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'ContractSteps',
    props: {
        currentStep: {
            type: Number,
            default: 0,
        },
        isConsumer: {
            type: Boolean,
            default: true,
        },
        inPreview: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        actionRequired: {
            type: Boolean,
            default: false,
        },
        noPayment: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const plural = props.multiple && !props.inPreview ? 's' : '';

        const actionPerformed = (step: number) => {
            if (step === 3) return false;
            return props.currentStep > step;
        };

        const consumerSteps = computed(() => [
            { name: actionPerformed(0) ? `Request${plural} Created` : `Create Request${plural}` },
            { name: actionPerformed(1) ? `Draft Contract${plural} Reviewed` : `Review Draft Contract${plural}` },
            { name: actionPerformed(2) ? `Contract${plural} Signed` : `Sign Contract${plural}` },
            {
                name: actionPerformed(3)
                    ? `Contract${plural} ${props.noPayment ? 'Activated' : 'Paid'}`
                    : `${props.noPayment ? 'Activate' : 'Pay'} Contract${plural}`,
            },
        ]);

        const providerSteps = computed(() => [
            { name: actionPerformed(0) ? `Request${plural} Reviewed` : `Review Request${plural}` },
            { name: actionPerformed(1) ? `Draft Contract${plural} Created` : `Create Draft Contract${plural}` },
            { name: actionPerformed(2) ? `Contract${plural} Signed` : `Sign Contract${plural}` },
            {
                name: actionPerformed(3)
                    ? `Contract${plural} ${props.noPayment ? 'Activated' : 'Paid'}`
                    : `${props.noPayment ? `Activate Contract${plural}` : 'Receive Payment'}`,
            },
        ]);

        const steps = computed(() => (props.isConsumer ? consumerSteps.value : providerSteps.value));

        const percentage = computed(() => {
            switch (props.currentStep) {
                case 0:
                    return '7%';
                case 1:
                    return '32%';
                case 2:
                    return '55%';
                case 3:
                    return '80%';
                default:
                    return '0%';
            }
        });

        return { steps, percentage, actionPerformed };
    },
});
