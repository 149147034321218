















import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
    name: 'LicenseDetail',
    props: {
        oldValues: {
            type: Array as PropType<string[]>,
            default: null,
        },
        newValues: {
            type: Array as PropType<string[]>,
            required: true,
        },
        options: {
            type: Array as PropType<string[]>,
            required: true,
        },
    },
    setup(props) {
        const inOld = (value: string) => props.oldValues?.includes(value);
        const inNew = (value: string) => props.newValues.includes(value);

        return { inOld, inNew };
    },
});
