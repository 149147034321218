










import Multiselect from 'vue-multiselect';
import { ref } from '@vue/composition-api';

export default {
    components: {
        Multiselect,
    },
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const options = ref<any>(props.context.options.map((option: any) => option.value));

        const addTag = (newTag: any) => {
            if (!props.context.model) {
                // eslint-disable-next-line no-param-reassign
                props.context.model = [];
            }
            options.value.push(newTag);
            props.context.model.push(newTag);
        };

        const removeTag = (tag: any) => {
            if (props.context.attributes.removeTags) {
                options.value.splice(options.value.indexOf(tag), 1);
            }
        };

        return { options, addTag, removeTag };
    },
};
