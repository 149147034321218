



import { CashIcon, ExclamationCircleIcon, LibraryIcon, ReceiptTaxIcon, RefreshIcon } from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent } from '@vue/composition-api';
import { ReimbursementMethod, ReimbursementMethodText } from '../constants';

export default defineComponent({
    name: 'ReimbursementMethodIcon',
    props: {
        type: { type: String as PropType<ReimbursementMethod>, required: true },
        classes: { type: String, default: 'w-5 h-5 text-secondary-800' },
    },
    components: { LibraryIcon, ReceiptTaxIcon, RefreshIcon, CashIcon, ExclamationCircleIcon },
    setup(props) {
        const info = computed(() => {
            switch (props.type) {
                case ReimbursementMethod.Bank:
                    return { icon: LibraryIcon, text: ReimbursementMethodText[ReimbursementMethod.Bank] };
                case ReimbursementMethod.Online:
                    return { icon: ReceiptTaxIcon, text: ReimbursementMethodText[ReimbursementMethod.Online] };
                case ReimbursementMethod.Bartering:
                    return { icon: RefreshIcon, text: ReimbursementMethodText[ReimbursementMethod.Bartering] };
                default:
                    return { icon: ExclamationCircleIcon, text: `Unknown reimbursement method: ${props.type}` };
            }
        });

        return { info };
    },
});
