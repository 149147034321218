














































































































































































































import { DataModelTree, FormBlock } from '@/app/components';
import { useFeatureFlags } from '@/app/composable';
import { AccessLevel } from '@/modules/access-policy/constants/access-levels.constants';
import { useAssetMetadata } from '@/modules/asset/composable/asset-metadata';
import { AssetTypeId } from '@/modules/asset/constants';
import { ModelAPI } from '@/modules/data-checkin/api';
import { useCleaning, useMappingTransformations } from '@/modules/data-checkin/composable';
import { useAxios } from '@/app/composable';
import { FilterIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as R from 'ramda';
import FiltersModal from './FiltersModal.vue';

dayjs.extend(utc);

export default defineComponent({
    name: 'AssetFields',
    components: {
        FormBlock,
        DataModelTree,
        FiltersModal,
        FilterIcon,
    },
    model: {
        prop: 'contract',
    },
    props: {
        fields: {
            type: Array,
            default: () => [],
        },
        contract: {
            type: Object,
            required: true,
        },
        updatingContract: {
            type: Object,
            default: null,
        },
        asset: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const { exec } = useAxios(true);

        const { isEnabled } = useFeatureFlags();
        const { domains, createTreeStructure } = useAssetMetadata();
        const { getTransformations, getTransformationText } = useMappingTransformations();
        const { getConstraintText, getOutliersRuleText } = useCleaning();

        const addingFiltersTo = reactive({ name: '', type: '', filters: {} });

        const previewRules = ref<Array<string>>([]);
        const dataStructure = ref<any>(null);
        const processingRules = ref<any>({});
        const filters = ref<any>({});
        const initialFilters = ref<any>({});
        const filtersText = ref<any>({});
        const initialFiltersText = ref<any>({});
        const showFiltersModal = ref<boolean>(false);
        const loadingRules = ref<boolean>(true);
        const resultFields = ref<any[]>([]);
        const loadingFields = ref<boolean>(false);
        const flatStructure = ref<any>(null);
        const initialSelectedFields = ref<any>(props.contract.fields.map((field: any) => field.name));

        const selectedFields = computed(() => props.contract.fields.map((field: any) => field.name));

        const extendedFields = computed(() =>
            props.updatingContract ? props.updatingContract.fields.map((field: any) => field.name) : [],
        );

        const title = computed(() => {
            switch (props.asset.assetTypeId) {
                case AssetTypeId.Dataset:
                    return 'Dataset Info';
                case AssetTypeId.Result:
                    return 'Result Info';
                default:
                    return 'Info';
            }
        });

        const description = computed(() => {
            switch (props.asset.assetTypeId) {
                case AssetTypeId.Dataset:
                    return 'Details about the data asset structure and processing rules that have been applied on the data. You need to select what fields you would like to acquire.';
                case AssetTypeId.Result:
                    return 'Details about the result structure.';
                default:
                    return null;
            }
        });

        const getFilterText = (filter: any) => {
            if (filter && filter.type === 'datetime')
                return `${dayjs.utc(filter.from).format('DD/MM/YYYY HH:mm')}-${dayjs
                    .utc(filter.to)
                    .format('DD/MM/YYYY HH:mm')}`;
            if (filter && filter.type === 'date')
                return `${dayjs(filter.from).format('DD/MM/YYYY')}-${dayjs(filter.to).format('DD/MM/YYYY')}`;
            return 'All values';
        };

        const initializeFilters = () => {
            filters.value = {};
            filtersText.value = {};
            props.contract.fields.forEach((field: any) => {
                filters.value[field.name] = field.filters;
                initialFilters.value[field.name] = R.clone(field.filters);
                filtersText.value[field.name] = getFilterText(field.filters);
                initialFiltersText.value[field.name] = R.clone(filtersText.value[field.name]);
            });
        };

        const initialize = async (domainId: number) => {
            loadingFields.value = true;
            const { schema, primaryConcept } = props.asset.structure;
            await exec(ModelAPI.conceptNames(domainId))
                .then((modelRes: any) => {
                    dataStructure.value = createTreeStructure(schema, primaryConcept, modelRes.data);
                    flatStructure.value = getFlatStructure(dataStructure.value);
                    getProcessingRules();
                    if (props.asset.parquetStatus === 'optimised') selectField(Object.keys(flatStructure.value));
                    initializeFilters();
                })
                .finally(() => {
                    loadingFields.value = false;
                });
        };

        const getFlatStructure: any = (parent: any[]) => {
            return parent.reduce((acc: any, node: any) => {
                acc[node.name] = node;
                if (R.has('children', node)) {
                    return {
                        ...acc,
                        ...getFlatStructure(node.children),
                    };
                }
                return acc;
            }, {});
        };

        const extractMappingRules = () => {
            props.asset.processingRules.mappingRules.forEach((rule: any) => {
                const transformations = getTransformations(rule);
                const rules: any[] = [];
                transformations.forEach((transformation: any) => {
                    rules.push(getTransformationText(transformation, true));
                });
                const path = rule.target.path.map((field: string) => field.replace('[]', ''));
                const key = [...path, rule.target.title].join('.');
                rules.forEach((mappingRule: any) => {
                    if (!processingRules.value[key]) processingRules.value[key] = [];
                    if (
                        !processingRules.value[key].find(
                            (item: any) => JSON.stringify(item.rule) === JSON.stringify([mappingRule]),
                        )
                    )
                        processingRules.value[key].push({ type: 'mapping', rule: [mappingRule] });
                });
            });
        };

        const extractCleaningRules = () => {
            props.asset.processingRules.cleaningRules.forEach((rule: any) => {
                const rules: any[] = [];
                rule.constraints.forEach((constraint: any) => {
                    rules.push(`${getConstraintText(constraint)} ${getOutliersRuleText(constraint, rule.type, true)}`);
                });
                const path = rule.path.map((field: string) => field.replace('[]', ''));
                const key = [...path, rule.title].join('.');
                rules.forEach((cleaningRule: any) => {
                    if (!processingRules.value[key]) processingRules.value[key] = [];
                    processingRules.value[key].push({ type: 'cleaning', rule: cleaningRule.split(',') });
                });
            });
        };

        const extractEncryptionRules = () => {
            props.asset.processingRules.encryptionRules.forEach((rule: any) => {
                if (rule.encrypt) {
                    const flatStructureValues = Object.values(flatStructure.value);
                    const foundField: any = flatStructureValues.find((field: any) => field.fieldId === rule.id);
                    const key = foundField ? foundField.id : rule.id;
                    if (!processingRules.value[key]) processingRules.value[key] = [];
                    processingRules.value[key].push({ type: 'encryption', rule: ['Field values were encrypted.'] });
                }
            });
        };

        const getProcessingRules = () => {
            if (props.asset.processingRules) {
                if (props.asset.processingRules.mappingRules) {
                    extractMappingRules();
                }
                if (props.asset.processingRules.cleaningRules) {
                    extractCleaningRules();
                }
                if (props.asset.processingRules.encryptionRules) {
                    extractEncryptionRules();
                }
            }
            loadingRules.value = false;
        };

        const togglePreviewRules = (field: any) => {
            const key = field.name;
            const idx = previewRules.value.indexOf(key);
            if (idx !== -1) {
                previewRules.value.splice(idx, 1);
            } else {
                previewRules.value.push(key);
            }
        };

        const hasRules = (field: any, type: string | null = null) => {
            const fieldRules = processingRules.value[field.name];
            if (!type) return !!fieldRules;
            if (fieldRules) {
                for (let i = 0; i < fieldRules.length; i += 1) {
                    if (fieldRules[i].type === type) return true;
                }
            }
            return false;
        };

        const selectField = (selected: any[]) => {
            props.contract.fields.splice(0);

            selected.forEach((selection: string) => {
                const field = flatStructure.value[selection];
                if (!field) return;

                const foundField = props.contract.fields.find((contractField: any) => contractField.name === selection);
                if (!foundField) {
                    props.contract.fields.push({
                        id: field.id,
                        uid: field.uid,
                        name: selection,
                        filters: filters.value[selection] ? filters.value[selection] : {},
                    });
                } else {
                    foundField.id = field.id;
                    foundField.filters = filters.value[selection] ? filters.value[selection] : {};
                }

                field.path.forEach((_: any, idx: number) => {
                    const name = field.path.slice(0, idx + 1).join('.');
                    const parentField = props.contract.fields.find((contractField: any) => contractField.name === name);
                    const foundParentField = flatStructure.value[name];
                    if (!parentField && foundParentField)
                        props.contract.fields.push({
                            id: foundParentField.id,
                            uid: foundParentField.uid,
                            name,
                            filters: {},
                        });
                });

                // eslint-disable-next-line no-param-reassign
                props.contract.fields = R.sortBy(R.prop('name'))(props.contract.fields);
            });
        };

        const openFiltersModal = (name: string, type: string) => {
            addingFiltersTo.name = name;
            addingFiltersTo.type = type;
            addingFiltersTo.filters = filters.value[name];
            showFiltersModal.value = true;
        };

        const closeFiltersModal = () => {
            addingFiltersTo.name = '';
            addingFiltersTo.type = '';
            addingFiltersTo.filters = {};
            showFiltersModal.value = false;
        };

        const addFilters = (value: any) => {
            if (addingFiltersTo.name) {
                const newFilter = { from: value.from, to: value.to, type: addingFiltersTo.type };
                filters.value[addingFiltersTo.name] = newFilter;
                filtersText.value[addingFiltersTo.name] = getFilterText(newFilter);
                const foundField = props.contract.fields.find((field: any) => field.name === addingFiltersTo.name);
                foundField.filters = newFilter;
            }
            showFiltersModal.value = false;
        };

        const removeFilters = () => {
            filters.value[addingFiltersTo.name] = null;
            filtersText.value[addingFiltersTo.name] = getFilterText(null);
            const foundField = props.contract.fields.find((field: any) => field.name === addingFiltersTo.name);
            foundField.filters = {};
            closeFiltersModal();
        };

        watch(
            () => domains.value,
            (domainsValue: any) => {
                if (domainsValue) {
                    // if domain & primary concept exist then load asset's data structure
                    if (props.asset.structure.domain && props.asset.structure.primaryConcept) {
                        const { majorVersion, uid } = props.asset.structure.domain;
                        // find domain from domains in store and use its id to load data structure
                        const domain = domainsValue.find(
                            (dom: any) => dom.uid === uid && dom.majorVersion === majorVersion,
                        );
                        if (domain) initialize(domain.id);
                    }
                }
            },
            { immediate: true },
        );

        return {
            isEnabled,
            AccessLevel,
            previewRules,
            togglePreviewRules,
            dataStructure,
            processingRules,
            hasRules,
            selectedFields,
            selectField,
            showFiltersModal,
            openFiltersModal,
            closeFiltersModal,
            addFilters,
            removeFilters,
            addingFiltersTo,
            filters,
            initialFilters,
            filtersText,
            initialFiltersText,
            flatStructure,
            loadingRules,
            resultFields,
            title,
            description,
            extendedFields,
            initialSelectedFields,
            loadingFields,
        };
    },
});
