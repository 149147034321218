

























































import { FormBlock, Scrollbar, ConfirmModal } from '@/app/components';
import store from '@/app/store';
import { ContractTemplatesAPI } from '@/modules/organization/api';
import { useAxios } from '@/app/composable';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import HtmlDiff from 'htmldiff-js';
import { VueEditor } from 'vue2-editor';
import { ContractStatus } from '../../constants';
import { Contract, ContractTransaction } from '../../types';

export default defineComponent({
    name: 'ContractTerms',
    components: {
        FormBlock,
        VueEditor,
        Scrollbar,
        ConfirmModal,
    },
    props: {
        contract: {
            type: Object as PropType<Contract>,
            required: true,
        },
        onEditMode: {
            type: Boolean,
            default: false,
        },
        previousNegotiation: {
            type: Object as PropType<ContractTransaction>,
            default: () => ({}),
        },
    },
    setup(props) {
        const { exec } = useAxios(true);

        const templates = ref<any[]>([]);
        const selectedTerms = ref<{ id: number; name: string; details: string } | null>(null);
        const tmpSelectedTerms = ref<{ id: number; name: string; details: string } | null>(null);
        const showChangeContractTemplateModal = ref(false);

        const toolbar = [
            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            ['clean'], // remove formatting button
        ];

        const user = computed(() => store.state.auth.user);

        const contractTerms = computed(() => {
            if (props.contract.status !== ContractStatus.Negotiate || !props.previousNegotiation?.changes.terms)
                return props.contract.terms;
            return HtmlDiff.execute(props.previousNegotiation.changes.terms, props.contract.terms);
        });

        if (props.onEditMode)
            exec(ContractTemplatesAPI.retrieveForOrganisation(user.value.organisationId)).then((res: any) => {
                templates.value = res?.data;
            });

        const changeSelectedTerms = (event: any) => {
            const selectedTemplate = templates.value.find(
                (template: { id: number; name: string; details: string }) =>
                    template.id === parseInt(event.target.value, 10),
            );

            if (selectedTemplate) tmpSelectedTerms.value = selectedTemplate;
            else tmpSelectedTerms.value = null;

            if (!props.contract.terms) confirmContractTemplateChange();
            else showChangeContractTemplateModal.value = true;
        };

        const confirmContractTemplateChange = () => {
            props.contract.terms = tmpSelectedTerms.value ? tmpSelectedTerms.value?.details : null;
            selectedTerms.value = tmpSelectedTerms.value ? tmpSelectedTerms.value : null;
            showChangeContractTemplateModal.value = false;
        };

        return {
            toolbar,
            contractTerms,
            templates,
            selectedTerms,
            confirmContractTemplateChange,
            tmpSelectedTerms,
            showChangeContractTemplateModal,
            changeSelectedTerms,
        };
    },
});
