import { useAxios, useFilters } from '@/app/composable';
import { ref } from '@vue/composition-api';
import { ExchangeRatesAPI } from '../api';
import { Currency } from '../constants';

export function useEthers() {
    const exchangeRates = ref<any>(null);
    const { formatDecimals } = useFilters();

    const { exec } = useAxios(true);

    const transformToOtherCurrency = (costInEthers: number, otherCurrency: Currency) => {
        return exchangeRates.value ? formatDecimals(Number(costInEthers * exchangeRates.value[otherCurrency]), 2) : 0;
    };

    const transformToEthers = (costInOtherCurrency: number, otherCurrency: Currency) => {
        return exchangeRates.value
            ? formatDecimals(Number(costInOtherCurrency / exchangeRates.value[otherCurrency]), 4)
            : 0;
    };

    exec(ExchangeRatesAPI.getExchangeRates()).then((res: any) => {
        exchangeRates.value = {
            EUR: Number(res.data.data.rates.EUR),
            USD: Number(res.data.data.rates.USD),
            GBP: Number(res.data.data.rates.GBP),
        };
    });

    return { exchangeRates, transformToOtherCurrency, transformToEthers };
}
