import { BundleStatus, ContractStatus } from '../constants';

export function useContractStatus() {
    const statusClass = (status: ContractStatus | BundleStatus) => {
        switch (status) {
            case ContractStatus.Active:
                return 'bg-green-100 text-green-700';
            case ContractStatus.Draft:
                return 'bg-gray-100 text-gray-700';
            case ContractStatus.Negotiate:
                return 'bg-yellow-100 text-yellow-700';
            case ContractStatus.Request:
            case ContractStatus.Revise:
            case ContractStatus.Reactivate:
            case ContractStatus.Extend:
                return 'bg-purple-100 text-purple-700';
            case ContractStatus.Signed:
                return 'bg-blue-100 text-blue-700';
            case ContractStatus.Rejected:
            case ContractStatus.Declined:
            case ContractStatus.Withdrawn:
                return 'bg-red-100 text-red-700';
            case ContractStatus.Expired:
                return 'bg-secondary-100 text-secondary-700';
            default:
                return 'bg-neutral-100 text-neutral-700';
        }
    };

    return { statusClass };
}
