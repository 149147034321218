import { AxiosRequestConfig } from 'axios';
import { CreateDepartment, CreateOrganization, OrganisationRole, UpdateOrganization } from '../types';

const endpoint = 'api/organisations';

export default {
    update: (id: number, data: UpdateOrganization) =>
        ({ method: 'PUT', url: `${endpoint}/${id}`, data } as AxiosRequestConfig),
    verify: (id: number) => ({ method: 'POST', url: `${endpoint}/${id}/verify` } as AxiosRequestConfig),
    retrieveOrgDepartments: (id: number) =>
        ({ method: 'GET', url: `${endpoint}/${id}/departments` } as AxiosRequestConfig),
    retrieveOrgUsers: (id: number) => ({ method: 'GET', url: `${endpoint}/${id}/users` } as AxiosRequestConfig),
    createDepartment: (id: number, data: CreateDepartment) =>
        ({ method: 'POST', url: `${endpoint}/${id}/departments`, data } as AxiosRequestConfig),
    removeDepartment: (id: number, depId: number) =>
        ({ method: 'DELETE', url: `${endpoint}/${id}/departments/${depId}` } as AxiosRequestConfig),
    registerOrganization: (data: CreateOrganization) =>
        ({ method: 'POST', url: `${endpoint}`, data } as AxiosRequestConfig),
    retrieveRoles: (id: number) => ({ method: 'GET', url: `${endpoint}/${id}/roles` } as AxiosRequestConfig),
    createOrgRole: (id: number, data: OrganisationRole) =>
        ({ method: 'POST', url: `${endpoint}/${id}/role`, data } as AxiosRequestConfig),
    updateRole: (id: number, roleId: number, data: OrganisationRole) =>
        ({ method: 'PUT', url: `${endpoint}/${id}/role/${roleId}`, data } as AxiosRequestConfig),
    deleteRole: (id: number, roleId: number) =>
        ({ method: 'DELETE', url: `${endpoint}/${id}/role/${roleId}` } as AxiosRequestConfig),
    addWallet: (id: number, data: { ethaddress: string; ethwallet: string }) =>
        ({ method: 'PUT', url: `${endpoint}/${id}/wallet`, data } as AxiosRequestConfig),
};
