






































































































































































































































































































import * as R from 'ramda';
import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
import { regex } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { InputErrorIcon, FormModal, AlertBanner } from '@/app/components';
import { dynamicValues, dateFormats, datetimeFormats } from '@/app/constants';
import { requiredValidator } from '@/app/validators';

extend('required', requiredValidator);
extend('regex', {
    ...regex,
    message: 'Invalid parameter name',
});

export default defineComponent({
    name: 'ParameterModal',
    components: {
        ValidationObserver,
        ValidationProvider,
        InputErrorIcon,
        FormModal,
        AlertBanner,
    },
    props: {
        editParam: {
            type: Object,
            required: false,
        },
        editParamType: {
            type: String,
            required: false,
        },
        method: {
            type: String,
            required: false,
        },
        loginResponseKeys: {
            type: Array,
            required: false,
        },
    },
    setup(props, { emit }) {
        const paramValidationRef = ref<any>(null);
        let param = reactive<any>({
            key: {
                name: null,
                type: 'query',
            },
            value: {
                type: null,
                format: null,
                value: null,
            },
            sensitive: false,
        });
        const editing = ref(false);

        const dynamicValue = reactive<any>({
            option: null,
            value: null,
        });

        if (props.editParam && !R.isEmpty(props.editParam)) {
            editing.value = true;
            if (props.editParamType === 'url') {
                param.key.name = props.editParam.key;
                param.key.type = 'url';
                param.value.type = props.editParam.type;
                param.value.value =
                    props.editParam.type === 'authentication'
                        ? props.editParam.value.slice(1, -1)
                        : props.editParam.value;
                param.value.sensitive = props.editParam.sensitive;
            } else {
                param = R.mergeAll([param, props.editParam]);
                if (param.value.type === 'dynamic') {
                    const valueParts = param.value.value.split(':');
                    [dynamicValue.option] = [valueParts[0]];
                    dynamicValue.value = valueParts[1] ? valueParts[1] : null;
                }
            }
        }

        const title = computed(() => {
            if (editing.value) return 'Edit Parameter';
            return 'Add New Parameter';
        });

        const changeType = () => {
            if (param.value.type !== 'dynamic') {
                dynamicValue.option = null;
                dynamicValue.value = null;
                param.value.format = null;
                param.value.value = null;
                param.sensitive = false;
            }
        };

        const changeDynamicValue = () => {
            param.value.format = null;
            param.value.value = null;
            dynamicValue.value = null;
        };

        const queryTypes = [
            { value: 'static', label: 'Static' },
            { value: 'dynamic', label: 'Dynamic' },
            { value: 'authentication', label: 'Authentication' },
        ];

        const bodyTypes = [
            { value: 'integer', label: 'Integer' },
            { value: 'float', label: 'Float' },
            { value: 'string', label: 'String' },
            { value: 'dynamic', label: 'Dynamic' },
            { value: 'authentication', label: 'Authentication' },
        ];

        const parameterTypes = computed(() => {
            if (param.key.type === 'query' || param.key.type === 'url') return queryTypes;
            return bodyTypes;
        });

        const formats = computed(() => {
            if (dynamicValue.option === 'current_date') return dateFormats;
            if (dynamicValue.option === 'current_datetime') return datetimeFormats;
            if (dynamicValue.option.includes('minute') || dynamicValue.option.includes('hour')) return datetimeFormats;
            return dateFormats.concat(datetimeFormats);
        });

        const needsValue = computed(() => {
            if (!dynamicValue.option) return false;
            return !!(dynamicValue.option.endsWith('_ago') || dynamicValue.option.endsWith('_later'));
        });

        const showAdvancedSettings = computed(() => {
            return (
                param.value.type &&
                param.value.type !== 'dynamic' &&
                props.editParamType !== 'pagination' &&
                param.value.type !== 'authentication'
            );
        });

        const cancel = () => {
            emit('cancel');
        };

        const validateAndProceed = async () => {
            let newParam = null;
            if (props.editParamType === 'url') {
                newParam = {
                    key: param.key.name,
                    value: param.value.type === 'authentication' ? `{${param.value.value}}` : param.value.value,
                    sensitive: param.sensitive,
                    type: param.value.type,
                    format: param.value.format,
                };
                if (dynamicValue.option) newParam.value = dynamicValue.option;
                if (dynamicValue.value) newParam.value += `:${dynamicValue.value}`;
            } else {
                newParam = R.clone(param);
                if (dynamicValue.option) newParam.value.value = dynamicValue.option;
                if (dynamicValue.value) newParam.value.value += `:${dynamicValue.value}`;
            }
            if (editing.value) emit('updateParam', newParam);
            else emit('addParam', newParam);
        };

        return {
            paramValidationRef,
            title,
            param,
            dynamicValue,
            dynamicValues,
            formats,
            needsValue,
            changeType,
            changeDynamicValue,
            cancel,
            validateAndProceed,
            queryTypes,
            bodyTypes,
            parameterTypes,
            showAdvancedSettings,
        };
    },
});
