













































































import { FormModal } from '@/app/components';
import { useFilters } from '@/app/composable';
import { requiredValidator } from '@/app/validators';
import { computed, defineComponent, ref } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';

dayjs.extend(utc);

extend('required', requiredValidator);

export default defineComponent({
    name: 'FiltersModal',
    components: {
        ValidationObserver,
        ValidationProvider,
        FormModal,
    },
    props: {
        type: {
            type: String,
            default: 'datetime',
        },
        initialFilters: {
            type: Object,
            default: null,
        },
        filters: {
            type: Object,
        },
        temporalCoverage: {
            type: Object,
        },
        isExtended: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { formatDate } = useFilters();
        const fromDate = ref<any>(props.filters ? props.filters.from : null);
        const toDate = ref<any>(props.filters ? props.filters.to : null);
        const initialFromDate = ref<any>(props.initialFilters ? props.initialFilters.from : null);
        const initialToDate = ref<any>(props.initialFilters ? props.initialFilters.to : null);

        const minTemporal = computed(() => {
            if (
                props.temporalCoverage &&
                (props.temporalCoverage.unit === null ||
                    props.temporalCoverage.unit === 'Time Period' ||
                    props.temporalCoverage.unit === 'Calculated based on data')
            ) {
                return new Date(props.temporalCoverage.min);
            }
            return null;
        });

        const maxTemporal = computed(() => {
            if (
                props.temporalCoverage &&
                (props.temporalCoverage.unit === null ||
                    props.temporalCoverage.unit === 'Time Period' ||
                    props.temporalCoverage.unit === 'Calculated based on data')
            ) {
                return new Date(props.temporalCoverage.max);
            }
            return null;
        });

        const error = computed(() => {
            if (props.isExtended) {
                if (
                    fromDate.value &&
                    initialFromDate.value &&
                    new Date(fromDate.value).getTime() > new Date(initialFromDate.value).getTime()
                )
                    return `From cannot be greater than ${dayjs.utc(initialFromDate.value).format('DD/MM/YYYY HH:mm')}`;
                if (
                    toDate.value &&
                    initialToDate.value &&
                    new Date(toDate.value).getTime() < new Date(initialToDate.value).getTime()
                )
                    return `To cannot be less than ${dayjs.utc(initialToDate.value).format('DD/MM/YYYY HH:mm')}`;
            }
            if (fromDate.value && toDate.value && new Date(fromDate.value).getTime() > new Date(toDate.value).getTime())
                return 'From cannot be greater than To';
            return null;
        });

        const minDate = computed(() => {
            if (props.isExtended) return initialToDate.value;
            if (fromDate.value) return fromDate.value;
            return minTemporal.value;
        });

        const maxDate = computed(() => {
            if (props.isExtended) return initialFromDate.value;
            if (toDate.value) return toDate.value;
            return maxTemporal.value;
        });

        const cancel = () => {
            emit('cancel');
        };

        const save = () => {
            emit('save-filters', { from: fromDate.value, to: toDate.value });
        };

        return {
            formatDate,
            cancel,
            fromDate,
            toDate,
            save,
            minTemporal,
            maxTemporal,
            minDate,
            maxDate,
            error,
        };
    },
});
