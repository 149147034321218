import { AuthGuard } from '@/modules/auth/router/auth.guard';

export default [
    {
        name: 'workflows',
        path: '/analytics-pipelines',
        component: () => import(/* webpackChunkName: "workflows" */ '@/modules/workflow-designer/views/Workflows.vue'),
        meta: { title: 'Analytics Pipeline', feature: 'analytics' },
        beforeEnter: AuthGuard,
    },
    {
        name: 'workflow-designer:edit',
        path: '/analytics-pipelines/:id/designer',
        component: () =>
            import(/* webpackChunkName: "workflows" */ '@/modules/workflow-designer/views/WorkflowDesigner.vue'),
        props: true,
        meta: { title: 'View/Edit Analytics Pipeline', feature: 'analytics' },
        beforeEnter: AuthGuard,
    },
    {
        name: 'workflow-designer:visualize',
        path: '/analytics-pipelines/:id/visualisation',
        component: () =>
            import(/* webpackChunkName: "workflows" */ '@/modules/workflow-designer/views/WorkflowVisualisation.vue'),
        props: true,
        meta: { title: 'View visualisation', feature: 'analytics' },
        beforeEnter: AuthGuard,
    },
    {
        name: 'history',
        path: '/analytics-pipelines/:workflowId/history',
        component: () =>
            import(
                /* webpackChunkName: "workflows" */ '@/modules/workflow-designer/components/execution-history/AnalyticsExecutionHistory.vue'
            ),
        props: true,
        meta: { feature: 'analytics' },
        beforeEnter: AuthGuard,
    },

    {
        name: 'workflow:create',
        path: '/analytics-pipelines/create',
        component: () =>
            import(/* webpackChunkName: "workflows" */ '@/modules/workflow-designer/views/ConfigureWorkflow.vue'),
        props: true,
        meta: { title: 'Create Data Analytics Pipeline', feature: 'analytics' },
        beforeEnter: AuthGuard,
    },
    {
        name: 'workflow:monitoring',
        path: '/analytics-pipelines/monitoring',
        component: () =>
            import(
                /* webpackChunkName: "workflows" */ '@/modules/workflow-designer/views/AnalyticsPipelineMonitoring.vue'
            ),
        meta: { title: 'Analytics Pipelines Monitoring', feature: 'analytics' },
        props: true,
        beforeEnter: AuthGuard,
    },
    {
        name: 'workflow',
        path: '/analytics-pipelines/:id/:mode?',
        component: () =>
            import(/* webpackChunkName: "workflows" */ '@/modules/workflow-designer/views/ConfigureWorkflow.vue'),
        props: true,
        meta: { title: 'Data Analytics Pipeline', feature: 'analytics' },
        beforeEnter: AuthGuard,
    },
];
