


















































































import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';

export default defineComponent({
    name: 'FieldPath',
    props: {
        field: {
            type: Object,
            required: true,
        },

        basePath: {
            type: Array,
            required: false,
            default: () => [],
        },
        alternateName: {
            type: Object,
            default: null,
        },
        showAlternateNaming: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        // references to DOM elements
        const pathContainerRef = ref<any>(null);
        const pathSeparatorRef = ref<any>(null);
        const fieldTitleRef = ref<any>(null);

        // calculates the width of a given string based on the font
        const getTextWidth = (text: string) => {
            const canvas: any = document.createElement('canvas');
            const context = canvas.getContext('2d');
            context.font = '.875rem sans-serif';
            const metrics = context.measureText(text);
            return Math.ceil(metrics.width);
        };

        /**
         * calculates the displayed field paths based
         * on the available screen size
         */
        const fieldPaths = computed((): { visible: []; hidden: []; all: [] } => {
            const fieldPathCopy = props.showAlternateNaming ? [...props.alternateName.path] : [...props.field.path];

            const result: any = { visible: [], hidden: [], all: [] };
            result.all = props.showAlternateNaming ? [...props.alternateName.path] : [...props.field.path];
            if (pathContainerRef.value && fieldTitleRef.value) {
                if (!props.showAlternateNaming && !R.isNil(props.field.title)) {
                    result.all.push(props.field.title);
                } else if (props.showAlternateNaming && result.all?.length > 1 && !R.isNil(props.alternateName.title)) {
                    result.all.push(props.alternateName.title);
                }
                const last = fieldPathCopy.pop();

                fieldPathCopy.forEach((path: string) => {
                    const totalWidth = pathContainerRef.value.offsetWidth;
                    const pathSeparatorWidth = pathSeparatorRef.value?.width.baseVal.value + 8;
                    const titleWidth = fieldTitleRef.value.offsetWidth;
                    const estimatedWidth =
                        getTextWidth(result.visible.join('') + path + last) +
                        (result.visible.length + 3) * pathSeparatorWidth +
                        titleWidth;
                    if (result.hidden.length === 0 && estimatedWidth <= totalWidth) {
                        result.visible.push(path);
                    } else if (result.hidden.length === 0) {
                        result.visible.push('..');
                        result.hidden.push(path);
                    } else {
                        result.hidden.push(path);
                    }
                });

                if (last) result.visible.push(last); // always show first independent of width
            }
            return result;
        });

        const getHiddenTooltip = (paths: string[]) => {
            return paths.join(' > ');
        };

        const isBasePath = (field: string, level: number) => {
            if (!props.basePath || level > props.basePath.length || field === '..') return false;

            for (let i = 0; i <= level; i++) {
                if ((fieldPaths.value.all[i] as string).replaceAll('[]', '') !== props.basePath[i]) return false;
            }
            return true;
        };

        const trimPath = () => {
            // eslint-disable-next-line vue/no-mutating-props
            props.field.path.splice(-1, 1);
            props.field.pathUids.splice(-1, 1);
            // eslint-disable-next-line vue/no-mutating-props
            props.field.parentIds.splice(-1, 1);
            // eslint-disable-next-line vue/no-mutating-props
            props.field.categories.splice(-1, 1);
        };

        return {
            fieldPaths,
            pathContainerRef,
            fieldTitleRef,
            pathSeparatorRef,
            getHiddenTooltip,
            trimPath,
            isBasePath,
        };
    },
});
