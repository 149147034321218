



































import { useFilters } from '@/app/composable';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'ModalPaymentDetails',
    props: {
        paymentDetails: {
            type: Object,
            required: true,
        },
        additional: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { formatDecimals } = useFilters();

        const price = computed(() =>
            props.paymentDetails.totalDerivationCost
                ? props.paymentDetails.totalCost - props.paymentDetails.totalDerivationCost
                : props.paymentDetails.totalCost,
        );

        return { formatDecimals, price };
    },
});
