import { AuthGuard } from '@/modules/auth/router/auth.guard';
import { NavigationGuard } from 'vue-router';

type Next = Parameters<NavigationGuard>[2];

export default [
    {
        name: 'retrieval',
        path: '/retrieval',
        component: () => import(/* webpackChunkName: "data-model" */ '@/modules/retrieval/views/RetrievalQueries.vue'),
        meta: { title: 'Retrieval', feature: ['retrieve.api', 'retrieve.files', 'retrieve.stream'] },
        beforeEnter: AuthGuard,
    },
    {
        name: 'retrieval:create',
        path: '/retrieval/create',
        component: () => import(/* webpackChunkName: "retrieval" */ '@/modules/retrieval/views/RetrievalQuery.vue'),
        meta: {
            title: 'Create New Data Retrieval Query',
            feature: ['retrieve.api', 'retrieve.files', 'retrieve.stream'],
        },
        beforeEnter: AuthGuard,
    },
    {
        name: 'retrieval:edit',
        path: '/retrieval/:id',
        component: () => import(/* webpackChunkName: "retrieval" */ '@/modules/retrieval/views/RetrievalQuery.vue'),
        props: true,
        meta: {
            title: 'View/Edit Data Retrieval Query',
            feature: ['retrieve.api', 'retrieve.files', 'retrieve.stream'],
        },
        beforeEnter: AuthGuard,
    },
    {
        name: 'retrieval:configure',
        path: '/retrieval/:id/configure',
        component: () =>
            import(/* webpackChunkName: "retrieval" */ '@/modules/retrieval/views/ConfigureRetrievalQuery.vue'),
        props: true,
        meta: {
            title: 'Configure Data Retrieval Query',
            feature: ['retrieve.api', 'retrieve.files', 'retrieve.stream'],
        },
        beforeEnter: AuthGuard,
    },
];
