

































import { defineComponent } from '@vue/composition-api';
import { useCart } from '../../composable';

export default defineComponent({
    name: 'CartListItem',
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const { removeFromCart } = useCart();

        return { removeFromCart };
    },
});
