import { AuthGuard } from '@/modules/auth/router/auth.guard';

export default [
    {
        name: 'marketplace',
        path: '/marketplace',
        component: () => import(/* webpackChunkName: "sharing" */ '@/modules/sharing/views/Marketplace.vue'),
        meta: { title: 'Marketplace' },
        beforeEnter: AuthGuard,
    },
    {
        name: 'wish-list',
        path: '/wish-list',
        component: () => import(/* webpackChunkName: "sharing" */ '@/modules/sharing/views/WishList.vue'),
        meta: { title: 'Wish List' },
        beforeEnter: AuthGuard,
    },
    {
        name: 'contracts',
        path: '/contracts',
        component: () => import(/* webpackChunkName: "sharing" */ '@/modules/sharing/views/Contracts.vue'),
        meta: { title: 'Contracts' },
        beforeEnter: AuthGuard,
    },
    {
        name: 'contract-details',
        path: '/contracts/:type/:id',
        component: () => import(/* webpackChunkName: "sharing" */ '@/modules/sharing/views/Contract.vue'),
        meta: { title: 'Contract' },
        props: true,
        beforeEnter: AuthGuard,
    },
    {
        name: 'contract-monitoring',
        path: '/contracts/:type/:id/monitoring',
        component: () => import(/* webpackChunkName: "sharing" */ '@/modules/sharing/views/ContractMonitoring.vue'),
        meta: { title: 'Contract Monitoring' },
        props: true,
        beforeEnter: AuthGuard,
    },
    {
        name: 'contract-action',
        path: '/contracts/:type/:id/:action',
        component: () => import(/* webpackChunkName: "sharing" */ '@/modules/sharing/views/Contract.vue'),
        meta: { title: 'Contract' },
        props: true,
        beforeEnter: AuthGuard,
    },
];
