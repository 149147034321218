import { moduleActionContext } from '@/app/store';
import { useAxios } from '@/app/composable';
import { defineModule } from 'direct-vuex';
import * as R from 'ramda';
import { clone } from 'ramda';
import { AlertsAPI } from '../api';
import { AlertSourceType } from '../constants';

export interface Alert {
    id: string;
    name: string;
    isEnabled: boolean;
    updatedAt: Date;
    createdAt: Date;
    entityType: string;
    entityId: string | number;
    sourceType: string;
    createdById: number;
    events: any;
    entities: { id: number; name: string; type: string; mine: boolean }[];
    stats: any;
    metrics: {
        redEvents: {
            total: number;
            occurrences: number;
        };
        yellowEvents: {
            total: number;
            occurrences: number;
        };
    };
    numRedEvents?: number;
    numYellowEvents?: number;
    occurrences?: number;
}

export interface AlertState {
    alerts: Alert[];
    loading: boolean;
}

export const alertModule = defineModule({
    namespaced: true,
    state: (): AlertState => {
        return {
            alerts: [],
            loading: false,
        };
    },
    mutations: {
        SET_ALERTS(state: AlertState, alerts: Alert[]) {
            state.alerts = clone(alerts);
        },

        MARK_ALERT_AS_ACTIVATED(state: AlertState, id: string) {
            const idx = R.findIndex(R.propEq('id', id), state.alerts as any[]);
            if (idx !== -1) {
                state.alerts[idx].isEnabled = true;
            }
        },
        MARK_ALERT_AS_DEACTIVATED(state: AlertState, id: string) {
            const idx = R.findIndex(R.propEq('id', id), state.alerts as any[]);
            if (idx !== -1) {
                state.alerts[idx].isEnabled = false;
            }
        },
        DELETE_ALERT(state: AlertState, id: string) {
            state.alerts = state.alerts.filter((a) => a.id !== id);
        },
        APPEND_ALERT(state: AlertState, alert: Alert) {
            state.alerts = [alert, ...state.alerts];
        },
    },
    getters: {
        getSourceTypeAlerts: (state: AlertState) => (types: string[]) => {
            const sourceTypeAlerts: {
                id: string | number;
                entityId: string | number;
                alertId: string;
                enabled: boolean;
            }[] = [];
            state.alerts.forEach((alert: Alert) => {
                const typeEntities = alert.entities.filter((entity: { id: number; name: string; type: string }) =>
                    types.includes(entity.type),
                );
                typeEntities.forEach((typeEntity: { id: number; name: string; type: string }) =>
                    sourceTypeAlerts.push({
                        id: alert.entityId,
                        entityId: typeEntity.id,
                        alertId: alert.id,
                        enabled: alert.isEnabled,
                    }),
                );
            });
            return sourceTypeAlerts;
        },
        getExcludedIds: (state: AlertState) => (sourceType: AlertSourceType, alertId?: string) => {
            return state.alerts.reduce((acc: any[], value: Alert) => {
                if (alertId && value.id === alertId) return acc;
                const foundEntities = value.entities.filter((e) => e.type === sourceType);
                return R.uniq(acc.concat(R.pluck('id', foundEntities)));
            }, []);
        },
        getAlertsLoading: (state: AlertState) => (): boolean => state.loading,
    },
    actions: {
        fetchAlerts({ state, commit }) {
            const { exec } = useAxios(true);
            state.loading = true;
            exec(AlertsAPI.getUserAlerts())
                .then((result: any) => {
                    if (result && result.data) {
                        const processedAlerts: Alert[] = [];
                        result.data.forEach((alert: Alert) => {
                            const metrics = {
                                numRedEvents: alert.metrics.redEvents.total,
                                numYellowEvents: alert.metrics.yellowEvents.total,
                                occurrences:
                                    alert.metrics.redEvents.occurrences + alert.metrics.yellowEvents.occurrences,
                            };
                            processedAlerts.push({ ...alert, ...metrics });
                        });
                        commit('SET_ALERTS', processedAlerts);
                    }
                })
                .finally(() => (state.loading = false));
        },
        addAlert({ commit }, alert) {
            if (!!alert && alert.id > 0) {
                commit('APPEND_ALERT', alert);
            }
        },
        deleteAlert({ commit }, id) {
            if (id) commit('DELETE_ALERT', id);
        },
        deactivateAlert({ commit }, id) {
            if (id) commit('MARK_ALERT_AS_DEACTIVATED', id);
        },
        activateAlert({ commit }, id) {
            if (id) commit('MARK_ALERT_AS_ACTIVATED', id);
        },
    },
});

export const alertModuleActionContext = (context: any) => moduleActionContext(context, alertModule);
