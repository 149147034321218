export enum ExceptionPolicyFields {
    // 'org.id' = 'organisationId',
    'org.businessName' = 'organisationId',
    'org.country' = 'organisationCountry',
    'org.continent' = 'organisationContinent',
    'org.type' = 'organisationType',
}

export enum OperatorNames {
    IN = 'in',
    NOT_IN = 'notIn',
}

export enum ExceptionFieldMappings {
    'organisationId' = 'ORGANISATION_BUSINESS_NAME',
    'organisationCountry' = 'COUNTRY',
    'organisationContinent' = 'CONTINENT',
    'organisationType' = 'ORGANISATION_TYPE',
}
