
















































import { useFilters } from '@/app/composable';
import { ContractsAPI, ContractTransactionsAPI } from '@/modules/sharing/api';
import { useBlockchain, useWallet } from '@/modules/sharing/composable';
import { BundleStatus, ContractDurationType, ContractStatus, WalletAction } from '@/modules/sharing/constants';
import * as Sentry from '@sentry/browser';
import { useAxios } from '@/app/composable';
import { CashIcon, PlusIcon, XIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { Bundle, Contract } from '../../types';
import LoadingModal from '../wallet/LoadingModal.vue';
import WalletModal from '../wallet/WalletModal.vue';
import ModalPaymentDetails from './ModalPaymentDetails.vue';

export default defineComponent({
    name: 'MultipleContractsActions',
    components: {
        WalletModal,
        LoadingModal,
        ModalPaymentDetails,
        CashIcon,
        PlusIcon,
        XIcon,
    },
    props: {
        contracts: {
            type: Array as PropType<Contract[]>,
            default: () => [],
        },
        totalCosts: {
            type: Object,
            default: () => ({}),
        },
        bundle: {
            type: Object as PropType<Bundle>,
            default: null,
        },
        isConsumer: {
            type: Boolean,
            default: false,
        },
        isNewContract: {
            type: Boolean,
            default: false,
        },
        invalid: {
            type: Boolean,
            default: false,
        },
        allProvidersHaveAnEthaddress: {
            type: Boolean,
            default: false,
        },
        allContractsSigned: {
            type: Boolean,
            default: false,
        },
        contractAction: {
            type: Boolean,
            default: false,
        },
        isBundle: {
            type: Boolean,
            default: true,
        },
        isLegalRepresentative: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { root, emit }) {
        const isBlockchainEnabled = ref<boolean>(!!process.env.VUE_APP_ETH_NODE);
        const showUnlockWalletModal = ref<boolean>(false);
        const showLoadingModal = ref<boolean>(false);
        const action = ref<Function | null>(null);

        const { exec, loading } = useAxios(true);
        const { createBundleContract, payContractsAndMarkAsActive, message: modalMessage } = useBlockchain();
        const { getWalletBalanceInEthers } = useWallet();
        const { formatDecimals } = useFilters();

        const missingFields = computed(() =>
            props.contracts.find(
                (c: any) => c.asset.assetTypeId === 1 && c.asset.structure.type !== 'other' && c.fields.length === 0,
            ),
        );

        const paymentDetails = computed(() => (props.bundle ? props.totalCosts[props.bundle.id] : null));

        const payToActivate = async (wallet: any) => {
            showLoadingModal.value = true;
            if (isBlockchainEnabled.value) {
                const balance = await getWalletBalanceInEthers(wallet);
                let totalCost = props.totalCosts[props.bundle.id].totalCostWithCommissionFee;
                totalCost = formatDecimals(totalCost, 4);
                let pendingTransactionId = null;
                try {
                    const resContract = await exec(ContractsAPI.makeBundleActive(props.bundle.id, true));
                    pendingTransactionId = resContract?.data?.pendingTransactionId;
                } catch (error) {
                    (root as any).$toastr.e('Writing transaction failed', 'Error');
                    showLoadingModal.value = false;
                    return;
                }
                try {
                    if (Number(balance) < totalCost) {
                        (root as any).$toastr.e('Insufficient wallet balance', 'Error');
                        await exec(ContractTransactionsAPI.update(pendingTransactionId, { status: 'failed' }));
                        showLoadingModal.value = false;
                    } else {
                        await payContractsAndMarkAsActive(
                            wallet,
                            props.bundle.ethaddress as string,
                            totalCost,
                            pendingTransactionId,
                            true,
                        );
                    }
                } catch (e) {
                    const error: any = e;
                    Sentry.captureException(error && error.error && error.error.message ? error.error.message : error);
                    await exec(ContractTransactionsAPI.update(pendingTransactionId, { status: 'failed' }));
                    (root as any).$toastr.e('Writing to blockchain failed', 'Error');
                    showLoadingModal.value = false;
                }
            }
            if (showLoadingModal.value) {
                exec(ContractsAPI.makeBundleActive(props.bundle.id))
                    .then(() => {
                        showLoadingModal.value = false;
                        (root as any).$toastr.s('Bundle activated!', 'Success');
                        emit('reload-bundle');
                    })
                    .catch(() => {
                        showLoadingModal.value = false;
                        (root as any).$toastr.e('Writing to database failed', 'Error');
                    });
            }
        };

        const confirmAction = (actionMethod: Function) => {
            action.value = actionMethod;
            if (isBlockchainEnabled.value) showUnlockWalletModal.value = true;
            else actionMethod();
        };

        const submit = async (wallet?: any) => {
            showLoadingModal.value = true;
            const providerEthaddresses: any[] = [];
            const payload: any = { contracts: [], isBundle: props.isBundle };
            props.contracts.forEach((contract: Contract) => {
                const ctr: Contract = R.clone(contract);
                if (ctr.duration.type !== ContractDurationType.Forever) {
                    ctr.duration.number = Number(ctr.duration.number);
                } else {
                    ctr.duration.number = null;
                }
                providerEthaddresses.push(ctr.providerOrg.ethaddress);
                payload.contracts.push({
                    duration: ctr.duration,
                    message: ctr.message,
                    metadata: ctr.metadata,
                    status: ContractStatus.Request,
                    consumerOrgId: ctr.consumerOrgId,
                    providerOrgId: ctr.providerOrgId,
                    assetId: ctr.assetId,
                    fields: ctr.fields,
                    asset: { id: ctr.assetId, name: ctr.asset?.name },
                });
            });
            if (isBlockchainEnabled.value && props.isBundle) {
                try {
                    payload.ethaddress = await createBundleContract(wallet, payload, providerEthaddresses);
                } catch (e) {
                    const error: any = e;
                    Sentry.captureException(error && error.error && error.error.message ? error.error.message : error);
                    (root as any).$toastr.e('Writing to blockchain failed', 'Error');
                    showLoadingModal.value = false;
                }
            }
            if (showLoadingModal.value) {
                exec(ContractsAPI.create(payload))
                    .then((res: any) => {
                        showLoadingModal.value = false;
                        emit('update-contracts', R.clone(res.data?.contracts));
                        emit('requests-sent');
                        emit('scroll-up');
                        (root as any).$toastr.s(`Contract requests sent!`, 'Success');
                    })
                    .catch(() => {
                        showLoadingModal.value = false;
                        (root as any).$toastr.e(`Contract requests failed to sent.`, 'Error');
                    });
            }
        };

        return {
            modalMessage,
            action,
            ContractStatus,
            missingFields,
            loading,
            isBlockchainEnabled,
            showUnlockWalletModal,
            showLoadingModal,
            paymentDetails,
            confirmAction,
            payToActivate,
            submit,
            BundleStatus,
            WalletAction,
        };
    },
});
