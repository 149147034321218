import { AxiosRequestConfig } from 'axios';

const endpoint = '/api/wish-list';

export default {
    getWishList: () => ({ method: 'GET', url: `${endpoint}` } as AxiosRequestConfig),
    getWishListAssetIds: () => ({ method: 'GET', url: `${endpoint}/asset-ids` } as AxiosRequestConfig),
    addAssetToWishList: (assetId: number) =>
        ({ method: 'POST', url: `${endpoint}/asset/${assetId}` } as AxiosRequestConfig),
    removeAssetFromWishList: (assetId: number) =>
        ({ method: 'DELETE', url: `${endpoint}/asset/${assetId}` } as AxiosRequestConfig),
};
