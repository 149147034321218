











































































































































































































import { useSockets } from '@/app/composable/socket';
import store from '@/app/store';
import Auth from '@/modules/auth/api/auth';
import Keycloak from '@/modules/auth/api/keycloak';
import { NotificationListItem } from '@/modules/notification/components';
import { useNotifications } from '@/modules/notification/composable';
import { Cart } from '@/modules/sharing/components';
import { useCart } from '@/modules/sharing/composable';
import { BellIcon, InformationCircleIcon, MenuAlt2Icon, QuestionMarkCircleIcon } from '@vue-hero-icons/outline';
import { ChevronDownIcon } from '@vue-hero-icons/solid';
import { Ref, computed, defineComponent, onBeforeUnmount, ref } from '@vue/composition-api';
import VGravatar from 'vue-gravatar';
import { Route } from 'vue-router';
import { ConfirmModal, ModelBrowserOverlay, OnClickOutside, Scrollbar } from '../components';
import { useFeatureFlags } from '../composable';
import { useRoute, useRouter } from '../composable/router';
import { UserRoles, WebSocketsRoomTypes } from '../constants';

export default defineComponent({
    name: 'PageHeader',
    components: {
        VGravatar,
        ChevronDownIcon,
        OnClickOutside,
        BellIcon,
        MenuAlt2Icon,
        Scrollbar,
        NotificationListItem,
        ConfirmModal,
        ModelBrowserOverlay,
        InformationCircleIcon,
        QuestionMarkCircleIcon,
        Cart,
    },
    setup(props, { root }) {
        const userMenuOpen: Ref<boolean> = ref<boolean>(false);
        const openModelBrowser = ref<boolean>(false);
        const user = computed(() => store.state.auth.user);
        const isAdmin = computed(() => store.getters.auth.hasRole(UserRoles.Admin));
        const isModelModerator = computed(() => store.getters.auth.hasRole(UserRoles.ModelModerator));
        const showModelBrowser: Ref<boolean> = ref<boolean>(false);
        const { subscribe, unsubscribe, connected, socketDisconnect, WebSocketsEvents, leaveSocketRoom } = useSockets();
        const { isEnabled: isFeatureEnabled } = useFeatureFlags();

        const userNavigation = computed(() => [
            { name: 'Personal Profile', route: 'user:edit', visible: true },
            {
                name: 'Organisation Details',
                route: 'organization:edit',
                visible: true,
            },
            { name: 'Wish List', route: 'wish-list', visible: true },
            {
                name: 'Data Models',
                route: 'model-manager',
                visible: isFeatureEnabled('model-manager') && isModelModerator.value,
            },
            {
                name: 'Admin Portal',
                route: 'admin-portal',
                visible: isAdmin.value,
            },
            { name: 'Alerts', route: 'alerts', visible: true },
        ]);

        const {
            openNotifications,
            closeNotifications,
            notifications,
            numNotifications,
            viewAllNotifications,
            enableMarkAllAsSeen,
            markAllAsSeen,
            showMarkAllAsSeenModal,
        } = useNotifications(root);

        const { clearCart } = useCart();

        const logout = async () => {
            (root as any).$toastr.removeByType('info');
            await Auth.logout();
            socketDisconnect();
            store.commit.notificationEngine.CLEAR_NOTIFICATIONS();
            store.commit.auth.CLEAR_KEYCLOAK_TOKEN();
            store.commit.auth.CLEAR_USER();
            store.commit.sharing.CLEAR_NETWORK();
            clearCart();
            await Keycloak.logout(process.env.VUE_APP_URL);
        };

        const onMessage = async () => {
            await logout();
        };

        subscribe(WebSocketsEvents.User, onMessage);
        onBeforeUnmount(() => {
            unsubscribe(WebSocketsEvents.User);
            leaveSocketRoom(WebSocketsRoomTypes.User);
        });

        const close = () => {
            userMenuOpen.value = false;
        };

        const route = useRoute();

        // on first load

        showModelBrowser.value = route.name !== 'mapping' || route.params.step !== 'configuration';

        useRouter().afterEach((to: Route) => {
            showModelBrowser.value = to.name !== 'mapping' || to.params.step !== 'configuration';
        });

        return {
            user,
            userMenuOpen,
            notifications,
            userNavigation,
            numNotifications,
            openModelBrowser,
            showModelBrowser,
            openNotifications,
            enableMarkAllAsSeen,
            showMarkAllAsSeenModal,
            close,
            logout,
            markAllAsSeen,
            closeNotifications,
            viewAllNotifications,
            connected,
            isFeatureEnabled,
        };
    },
});
