import { useAxios } from '@/app/composable';
import { useSockets } from '@/app/composable/socket';
import store from '@/app/store';
import { ExecutionError } from '@/app/store/execution-errors';
import { S } from '@/app/utilities';
import { NotificationAPI } from '@/modules/notification/api';
import { notificationDescription } from '@/modules/notification/config/messages.templates';
import { computed, onBeforeUnmount, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { NotificationType, RefreshPageKeys } from '../constants';

export function useNotifications(root: any) {
    const { exec } = useAxios(true);
    const { subscribe, unsubscribe, WebSocketsEvents } = useSockets();
    const openNotifications = ref(false);
    const showMarkAllAsSeenModal = ref(false);

    const closeNotifications = () => {
        if (openNotifications.value) {
            openNotifications.value = false;
        }
    };

    const notifications = computed(() => {
        return store.state.notificationEngine.notifications;
    });

    const numNotifications = computed(() => store.state.notificationEngine.unSeenNotifications);

    const viewAllNotifications = () => {
        openNotifications.value = false;
        if (root.$router.app.$route.name !== 'notifications') {
            root.$router.push({ name: 'notifications' });
        }
    };

    const enableMarkAllAsSeen = computed(() => {
        return !!(notifications.value.length && notifications.value.filter((n: any) => !n.seenAt).length);
    });

    const markAllAsSeen = async () => {
        showMarkAllAsSeenModal.value = false;
        await store.dispatch.notificationEngine.markAllAsSeen();
    };

    const truncateDescription = (description: string) => {
        if (description.length > 120) {
            return `${description.slice(0, 120)}...`;
        }
        return description;
    };

    const executionErrors = computed(() => store.state.executionErrors.executionErrors);

    const onMessage = async (notification: any) => {
        const type: NotificationType = notification?.payload?.referenceType;
        const page = root.$router.app.$route.name;
        if (type === NotificationType.DataCheckinJob)
            if (page === 'data-checkin-jobs')
                store.dispatch.notificationEngine.enableRefreshAction(RefreshPageKeys.DataCheckinJob);
            else if (['assets', 'assets:view'].includes(page) && notification?.eventType === 'dcj.service.completed')
                store.dispatch.notificationEngine.addRefreshProvenanceId(notification?.payload?.referenceId);
        if (type === NotificationType.Workflow)
            if (page === 'workflows') store.dispatch.notificationEngine.enableRefreshAction(RefreshPageKeys.Workflow);
            else if (
                ['assets', 'assets:view'].includes(page) &&
                notification?.eventType === 'workflow.service.completed'
            )
                store.dispatch.notificationEngine.addRefreshProvenanceId(notification?.payload?.referenceId);

        store.dispatch.notificationEngine.addNotification(notification);
        store.dispatch.notificationEngine.enableRefreshAction(RefreshPageKeys.Notifications);

        let message: string;
        if (notification.payload.errorCode) {
            const executionError = executionErrors.value.find(
                (err: ExecutionError) => err.code === notification.payload.errorCode,
            );
            message = `${`${S.sanitizeHtml(notification.payload.referenceTitle)} failed: ${S.sanitizeHtml(
                truncateDescription(executionError?.title || `Unknown Error Code: ${notification.payload.errorCode}`),
            )}`}`;
        } else {
            message = `${truncateDescription(S.sanitizeHtml(notificationDescription(notification)))}`;
        }

        (root as any).$toastr.i({
            msg: message,
            title: 'You have 1 new notification',
            name: 'info',
            onClicked: async () => {
                const idx = R.findIndex(
                    R.propEq('id', notification.id),
                    store.state.notificationEngine.notifications as any[],
                );
                await exec(NotificationAPI.markAsSeen(notification.id)).then((res: any) => {
                    store.state.notificationEngine.notifications[idx].seenAt = res.data.seenAt;
                });
            },
        });
    };

    subscribe(WebSocketsEvents.Notification, (msg: any) => onMessage(msg));
    onBeforeUnmount(() => {
        unsubscribe(WebSocketsEvents.Notification);
    });

    return {
        openNotifications,
        notifications,
        numNotifications,
        enableMarkAllAsSeen,
        showMarkAllAsSeenModal,
        closeNotifications,
        viewAllNotifications,
        markAllAsSeen,
        truncateDescription,
    };
}
