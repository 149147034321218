





import { CloudDownloadIcon, CubeTransparentIcon, ExclamationCircleIcon, ServerIcon } from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent } from '@vue/composition-api';
import { ExpectedUsage, ExpectedUsageText } from '../constants';

export default defineComponent({
    name: 'ExpectedUsageIcon',
    props: {
        type: { type: String as PropType<ExpectedUsage>, required: true },
        classes: { type: String, default: 'w-5 h-5 text-secondary-800' },
    },
    components: { CubeTransparentIcon, ServerIcon, CloudDownloadIcon, ExclamationCircleIcon },
    setup(props) {
        const info = computed(() => {
            switch (props.type) {
                case ExpectedUsage.Centralized:
                    return { icon: CubeTransparentIcon, text: ExpectedUsageText[ExpectedUsage.Centralized] };
                case ExpectedUsage.DirectDownload:
                    return { icon: CloudDownloadIcon, text: ExpectedUsageText[ExpectedUsage.DirectDownload] };
                case ExpectedUsage.OnPremise:
                    return { icon: ServerIcon, text: ExpectedUsageText[ExpectedUsage.OnPremise] };
                default:
                    return { icon: ExclamationCircleIcon, text: `Unknown expected usage: ${props.type}` };
            }
        });

        return { info };
    },
});
