





















































import { FormModal, TwProgressBar } from '@/app/components';
import store from '@/app/store';
import { minLengthValidator, requiredValidator, strongPasswordValidator } from '@/app/validators';
import { OrganizationsAPI } from '@/modules/organization/api';
import { useAxios } from '@/app/composable';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { useWallet } from '../../composable';
import { WalletAction, WalletActionMap } from '../../constants';

extend('required', requiredValidator);
extend('min', minLengthValidator);
extend('strong_password', strongPasswordValidator);

export default defineComponent({
    name: 'WalletModal',
    components: { FormModal, ValidationProvider, ValidationObserver, TwProgressBar },
    props: {
        type: {
            type: String as PropType<WalletAction>,
            required: true,
        },
        suffix: {
            type: String,
            default: '',
        },
    },
    setup(props, { emit, root }) {
        const validationRef = ref<any>(null);
        const password = ref<string>('');
        const privateKey = ref<string>('');
        const loading = ref<boolean>(false);
        const error = ref<string | null>(null);

        const orgId = computed(() =>
            store.getters.organisation.details?.id ? Number(store.getters.organisation.details.id) : null,
        );

        const walletAction = computed(() => WalletActionMap[props.type]);

        const { exec } = useAxios(true);

        const { createRandomWallet, importExistingWallet, connectToWallet, encryptWallet, progress } = useWallet();

        const addWallet = async (wallet: any) => {
            if (!orgId.value) return;

            const encryptedWallet = await encryptWallet(wallet, password.value);
            const { address } = JSON.parse(encryptedWallet);
            const ethaddress = address.startsWith('0x') ? address : `0x${address}`;
            await exec(OrganizationsAPI.addWallet(orgId.value, { ethaddress, ethwallet: encryptedWallet }));
            store.dispatch.organisation.setWallet(encryptedWallet);
            store.dispatch.organisation.setHasWallet(true);
            password.value = '';
            privateKey.value = '';
            emit('hide-modal');
            emit('wallet-created');
        };

        const createWallet = async () => {
            try {
                await addWallet(createRandomWallet());
                (root as any).$toastr.s('Ethereum wallet created!', 'Success');
            } catch (e) {
                error.value = 'Wallet creation failed';
            }
        };

        const importWallet = async () => {
            try {
                await addWallet(importExistingWallet(privateKey.value));
                (root as any).$toastr.s('Ethereum wallet imported!', 'Success');
            } catch (e) {
                error.value = 'Invalid private key';
            }
        };

        const unlockWallet = async () => {
            try {
                const currentWallet = await connectToWallet(password.value);
                if (currentWallet) emit('wallet-unlocked', currentWallet);
                else (root as any).$toastr.e('Ethereum wallet does not exist!', 'Error');
                emit('hide-modal');
            } catch (e) {
                error.value = 'Invalid password';
            }
        };

        const save = async () => {
            const valid = await validationRef.value.validate();
            if (!valid) return;

            loading.value = true;
            error.value = null;

            switch (props.type) {
                case WalletAction.Create:
                    await createWallet();
                    break;
                case WalletAction.Import:
                    await importWallet();
                    break;
                case WalletAction.Unlock:
                    await unlockWallet();
                    break;
                default: // do nothing
            }

            loading.value = false;
        };

        return {
            password,
            loading,
            progress,
            privateKey,
            validationRef,
            error,
            save,
            WalletAction,
            walletAction,
        };
    },
});
