import { ESortingOption } from '@/app/types';
import { isNil } from 'ramda';

export class ContractSortingOption extends ESortingOption {
    static readonly ASSET_TITLE_ASC = new ContractSortingOption('asset_title', true, 'Asset Title Ascending');

    static readonly ASSET_TITLE_DESC = new ContractSortingOption('asset_title', false, 'Asset Title Descending');

    static readonly DUE_DATE_DESC = new ContractSortingOption('due_date', false, 'Due Date (newest first)');

    static readonly DUE_DATE_ASC = new ContractSortingOption('due_date', true, 'Due Date (oldest first)');

    static readonly DATE_UPDATED_DESC = new ContractSortingOption('date_updated', false, 'Date updated (newest first)');

    static readonly DATE_UPDATED_ASC = new ContractSortingOption('date_updated', true, 'Date updated (oldest first)');

    static all(): ESortingOption[] {
        return [...super.all(), ...this.local()];
    }

    static default(relevanceAvailable: boolean): ESortingOption {
        const calculatedDefault = super.calculateDefault(relevanceAvailable);
        if (!isNil(calculatedDefault)) return calculatedDefault;

        return this.DATE_UPDATED_DESC;
    }

    static available(relevanceAvailable: boolean): ESortingOption[] {
        return [...super.available(relevanceAvailable), ...this.local()];
    }

    private static local(): ContractSortingOption[] {
        return [
            this.DUE_DATE_ASC,
            this.DUE_DATE_DESC,

            this.DATE_UPDATED_DESC,
            this.DATE_UPDATED_ASC,

            this.ASSET_TITLE_ASC,
            this.ASSET_TITLE_DESC,
        ];
    }
}
