









































































































import { useAsset } from '@/modules/asset/composable/asset';
import { useContractStatus } from '@/modules/sharing/composable';
import { ContractStatus } from '@/modules/sharing/constants';
import { ChevronDownIcon, ChevronUpIcon, DownloadIcon, SwitchHorizontalIcon } from '@vue-hero-icons/outline';
import { ExclamationIcon, UserIcon } from '@vue-hero-icons/solid';
import { PropType, computed, defineComponent } from '@vue/composition-api';
import { Contract } from '../../types';
import ContractSteps from './ContractSteps.vue';
import { useAxios } from '@/app/composable';
import { ContractsAPI } from '../../api';
import { jsPDF } from 'jspdf';

export default defineComponent({
    name: 'ContractPreview',
    components: {
        ContractSteps,
        DownloadIcon,
        ChevronUpIcon,
        ChevronDownIcon,
        ExclamationIcon,
        UserIcon,
        SwitchHorizontalIcon,
    },
    props: {
        isConsumer: {
            type: Boolean,
            default: true,
        },
        contract: {
            type: Object as PropType<Contract>,
            required: true,
        },
        showBothUsers: {
            type: Boolean,
            default: false,
        },
        isPublicAsset: {
            type: Boolean,
            default: false,
        },
        isExpandable: {
            type: Boolean,
            default: false,
        },
        isExpanded: {
            type: Boolean,
            default: false,
        },
        showPreviewSteps: {
            type: Boolean,
            default: false,
        },
        configurationError: {
            type: Boolean,
            default: false,
        },
        currentStep: {
            type: Number,
            default: 0,
        },
        actionRequired: {
            type: Boolean,
            default: false,
        },
        noPayment: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { exec } = useAxios(true);
        const { statusClass } = useContractStatus();
        const { assetTypeClass, assetTypeName } = useAsset();

        const canBeDownloaded = computed(() => {
            const statuses: ContractStatus[] = [
                ContractStatus.Draft,
                ContractStatus.Negotiate,
                ContractStatus.Signed,
                ContractStatus.Active,
            ];
            return (
                statuses.includes(props.contract.status as ContractStatus) &&
                !props.isPublicAsset &&
                !props.contract.updatingContractId
            );
        });

        const downloadPDF = () => {
            exec(ContractsAPI.getPDF(props.contract.id)).then((res: any) => {
                const doc = new jsPDF({
                    unit: 'px',
                    hotfixes: ['px_scaling'],
                });
                doc.html(res.data, {
                    callback: (document: any) => {
                        document.save(`${props.contract.asset?.name} Contract.pdf`);
                    },
                    margin: 50,
                });
            });
        };

        return { assetTypeClass, canBeDownloaded, statusClass, assetTypeName, downloadPDF };
    },
});
