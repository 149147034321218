import { ValidatorUtils } from './utils';

export const strongPasswordValidator = {
    validate: (value: any) => {
        const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,})');
        return strongRegex.test(value);
    },
    message(field: string) {
        return `${ValidatorUtils.prettifyName(
            field,
        )} must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (! @ # $ % ^ & * _)`;
    },
};
