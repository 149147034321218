

















































































































































































































































import { Toggle } from '@/app/components';
import { requiredValidator } from '@/app/validators';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { extend } from 'vee-validate';
import { useDataModel } from '../composable';
import { DataType } from '../constants';

extend('required', requiredValidator);

export default defineComponent({
    name: 'StringType',
    components: { Toggle },
    model: {
        prop: 'metadata',
        event: 'updateMetadata',
    },
    props: {
        collapsedLeftPane: {
            type: Boolean,
            required: true,
        },
        metadata: {
            type: Object,
            default: null,
        },
        isProposal: {
            type: Boolean,
            required: false,
        },
        initialMetadata: {
            type: Object,
            required: false,
        },
        model: {
            type: Object,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        isFieldDraft: {
            type: Boolean,
            required: true,
        },
        // changesToBeSaved: {
        //     type: Array,
        //     default: () => [],
        // },
        // savedChange: {
        //     type: Boolean,
        //     required: false,
        // },
    },
    setup(props, { emit }) {
        const copiedModel = computed(() => props.model);
        const { defineDatatypeMetadata } = useDataModel(copiedModel);
        const indexOptions = [
            { label: 'Keyword', value: 'keyword' },
            { label: 'Text', value: 'text' },
            { label: 'Both Keyword and Text', value: 'both' },
            { label: 'False', value: false },
        ];
        const activeMetadata = ref<any>(null);
        const setActiveMetadata = () => {
            if (props.metadata && !props.isProposal) {
                activeMetadata.value = R.clone(props.metadata);
            } else {
                activeMetadata.value = defineDatatypeMetadata(DataType.String);
                emit('updateMetadata', activeMetadata.value);
            }
        };
        setActiveMetadata();

        const updateMetadata = () => {
            // also disable "ordered" metadata if "multiple" is changed to false
            if (!activeMetadata.value.multiple && activeMetadata.value.ordered) {
                activeMetadata.value.ordered = false;
            }
            if (!activeMetadata.value.indexES) {
                activeMetadata.value.spatial = false;
                activeMetadata.value.spatialID = false;
            }
            emit('updateMetadata', activeMetadata.value);
        };

        const disableMultiple = computed(
            () => (props.initialMetadata?.multiple && !props.isProposal && !props.isFieldDraft) || props.readOnly,
        );

        const disableOrdered = computed(
            () =>
                !activeMetadata.value.multiple ||
                (props.initialMetadata?.multiple &&
                    !props.initialMetadata?.ordered &&
                    !props.isProposal &&
                    !props.isFieldDraft) ||
                props.readOnly,
        );

        const disableSensitive = computed(
            () =>
                (props.initialMetadata &&
                    !props.initialMetadata?.sensitive &&
                    !props.isProposal &&
                    !props.isFieldDraft) ||
                props.readOnly,
        );

        const disableMetadataConditions = computed(
            () => props.initialMetadata && !props.isFieldDraft && !props.isProposal,
        );

        const disableIndexMongo = computed(
            () => props.readOnly || (disableMetadataConditions.value && !props.initialMetadata?.indexMongo),
        );

        const disableIndexES = computed(
            () => props.readOnly || (disableMetadataConditions.value && !props.initialMetadata?.indexES),
        );

        const disableSpatialAndSpatialId = computed(() => props.readOnly || !activeMetadata.value.indexES);

        return {
            disableMultiple,
            disableOrdered,
            disableSensitive,
            updateMetadata,
            activeMetadata,
            indexOptions,
            disableIndexMongo,
            disableIndexES,
            disableSpatialAndSpatialId,
        };
    },
});
