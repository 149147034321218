
































































import { HarvesterSourceType } from '@/modules/data-checkin/constants';
import ScheduleEditor from '@/modules/workflow-designer/components/schedule/ScheduleEditor.vue';
import ViewSchedules from '@/modules/workflow-designer/components/schedule/ViewSchedules.vue';
import { ScheduleFrequency } from '@/modules/workflow-designer/constants';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { UpdateScheduleDTO } from '../../workflow-designer/dto';
import { ScheduleType } from '../../workflow-designer/types';

export default defineComponent({
    name: 'Schedule',
    props: {
        schedules: {
            type: Array,
            default: () => [],
        },
        error: {
            type: String,
            default: null,
        },
        workflowId: {
            type: [String, Number],
            required: true,
        },
        closeEditor: {
            type: Boolean,
            required: false,
        },
        frameworkVersion: {
            type: String,
        },
        minimalLayout: {
            type: Boolean,
            default: false,
        },
        retrievalType: {
            type: String,
            required: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        frequencies: {
            type: Array,
            default: () => ['hourly', 'daily', 'weekly', 'monthly'],
        },
        startDate: {
            type: [Date, Number],
            required: false,
        },
        defaultStartDate: {
            type: Boolean,
            default: false,
        },
        stepSource: {
            type: String,
            required: false,
        },
        schedulesPerFrequency: {
            type: Number,
            default: null,
        },
        loadingSchedule: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        requiredSchedulesMessage: {
            type: String,
            required: true,
        },
        frequency: {
            type: String,
            default: null,
        },
    },
    components: { ScheduleEditor, ViewSchedules },
    setup(props, { emit }) {
        const openScheduleEditor = ref(!props.schedules.length ? true : false);
        const errorInScheduleEditor = ref(null);
        const scheduleToBeEdited: any = ref(null);
        const showScheduleEditCancelConfirmation = ref<boolean>(false);
        const changedSchedule: any = ref(null);
        const creationDifferencesState = ref<boolean>(false);
        const multipleSchedules = computed(
            () =>
                (props.retrievalType && props.retrievalType !== 'once') || props.frequency === ScheduleFrequency.Daily,
        );

        const saveSchedule = (schedule: ScheduleType) => {
            emit('save', schedule);
        };
        const updateSchedule = (schedule: UpdateScheduleDTO) => {
            emit('save', { id: scheduleToBeEdited.value.id, ...schedule });
        };
        const changeScheduleStatus = (schedule: any) => {
            emit('change-status', schedule);
        };
        const deleteSchedule = (schedule: any) => {
            emit('delete-schedule', schedule.id);
        };

        const differencesExist = computed(() => {
            if (scheduleToBeEdited.value && changedSchedule.value) {
                const scheduleToCompare = {
                    ...scheduleToBeEdited.value,
                    startDate: new Date(scheduleToBeEdited.value.startDate),
                    endDate: new Date(scheduleToBeEdited.value.endDate),
                };
                const checkDifferences = JSON.stringify(scheduleToCompare) !== JSON.stringify(changedSchedule.value);
                return checkDifferences;
            }
            return false;
        });

        const setChangedSchedule = (schedule: any) => {
            changedSchedule.value = schedule;
            emit('changed-schedule', differencesExist.value);
        };

        const addSchedule = () => {
            openScheduleEditor.value = true;
            scheduleToBeEdited.value = null;
        };
        const editSchedule = (schedule: any) => {
            if (scheduleToBeEdited.value && scheduleToBeEdited.value.id !== schedule.id) {
                emit('reset-error');
            }

            // If harvester is Large Files, update the start date to be the tomorrow's date
            const today = dayjs().utc();
            if (
                props.stepSource === HarvesterSourceType.LargeFiles &&
                schedule.startDate &&
                dayjs(schedule.startDate).format('DD/MM/YYYY') <= today.format('DD/MM/YYYY')
            ) {
                schedule.startDate = today.add(1, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS');
            }

            scheduleToBeEdited.value = schedule;
            openScheduleEditor.value = true;
        };
        const closeScheduleEditor = () => {
            scheduleToBeEdited.value = null;
            openScheduleEditor.value = false;
        };

        const closeScheduleEditorAfterSave = () => {
            openScheduleEditor.value = false;
            scheduleToBeEdited.value = null;
            emit('reset-error');
        };

        const setCreationDifferencesState = (state: boolean) => {
            creationDifferencesState.value = state;
        };

        const disabledMessage = computed(() =>
            props.disabled
                ? 'The schedule will be automatically enabled once the configuration of the final step (i.e. the Loader Step) of the Data Check-in Pipeline is completed.'
                : null,
        );

        watch(
            () => props.error,
            (error: any) => {
                errorInScheduleEditor.value = R.clone(error);
            },
        );

        watch(
            () => props.closeEditor,
            (close: any) => {
                if (close) {
                    openScheduleEditor.value = false;
                    scheduleToBeEdited.value = null;
                }
            },
        );

        return {
            openScheduleEditor,
            saveSchedule,
            changeScheduleStatus,
            deleteSchedule,
            editSchedule,
            scheduleToBeEdited,
            closeScheduleEditor,
            addSchedule,
            updateSchedule,
            errorInScheduleEditor,
            emit,
            differencesExist,
            showScheduleEditCancelConfirmation,
            setChangedSchedule,
            close,
            setCreationDifferencesState,
            closeScheduleEditorAfterSave,
            multipleSchedules,
            disabledMessage,
            uuidv4,
        };
    },
});
