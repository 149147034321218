// Functions for the SynergiesCore
export const coreABI = [
    'event ContractCreated(address newContract)',
    'event BundleContractCreated(address newContract)',
    'function createBundleContract(address adminAddress, address[] providers, bytes32[] assets, uint256 numberOfChildren)',
    'function createContract(address adminAddress, address updatingContract, (uint8 _status, uint8 _reimbursementMethod, address _consumer, bytes32 _assetHash, bytes32 _barteringAssetHash, string _fieldsAndFilters, uint256 _duration, bytes32 _costVatCurrencyHash, bytes32 _contractTermsHash, bytes32 _platformTermsHash, address _parentContract, uint256 _cost, address _derivationManager, bytes32 _licenseMetadataHash))',
    'function changeCommissionFee(uint256 _commissionFee)',
    'function getCommissionFee(uint256 totalCost)',
    'function getBundleContracts() view returns (address[])',
    'function getContracts() view returns (address[])',
    'function validateBundleContract(address ctrAddress)',
    'function validateContract(address ctrAddress)',
];

// Functions for the SynergiesContract
export const contractABI = [
    'function acceptContract()',
    'function acceptOffer()',
    'function rejectContract()',
    'function rejectOffer()',
    'function contractActive()',
    'function negotiate(uint256 _newCost, bytes32 _newContractTermsHash, bytes32 _newCostVatCurrencyHash, bytes32 _newLicenseMetadataHash)',
    'function counterOffer(uint256 _newCost, bytes32 _newContractTermsHash, bytes32 _newCostVatCurrencyHash, bytes32 _newLicenseMetadataHash)',
    'function payContractsAndMarkAsActive() payable',
    'function validate()',
    'function isActive() view returns (bool)',
    'function getTotalCost() view returns (uint256)',
];

// Functions for the SynergiesDerivationCore
export const derivationCoreABI = [
    'event DerivationContractCreated(address newContract)',
    'function createDerivationContract(address adminAddress, address seller, bytes32 sellingAssetHash, bytes32 assetHash, uint256 cost, address acquisitionContract, bytes32 _licenseMetadataHash)',
    'function findDerivationContracts(bytes32 sellingAssetHash)',
    'function getDerivationContracts() view returns (address[])',
    'function validateDerivationContract(address ctrAddress)',
];

// Functions for the SynergiesDerivationContract
export const derivationContractABI = [
    'function acceptContract()',
    'function acceptOffer()',
    'function rejectContract()',
    'function rejectOffer()',
    'function negotiate(uint256 _newCost, bytes32 _newLicenseMetadataHash)',
    'function counterOffer(uint256 _newCost, bytes32 _newLicenseMetadataHash)',
    'function validate()',
    'function isActive() view returns (bool)',
    'function getTotalCost() view returns (uint256)',
];

// Functions for the SynergiesBundleContract
export const bundleContractABI = [
    'function payContractsAndMarkAsActive() payable',
    'function reject()',
    'function validate()',
    'function getChildren() view returns (address[])',
    'function getProviders() view returns (address[])',
    'function getAssets() view returns (bytes32[])',
    'function isActive() view returns (bool)',
    'function getTotalCost() view returns (uint256)',
];
