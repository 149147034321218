









































import { useFilters } from '@/app/composable';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { ContractTransaction } from '../../types';
import { CheckCircleIcon, XCircleIcon, ClockIcon } from '@vue-hero-icons/solid';
import { useContractStatus } from '../../composable';
import { ContractStatus, ContractTransactionStatus } from '../../constants';

export default defineComponent({
    name: 'TransactionItem',
    components: {
        CheckCircleIcon,
        XCircleIcon,
        ClockIcon,
    },
    props: {
        transaction: {
            type: Object as PropType<ContractTransaction>,
            required: true,
        },
        previousTransaction: {
            type: Object as PropType<ContractTransaction>,
            required: false,
        },
        consumerOrgId: {
            type: Number,
            required: true,
        },
        assetName: {
            type: String,
            required: false,
        },
    },
    setup(props) {
        const { formatDateAs } = useFilters();
        const { statusClass } = useContractStatus();

        const transactionStatus = computed(() => {
            if (props.transaction.changes.status === ContractStatus.Request) {
                if (props.transaction.changes.metadata?.reactivate) return ContractStatus.Reactivate;
                if (props.transaction.changes.metadata?.extend) return ContractStatus.Extend;
            } else if (
                props.transaction.changes.status === ContractStatus.Draft &&
                props.transaction.changes.metadata?.revise
            ) {
                return ContractStatus.Revise;
            }
            return props.transaction.changes.status;
        });

        const transactionTimestamp = computed(() => {
            return `${formatDateAs(props.transaction.createdAt, true, 'DD/MM/YYYY')} ${formatDateAs(
                props.transaction.createdAt,
                true,
                'HH:mm',
                'UTC',
            )}`;
        });

        const userRole = computed(() =>
            props.transaction.changes.metadata?.organisationId === props.consumerOrgId
                ? 'The data asset recipient'
                : 'The data asset provider',
        );

        const negotiationMessage = computed(() => {
            let message = '';
            props.transaction.changes.metadata?.organisationId === props.consumerOrgId
                ? (message += 'negotiated')
                : (message += 'made a counter offer');
            if (!props.previousTransaction) return message;

            const priceChanged =
                JSON.stringify(props.previousTransaction.changes.metadata?.pricing) !==
                JSON.stringify(props.transaction.changes.metadata?.pricing);
            const licenseChanged =
                JSON.stringify(props.previousTransaction.changes.metadata?.license) !==
                JSON.stringify(props.transaction.changes.metadata?.license);
            const termsChanged = props.previousTransaction.changes.terms !== props.transaction.changes.terms;
            let changedText = '';
            if (priceChanged || licenseChanged || termsChanged) {
                message += ' over ';
                if (priceChanged) changedText += 'the <b>price</b>';
                if (licenseChanged)
                    changedText += `${
                        changedText.length ? (termsChanged ? ', ' : ' and ') : ''
                    }the <b>licensing details</b>`;
                if (termsChanged) changedText += `${changedText.length ? ' and ' : ''}the <b>terms</b>`;
                message += changedText;
            }
            return message;
        });

        const transactionMessage = computed(() => {
            switch (transactionStatus.value) {
                case ContractStatus.Request:
                    return `${userRole.value} sent a contract request for ${
                        props.assetName ? `asset <b>${props.assetName}</b>` : 'an asset'
                    }`;
                case ContractStatus.Draft:
                    if (props.transaction.bundleId) return 'A draft bundle contract has been created';
                    return `${userRole.value} created a draft contract`;
                case ContractStatus.Negotiate:
                    return `${userRole.value} ${negotiationMessage.value}`;
                case ContractStatus.Signed:
                    return `${userRole.value} signed the contract`;
                case ContractStatus.Rejected:
                    if (props.transaction.bundleId) return 'The bundle contract has been rejected';
                    return `${userRole.value} rejected the contract`;
                case ContractStatus.Declined:
                    return `${userRole.value} declined the contract request`;
                case ContractStatus.Expired:
                    if (props.transaction.bundleId) return 'The bundle contract has expired';
                    return `The contract has expired`;
                case ContractStatus.Withdrawn:
                    return `The contract was withdrawn`;
                case ContractStatus.Active:
                    if (props.transaction.bundleId) return 'The bundle contract has been activated';
                    return `The reimbursement terms have been fulfilled and the contract has been activated`;
                case ContractStatus.Revise:
                    return `${userRole.value} revised the contract`;
                case ContractStatus.Reactivate:
                    return `${userRole.value} reactivated the contract`;
                case ContractStatus.Extend:
                    return `${userRole.value} extended the contract`;
                default:
                    return '';
            }
        });

        return {
            ContractStatus,
            ContractTransactionStatus,
            transactionStatus,
            transactionTimestamp,
            transactionMessage,
            statusClass,
        };
    },
});
