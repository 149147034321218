import { HarvesterSourceType } from '../../constants';

export enum ConstraintType {
    RANGE = 'RANGE',
    RANGE_EXCLUDING = 'RANGE_EXCLUDING',
    NOT_RANGE = 'NOT_RANGE',
    MANDATORY = 'MANDATORY',
    REGULAR_EXPRESSION = 'REGULAR_EXPRESSION',
    NOT_REGULAR_EXPRESSION = 'NOT_REGULAR_EXPRESSION',
    UNIQUE = 'UNIQUE',
    SET_MEMBERSHIP = 'SET_MEMBERSHIP',
    CROSS_FIELD = 'CROSS_FIELD',
    FOREIGN_KEY = 'FOREIGN_KEY',
    NOT_FOREIGN_KEY = 'NOT_FOREIGN_KEY',
}

export enum OutliersRuleType {
    DROP = 'DROP',
    DEFAULT_VALUE = 'DEFAULT_VALUE',
    PREVIOUS_VALUE = 'PREVIOUS_VALUE',
    MOST_FREQUENT_VALUE = 'MOST_FREQUENT_VALUE',
    MEAN_VALUE = 'MEAN_VALUE',
    MEDIAN_VALUE = 'MEDIAN_VALUE',
    MIN_VALUE = 'MIN_VALUE',
    MAX_VALUE = 'MAX_VALUE',
    MOST_SIMILAR = 'MOST_SIMILAR',
}

export enum SecondaryRuleType {
    DROP = 'DROP',
    DEFAULT_VALUE = 'DEFAULT_VALUE',
}

export interface SecondaryRule {
    type: SecondaryRuleType | null;
    replaceValue: string | number | null;
}

export interface OutliersRule {
    type: ConstraintType | null;
    replaceValue: string | number | null;
    secondaryRule: SecondaryRule | null;
}

export interface Condition {
    fieldId?: number | null;
    type?: ConstraintType | null;
    details?: object | null;
    conditionUid?: string | null;
    operator?: string | null;
    conditions?: Condition[];
}

export interface Constraint {
    id: number;
    referenceId: number;
    type?: ConstraintType | null;
    details?: object | null;
    structure?: {
        operator: string;
        conditions: Condition[];
    } | null;
    outliersRule: OutliersRule | null;
    fieldName: string | string[];
}

export interface FieldConfiguration {
    id: number | null;
    name: string | null;
    title: string | null;
    multiple: boolean | null;
    parentIds: number[] | null;
    path: string[] | null;
    type: string | null;
    categories: string[] | null;
    constraints: Constraint[] | null;
}

export interface CleaningConfig {
    fields: FieldConfiguration[];
}

export enum ConstraintMode {
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
}

export interface ConstraintStats {
    id: number;
    error_code: number | null;
    dropped: number;
    null: number;
    transformed: number;
}

export interface JobStepResult {
    id: string | number;
    configuration: {
        fileType?: string;
        isSampleCropped?: boolean;
        params?: Record<string, any>;
        response?: Record<string, any>;
        source: HarvesterSourceType;
        fields?: Record<string, any>;
    };
    dataCheckinStepType: {
        name: string;
    };
    status: string;
}
