



























































































































import { ConfirmModal, OnClickOutside, Scrollbar } from '@/app/components';
import { UserRoles } from '@/app/constants';
import store from '@/app/store';
import { Asset } from '@/modules/asset/types';
import { ShoppingCartIcon } from '@vue-hero-icons/outline';
import { InformationCircleIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useCart } from '../../composable';
import { ReimbursementMethod } from '../../constants';
import CartListItem from './CartListItem.vue';

export default defineComponent({
    name: 'Cart',
    components: {
        CartListItem,
        ShoppingCartIcon,
        OnClickOutside,
        Scrollbar,
        InformationCircleIcon,
        ConfirmModal,
    },
    setup(_, { root }) {
        const { openCart, closeCart, clearCart, cart, showEmptyCartModal, isCartOpen, cartLimit } = useCart();

        const showClearCartModal = ref<boolean>(false);

        const isLegalRepresentative = computed(() => store.getters.auth.hasRole(UserRoles.LegalRepresentative));

        const buyAssets = (id: string) => {
            root.$router.push({ name: 'contract-details', params: { type: 'create', id } });
            closeCart();
        };

        const clearCartAssets = () => {
            clearCart();
            closeCart();
            showClearCartModal.value = false;
        };

        const canOpenCart = computed(() => {
            return !root.$route.path.startsWith('/contracts/create');
        });

        const allOnlineAssets = computed(() =>
            cart.value.every((asset: Asset) =>
                asset.metadata?.pricing?.reimbursementMethod.includes(ReimbursementMethod.Online),
            ),
        );

        return {
            cartLimit,
            isLegalRepresentative,
            showClearCartModal,
            openCart,
            closeCart,
            cart,
            showEmptyCartModal,
            isCartOpen,
            buyAssets,
            clearCartAssets,
            canOpenCart,
            allOnlineAssets,
        };
    },
});
