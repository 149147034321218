var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full space-y-2"},[_c('div',{staticClass:"space-y-2",class:{
            'xl:flex xl:justify-between xl:items-center xl:space-y-0': _vm.collapsedLeftPane,
        }},[_c('div',{staticClass:"flex items-center justify-between w-full mr-2",class:{ 'xl:w-1/2': _vm.collapsedLeftPane }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                        'An indication whether the specific concept can appear multiple times in the same asset.',
                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',
                }),expression:"{\n                    content:\n                        'An indication whether the specific concept can appear multiple times in the same asset.',\n                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',\n                }"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Allow multiple occurrences of this field")]),_c('Toggle',{attrs:{"checked":_vm.activeMetadata.multiple,"disabled":_vm.disableMultiple,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.multiple ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.multiple),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "multiple", $$v)},expression:"activeMetadata.multiple"}})],1),_c('div',{staticClass:"flex items-center justify-between w-full",class:{ 'xl:w-1/2': _vm.collapsedLeftPane }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                        'An indication whether ordering is important for multiple appearances of the same concept.',
                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',
                }),expression:"{\n                    content:\n                        'An indication whether ordering is important for multiple appearances of the same concept.',\n                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',\n                }"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Order of multiple occurrences is important")]),_c('Toggle',{attrs:{"checked":_vm.activeMetadata.ordered,"disabled":_vm.disableOrdered,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.ordered ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.ordered),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "ordered", $$v)},expression:"activeMetadata.ordered"}})],1)]),_c('div',{staticClass:"space-y-2",class:{
            'xl:flex xl:justify-between xl:items-center xl:space-y-0': _vm.collapsedLeftPane,
        }},[_c('div',{staticClass:"flex items-center justify-between w-full mr-2",class:{ 'xl:w-1/2': _vm.collapsedLeftPane }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: 'An indication whether the specific concept can be encrypted.',
                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',
                }),expression:"{\n                    content: 'An indication whether the specific concept can be encrypted.',\n                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',\n                }"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Allow field to be encrypted")]),_c('Toggle',{attrs:{"checked":_vm.activeMetadata.encryption,"disabled":_vm.readOnly,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.encryption ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.encryption),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "encryption", $$v)},expression:"activeMetadata.encryption"}})],1),_c('div',{staticClass:"flex items-center justify-between w-full",class:{ 'xl:w-1/2': _vm.collapsedLeftPane }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                        'An indication whether the specific concept shall contain data that must be treated with extra security as they pertain to the privacy of an individual or organisation, such as racial or ethnic origin; religious or philosophical beliefs; genetic data, etc.',
                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',
                }),expression:"{\n                    content:\n                        'An indication whether the specific concept shall contain data that must be treated with extra security as they pertain to the privacy of an individual or organisation, such as racial or ethnic origin; religious or philosophical beliefs; genetic data, etc.',\n                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',\n                }"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Might contain sensitive information")]),_c('Toggle',{attrs:{"checked":_vm.activeMetadata.sensitive,"disabled":_vm.disableSensitive,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.sensitive ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.sensitive),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "sensitive", $$v)},expression:"activeMetadata.sensitive"}})],1)]),_c('div',{staticClass:"space-y-2",class:{
            'xl:flex xl:justify-between xl:items-center xl:space-y-0 ': _vm.collapsedLeftPane,
        }},[_c('div',{staticClass:"flex items-center justify-between w-full",class:{ 'xl:w-1/2': _vm.collapsedLeftPane }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                        'An indication whether the specific concept shall contain data that directly identify an individual and will need to be anonymised prior to being stored.',
                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',
                }),expression:"{\n                    content:\n                        'An indication whether the specific concept shall contain data that directly identify an individual and will need to be anonymised prior to being stored.',\n                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',\n                }"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Might contain identifying information")]),_c('Toggle',{attrs:{"checked":_vm.activeMetadata.identifier,"disabled":_vm.readOnly,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.identifier ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.identifier),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "identifier", $$v)},expression:"activeMetadata.identifier"}})],1),_c('div',{staticClass:"flex items-center justify-between w-full",class:{ 'xl:w-1/2 xl:ml-2': _vm.collapsedLeftPane }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                        'An indication whether the specific field may denote the temporal coverage of the asset.',
                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',
                }),expression:"{\n                    content:\n                        'An indication whether the specific field may denote the temporal coverage of the asset.',\n                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',\n                }"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Denoting the temporal coverage of the data")]),_c('Toggle',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                    _vm.disableTemporal
                        ? "Enabled only if the \"Allow indexing of this field in Elasticsearch\" option is also set to true"
                        : ''
                ),expression:"\n                    disableTemporal\n                        ? `Enabled only if the \"Allow indexing of this field in Elasticsearch\" option is also set to true`\n                        : ''\n                "}],attrs:{"checked":_vm.activeMetadata.temporal,"disabled":_vm.disableTemporal,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.temporal ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.temporal),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "temporal", $$v)},expression:"activeMetadata.temporal"}})],1)]),_c('div',{staticClass:"space-y-2",class:{
            'xl:flex xl:justify-between xl:items-center xl:space-y-0': _vm.collapsedLeftPane,
        }},[_c('div',{staticClass:"flex items-center justify-between w-full",class:{
                'xl:w-1/2': _vm.collapsedLeftPane,
            }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("An indication whether the specific field should be indexed in MongoDB."),expression:"`An indication whether the specific field should be indexed in MongoDB.`"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Allow indexing of this field in MongoDB")]),_c('Toggle',{attrs:{"checked":_vm.activeMetadata.indexMongo,"disabled":_vm.disableIndexMongo,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.indexMongo ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.indexMongo),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "indexMongo", $$v)},expression:"activeMetadata.indexMongo"}})],1),_c('div',{staticClass:"flex items-center justify-between w-full",class:{
                'xl:w-1/2': _vm.collapsedLeftPane,
                'xl:ml-2': _vm.collapsedLeftPane,
            }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("An indication whether the specific field should be indexed in Elasticsearch."),expression:"`An indication whether the specific field should be indexed in Elasticsearch.`"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Allow indexing of this field in Elasticsearch")]),_c('Toggle',{attrs:{"checked":_vm.activeMetadata.indexES,"disabled":_vm.disableIndexES,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.indexES ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.indexES),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "indexES", $$v)},expression:"activeMetadata.indexES"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }