




























































































































































































































import store from '@/app/store';
import Keycloak from '@/modules/auth/api/keycloak';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';

export default defineComponent({
    name: 'Home',
    metaInfo: {
        title: 'Home',
    },
    components: { OrbitSpinner },
    setup(props, { root }) {
        const open = ref(false);
        const isLoggingIn = ref(false);
        const isAuthenticated = computed(() => store.getters.auth.isAuthenticated);

        const useKeycloak = computed(() => Keycloak.isEnabled());
        const login = async () => {
            isLoggingIn.value = true;
            try {
                await Keycloak.login();
            } catch (e: any) {
                (root as any).$toastr.e(e.message, 'Error');
            } finally {
                isLoggingIn.value = false;
            }
        };
        const register = async () => {
            await Keycloak.register();
        };
        const keycloakAuthentication = async () => {
            if (store.getters.auth.isAuthenticated) {
                root.$router.push({ name: 'home', hash: '' });
            } else {
                isLoggingIn.value = true;
                try {
                    await Keycloak.login();
                } catch (e: any) {
                    (root as any).$toastr.e(e.message, 'Error');
                } finally {
                    isLoggingIn.value = false;
                }
            }
        };
        if (
            Keycloak.isEnabled() &&
            document.location.hash.length > 0 &&
            document.location.hash.match(/^#.*code=[a-zA-Z0-9]*/g)
        ) {
            keycloakAuthentication();
        }

        return { open, isAuthenticated, login, useKeycloak, register, isLoggingIn };
    },
});
