export enum ReimbursementMethod {
    Bank = 'bank',
    Online = 'online',
    Bartering = 'bartering',
}

export const ReimbursementMethodText = {
    [ReimbursementMethod.Bank]: 'Through Payment via a Credit / Debit Card and/or a Bank Transfer',
    [ReimbursementMethod.Online]: 'Through a Crypto-Currency Payment in SYNERGIES',
    [ReimbursementMethod.Bartering]: 'Available for Bartering',
};

export const ReimbursementMethodShortText = {
    [ReimbursementMethod.Bank]: 'Bank Transfer',
    [ReimbursementMethod.Online]: 'Crypto-Payment',
    [ReimbursementMethod.Bartering]: 'Exchange',
};
