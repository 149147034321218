var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FormBlock',{attrs:{"title":_vm.title,"description":_vm.description}},[(_vm.running && !_vm.streaming)?_c('div',{staticClass:"text-sm italic text-neutral-600"},[_vm._v(" You cannot change the retrieval settings while the data check-in pipeline is running ")]):_c('div',{staticClass:"flex items-center flex-auto"},[_c('div',{staticClass:"flex-initial w-24 mr-2 text-sm text-neutral-700"},[_c('span',[_vm._v("Retrieve until")])]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-date-picker',{attrs:{"masks":{ input: 'DD MMMM YYYY' },"available-dates":[
                        {
                            start: new Date(_vm.retrievalEndDate),
                            end: new Date(_vm.retrievalEndDate),
                        },
                        {
                            start: _vm.today,
                            end: null,
                        } ],"model-config":{ timeAdjust: '00:00:00' },"timezone":"utc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var inputValue = ref.inputValue;
                    var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"w-56 py-1 text-sm text-center text-neutral-800 form-input",class:{ 'border-red-700': errors.length > 0 },attrs:{"type":"text","placeholder":"End Date","disabled":_vm.isFinalized},domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.retrievalEndDate),callback:function ($$v) {_vm.retrievalEndDate=$$v},expression:"retrievalEndDate"}})]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }