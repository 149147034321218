import { ESortingOption } from '@/app/types';
import { isNil } from 'ramda';

export class MarketplaceSortingOption extends ESortingOption {
    static readonly TITLE_ASC = new MarketplaceSortingOption('title', true, 'Title Ascending');

    static readonly TITLE_DESC = new MarketplaceSortingOption('title', false, 'Title Descending');

    static readonly DATE_CREATED_DESC = new MarketplaceSortingOption(
        'date_created',
        false,
        'Date Created (newest first)',
    );

    static readonly DATE_CREATED_ASC = new MarketplaceSortingOption(
        'date_created',
        true,
        'Date Created (oldest first)',
    );

    static readonly DATE_UPDATED_DESC = new MarketplaceSortingOption(
        'date_updated',
        false,
        'Date Updated (newest first)',
    );

    static readonly DATE_UPDATED_ASC = new MarketplaceSortingOption(
        'date_updated',
        true,
        'Date Updated (oldest first)',
    );

    static readonly DATA_PROVIDER_ASC = new MarketplaceSortingOption(
        'organisation_name',
        true,
        'Data Provider Ascending',
    );

    static readonly DATA_PROVIDER_DESC = new MarketplaceSortingOption(
        'organisation_name',
        false,
        'Data Provider Descending',
    );

    static all(): ESortingOption[] {
        return [...super.all(), ...this.local()];
    }

    static default(relevanceAvailable: boolean): ESortingOption {
        const calculatedDefault = super.calculateDefault(relevanceAvailable);
        if (!isNil(calculatedDefault)) return calculatedDefault;

        return this.DATE_UPDATED_DESC;
    }

    static available(relevanceAvailable: boolean): ESortingOption[] {
        return [...super.available(relevanceAvailable), ...this.local()];
    }

    private static local(): MarketplaceSortingOption[] {
        return [
            this.DATE_CREATED_DESC,
            this.DATE_CREATED_ASC,

            this.DATE_UPDATED_DESC,
            this.DATE_UPDATED_ASC,

            this.DATA_PROVIDER_DESC,
            this.DATA_PROVIDER_ASC,

            this.TITLE_DESC,
            this.TITLE_ASC,
        ];
    }
}
