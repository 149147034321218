
























import { computed, defineComponent, PropType } from '@vue/composition-api';
import { Contract, ContractTransaction } from '../../types';
import TransactionItem from './TransactionItem.vue';
import * as R from 'ramda';

export default defineComponent({
    name: 'ContractHistory',
    components: {
        TransactionItem,
    },
    props: {
        contract: {
            type: Object as PropType<Contract>,
            required: true,
        },
    },
    setup(props) {
        const sortedTransactions = computed(
            () => R.sortWith([R.ascend(R.prop('createdAt'))])(props.contract.transactions) as ContractTransaction[],
        );

        return { sortedTransactions };
    },
});
