import router from '@/app/router';
import store from '@/app/store';
import Keycloak from '@/modules/auth/api/keycloak';
import { Route } from 'vue-router';

export function useErrors(route: Route | null = null) {
    const redirectToLogin = () => {
        if (!store.getters.auth.isAuthenticated) return;
        store.commit.auth.CLEAR_USER();
        store.commit.sharing.CLEAR_NETWORK();
        if (Keycloak.isEnabled()) {
            Keycloak.login(route);
        } else if (route) {
            router.push({ name: 'login', params: { path: route.fullPath } });
        } else {
            router.push({ name: 'login' });
        }
    };

    const parseGQLError = (error: any): void => {
        const { graphQLErrors }: { graphQLErrors: any[] } = error;
        if (graphQLErrors)
            graphQLErrors.forEach(({ message }: { message: string }) => {
                if (message === 'Unauthorized') redirectToLogin();
            });
    };

    const checkGQLAuthentication = (error: any): void => {
        console.warn('checkGQLAuthentication is deprecated! Feel free to remove it from your code!');
    };

    return { redirectToLogin, checkGQLAuthentication, parseGQLError };
}
