import { AxiosRequestConfig } from 'axios';

const endpoint = '/api/contracts/derivation';

export default {
    getAll: () => ({ method: 'GET', url: `${endpoint}` } as AxiosRequestConfig),
    get: (id: string) => ({ method: 'GET', url: `${endpoint}/${id}` } as AxiosRequestConfig),
    create: (data: any) => ({ method: 'POST', url: `${endpoint}`, data } as AxiosRequestConfig),
    declineRequest: (id: string) =>
        ({ method: 'PATCH', url: `${endpoint}/${id}/decline-request` } as AxiosRequestConfig),
    signContract: (id: string, data: any, pending = false) =>
        ({ method: 'PUT', url: `${endpoint}/${id}/sign?pending=${pending}`, data } as AxiosRequestConfig),
    acceptContract: (id: string, pending = false) =>
        ({ method: 'PATCH', url: `${endpoint}/${id}/accept?pending=${pending}` } as AxiosRequestConfig),
    acceptCounterOffer: (id: string, pending = false) =>
        ({ method: 'PATCH', url: `${endpoint}/${id}/accept-counter-offer?pending=${pending}` } as AxiosRequestConfig),
    rejectContract: (id: string, pending = false) =>
        ({ method: 'PATCH', url: `${endpoint}/${id}/reject?pending=${pending}` } as AxiosRequestConfig),
    rejectCounterOffer: (id: string, pending = false) =>
        ({ method: 'PATCH', url: `${endpoint}/${id}/reject-counter-offer?pending=${pending}` } as AxiosRequestConfig),
    negotiate: (id: string, data: any, pending = false) =>
        ({ method: 'PUT', url: `${endpoint}/${id}/negotiate?pending=${pending}`, data } as AxiosRequestConfig),
    counterOffer: (id: string, data: any, pending = false) =>
        ({ method: 'PUT', url: `${endpoint}/${id}/counter-offer?pending=${pending}`, data } as AxiosRequestConfig),
};
