import { NavigationGuard, Route } from 'vue-router';
import { AuthGuard } from '@/modules/auth/router/auth.guard';

type Next = Parameters<NavigationGuard>[2];

export default [
    {
        name: 'model-manager',
        path: '/models',
        component: () => import(/* webpackChunkName: "data-model" */ '@/modules/data-model/views/Models.vue'),
        meta: { title: 'Models', feature: 'model-manager' },
        beforeEnter: AuthGuard,
    },
    {
        name: 'model-manager:edit',
        path: '/models/:id',
        component: () => import(/* webpackChunkName: "data-model" */ '@/modules/data-model/views/ModelManager.vue'),
        props: true,
        meta: { title: 'View/Edit Model', feature: 'model-manager' },
        beforeEnter: AuthGuard,
    },
];
