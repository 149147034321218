export enum AnonymisationType {
    All = 'all',
    Insensitive = 'insensitive',
    Identifier = 'identifier',
    QuasiIdentifier = 'quasi-identifier',
    Sensitive = 'sensitive',
}

export enum GeneralizationMethod {
    Interval = 'interval',
    NumericalGroup = 'numerical-group',
    Masking = 'masking',
    BooleanGroup = 'boolean-group',
    Datetime = 'datetime',
    Date = 'date',
    Time = 'time',
}

export const AnonymisationDescriptions = {
    insensitive: 'This field will remain unchanged and it will not have any effect on the anonymisation algorithm.',
    sensitive:
        'The field values will remain unchanged. The anonymisation algorithm will make sure to protect an individual to be identified by generalising the quasi-identifiers.',
    interval:
        'The field values will likely be generalised to arithmetic intervals of some generalisation level. The anonymisation algorithm will try to stay at the lowest possible level of generalisation.',
    numericalGroup:
        'The field values will likely be generalised to numerical groups of some generalisation level. The anonymisation algorithm will try to stay at the lowest possible level of generalisation.',
    masking:
        'The field values will likely be obscured by the masking character starting from right to left. The anonymisation algorithm will try to hide as few characters as possible.',
    datetime:
        'The field values will likely be generalised by resetting different parts of the datetime, starting from the seconds until the year. The anonymisation algorithm will try to reset as few datetime parts as possible.',
    date:
        'The field values will likely be generalised by resetting different parts of the date, starting from the day until the year. The anonymisation algorithm will try to reset as few datetime parts as possible.',
    time:
        'The field values will likely be generalised by resetting different parts of the time, starting from the seconds until the hours. The anonymisation algorithm will try to reset as few time parts as possible.',
    booleanGroup:
        'The field values will likely be generalised by including both boolean values in the same group or by dropping the column. The anonymisation algorithm will try not to change the values if possible.',
    groupBoolean: 'The boolean values will be grouped together if this field is generalised.',
    dropBoolean: 'The column will be dropped if this field is generalised.',
    dropColumn: 'This column will be dropped from the dataset.',
};
