import store from '@/app/store';
import { computed, ref } from '@vue/composition-api';

export function useCart() {
    const cartLimit = 5;
    const isCartOpen = ref<boolean>(false);
    const showEmptyCartModal = ref<boolean>(false);

    const cart = computed(() => store.state.sharing.cart);
    const cartAssetIds = computed(() => cart.value.map((asset: any) => asset.id));

    const openCart = () => {
        isCartOpen.value = false;
    };

    const closeCart = () => {
        isCartOpen.value = false;
    };

    const clearCart = () => {
        store.commit.sharing.CLEAR_CART();
        localStorage.setItem('cart', JSON.stringify([]));
    };

    const getCartFromLocalStorage = () => {
        const cartAssets: any = localStorage.getItem('cart');
        if (cartAssets) return JSON.parse(cartAssets);
        return [];
    };

    store.commit.sharing.SET_CART(getCartFromLocalStorage());

    const addToCart = (asset: any) => {
        if (cart.value.length >= cartLimit) return;
        store.commit.sharing.ADD_TO_CART(asset);
        localStorage.setItem('cart', JSON.stringify(cart.value));
    };

    const removeFromCart = (assetId: number) => {
        store.commit.sharing.REMOVE_FROM_CART(assetId);
        localStorage.setItem('cart', JSON.stringify(cart.value));
    };

    return {
        cartLimit,
        isCartOpen,
        showEmptyCartModal,
        cart,
        cartAssetIds,
        openCart,
        closeCart,
        addToCart,
        removeFromCart,
        clearCart,
    };
}
