






























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'BlockItem',
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
        },
        additionalClasses: {
            type: String,
            default: '',
        },
    },
});
