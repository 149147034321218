import { AxiosRequestConfig } from 'axios';
import { forEachObjIndexed } from 'ramda';

export default {
    getPolicy: (jobId: number, folder: string, subfolder = '') =>
        ({
            method: 'GET',
            url: `/api/data-checkin/jobs/${jobId}/policy/${folder}?subfolder=${subfolder}`,
        } as AxiosRequestConfig),
    file: (file: any, filename: string, config: any, onUploadProgressCallback?: (progressEvent: any) => void) => {
        const formData = new FormData();
        formData.append('key', `${config.prefix}/${filename}`);
        formData.append('file', file);

        forEachObjIndexed((value: any, key: any) => {
            formData.append(key, value);
        }, config.formData);

        return {
            url: config.postURL,
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: onUploadProgressCallback,
        } as AxiosRequestConfig;
    },
};
