






































































































































































































































































import { FormBlock, InputErrorIcon } from '@/app/components';
import { useAxios, useFilters } from '@/app/composable';
import { maxValueValidator, minValueValidator, requiredValidator } from '@/app/validators';
import { AssetsAPI } from '@/modules/asset/api';
import { useEthers } from '@/modules/sharing/composable';
import {
    ContractStatus,
    Currency,
    ReimbursementMethod,
    ReimbursementMethodText,
    currencySymbol,
} from '@/modules/sharing/constants';
import { SwitchHorizontalIcon } from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { ValidationProvider, extend } from 'vee-validate';
import { Contract, ContractTransaction } from '../../types';
import EthersConverter from './EthersConverter.vue';

extend('required', requiredValidator);
extend('min_value', minValueValidator);
extend('max_value', maxValueValidator);

export default defineComponent({
    name: 'PaymentDetails',
    components: {
        FormBlock,
        ValidationProvider,
        InputErrorIcon,
        EthersConverter,
        SwitchHorizontalIcon,
    },
    props: {
        contract: {
            type: Object as PropType<Contract>,
            required: true,
        },
        updatingContract: {
            type: Object as PropType<Contract>,
            default: null,
        },
        onEditMode: {
            type: Boolean,
            default: false,
        },
        negotiating: {
            type: Boolean,
            default: false,
        },
        previousNegotiation: {
            type: Object as PropType<ContractTransaction>,
            default: () => ({}),
        },
        isBundle: {
            type: Boolean,
            default: false,
        },
        isConsumer: {
            type: Boolean,
            default: false,
        },
        totalCosts: {
            type: Object,
            default: null,
        },
    },
    setup(props) {
        const exchangeRates = ref<any>(null);
        const otherCurrency = ref<Currency>(Currency.Euro);
        const contractCost = ref<number>(R.clone(props.contract.metadata.pricing.cost));
        const barteringAssets = ref<{ id: number; name: string }[]>([]);

        const { exec } = useAxios(true);

        const initialCost = computed(() =>
            props.updatingContract ? props.updatingContract.metadata.pricing.cost : contractCost.value,
        );

        const { formatDecimals } = useFilters();
        const { transformToEthers, transformToOtherCurrency } = useEthers();

        const totalDerivationCost = computed(() => {
            if (props.totalCosts) return Number(props.totalCosts[props.contract.id].totalDerivationCost);
            return 0;
        });

        const totalDerivationCostInOtherCurrency = computed(() => {
            if (exchangeRates.value && otherCurrency.value) {
                return totalDerivationCost.value * exchangeRates.value[otherCurrency.value];
            }
            return 0;
        });

        const reviseContract = computed(() => !!props.contract.metadata.revise);

        const totalCost = computed(() => {
            if ((!props.onEditMode || reviseContract.value) && props.totalCosts) {
                const cost = Number(props.totalCosts[props.contract.id].totalCost);
                if (cost && cost - totalDerivationCost.value !== Number(props.contract.metadata.pricing.cost)) {
                    return cost;
                }
            }
            return Number(props.contract.metadata.pricing.cost) + totalDerivationCost.value;
        });

        const commissionFeePercentage = computed(() => {
            if (props.totalCosts) return Number(props.totalCosts[props.contract.id].commissionFeePercentage);
            return 0;
        });

        const commissionFee = computed(() => Number((totalCost.value * commissionFeePercentage.value) / 100));

        const commissionFeeInOtherCurrency = computed(() => {
            if (exchangeRates.value && otherCurrency.value)
                return commissionFee.value * exchangeRates.value[otherCurrency.value];
            return 0;
        });

        const totalCostWithCommissionFee = computed(() => Number(totalCost.value + commissionFee.value));

        const totalCostInOtherCurrency = computed(() => {
            if (exchangeRates.value && otherCurrency.value)
                return totalCostWithCommissionFee.value * exchangeRates.value[otherCurrency.value];
            return 0;
        });

        const showTotalCost = computed(
            () =>
                Number(formatDecimals(props.contract.metadata.pricing.cost, 4)) !== totalCostWithCommissionFee.value &&
                (!props.contract.updatingContractId || reviseContract.value),
        );

        const currencyChanged = (currency: Currency, rates: any) => {
            otherCurrency.value = currency;
            exchangeRates.value = rates;
        };

        const changePaymentMethod = (event: any) => {
            const method = event.target.value;
            const { cost, currency } = props.contract.metadata.pricing;
            if (method === ReimbursementMethod.Bank) {
                props.contract.metadata.pricing.currency = Currency.Euro;
                if (currency !== Currency.Euro) {
                    props.contract.metadata.pricing.cost = transformToOtherCurrency(cost, Currency.Euro);
                }
            } else if (method === ReimbursementMethod.Online) {
                props.contract.metadata.pricing.cost = transformToEthers(cost, currency);
                props.contract.metadata.pricing.currency = Currency.Ether;
            } else {
                props.contract.metadata.pricing.cost = 0;
            }
        };

        if (props.contract.asset?.metadata.pricing.reimbursementMethod.includes(ReimbursementMethod.Bartering))
            exec(AssetsAPI.getBarteringAssets(props.contract.consumerOrgId)).then((res: any) => {
                barteringAssets.value = res?.data;
                if (
                    props.contract.barteringAsset &&
                    !barteringAssets.value.find((asset) => asset.id === props.contract.barteringAssetId)
                )
                    barteringAssets.value.push({
                        id: props.contract.barteringAsset.id,
                        name: props.contract.barteringAsset.name,
                    });
            });

        return {
            R,
            ReimbursementMethod,
            ReimbursementMethodText,
            reviseContract,
            otherCurrency,
            ContractStatus,
            currencySymbol,
            totalDerivationCostInOtherCurrency,
            totalCostInOtherCurrency,
            commissionFeeInOtherCurrency,
            Currency,
            totalDerivationCost,
            currencyChanged,
            totalCostWithCommissionFee,
            commissionFee,
            commissionFeePercentage,
            initialCost,
            formatDecimals,
            changePaymentMethod,
            showTotalCost,
            barteringAssets,
        };
    },
});
