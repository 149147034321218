import { useAxios } from '@/app/composable';
import store from '@/app/store';
import { computed, ref } from '@vue/composition-api';
import { ethers } from 'ethers';
import { keccak256 } from 'js-sha3';
import * as R from 'ramda';
import { ContractTransactionsAPI } from '../api';
import {
    BlockchainMessage,
    ContractDurationType,
    ContractStatus,
    ReimbursementMethod,
    bundleContractABI,
    contractABI,
    coreABI,
    derivationContractABI,
    platformTerms,
} from '../constants';
import { Bundle, Contract, ContractDuration } from '../types';

export function useBlockchain() {
    const { exec } = useAxios(true);

    const message = ref<BlockchainMessage | null>(null);

    const getContractStatus = (status: ContractStatus) => {
        switch (status) {
            case ContractStatus.Draft:
                return 0;
            case ContractStatus.Negotiate:
                return 1;
            case ContractStatus.Rejected:
                return 2;
            case ContractStatus.Signed:
                return 3;
            case ContractStatus.Active:
                return 4;
            case ContractStatus.Expired:
                return 5;
            default:
                return 0;
        }
    };

    const getReimbursementMethod = (reimbursementMethod?: ReimbursementMethod | null) => {
        switch (reimbursementMethod) {
            case ReimbursementMethod.Bank:
                return 0;
            case ReimbursementMethod.Online:
                return 1;
            case ReimbursementMethod.Bartering:
                return 2;
            default:
                return 0;
        }
    };

    const getDuration = (duration: { type: ContractDurationType; number: string | number | null }) => {
        switch (duration.type) {
            case ContractDurationType.Day:
                return Number(duration.number);
            case ContractDurationType.Month:
                return Number(duration.number) * 30;
            case ContractDurationType.Year:
                return Number(duration.number) * 365;
            case ContractDurationType.Forever:
                return 1000000;
            default:
                return 0;
        }
    };

    const contractAddress = computed(() => store.state.sharing.contractAddress);
    const derivationContractAddress = computed(() => store.state.sharing.derivationContractAddress);
    const proxyAdminAddress = computed(() => store.state.sharing.proxyAdminAddress);

    const createBundleContract = async (
        wallet: any,
        bundle: Bundle,
        providerEthaddresses: string[],
    ): Promise<string> => {
        message.value = BlockchainMessage.Connecting;
        const core = new ethers.Contract(contractAddress.value, coreABI, wallet);

        message.value = BlockchainMessage.Creating;
        const tx = await core.createBundleContract(
            proxyAdminAddress.value,
            providerEthaddresses,
            bundle.contracts.map((contract: Contract) => keccak256.array(`${contract.asset?.id}`)),
            bundle.contracts.length,
            { gasLimit: 8000000 },
        );

        message.value = BlockchainMessage.Transaction;
        const receipt: any = await tx.wait();
        const contractCreated = receipt.events.pop();
        message.value = BlockchainMessage.Database;
        return contractCreated.args[0];
    };

    const rejectBundle = async (wallet: any, ethaddress: string, pendingTransactionId: string) => {
        message.value = BlockchainMessage.Connecting;
        const smartContract = new ethers.Contract(ethaddress, bundleContractABI, wallet);
        message.value = BlockchainMessage.Rejecting;
        try {
            const tx = await smartContract.reject({ gasLimit: 8000000 });
            await exec(ContractTransactionsAPI.update(pendingTransactionId, { transactionHash: tx.hash }));
            message.value = BlockchainMessage.Transaction;
            await tx.wait();
            message.value = BlockchainMessage.Database;
        } catch (error) {
            await exec(
                ContractTransactionsAPI.update(pendingTransactionId, {
                    transactionHash: (error as any).transactionHash,
                }),
            );
            throw error;
        }
    };

    const createContract = async (
        wallet: any,
        contract: Contract,
        bundle: Bundle | null,
        pendingTransactionId = null,
        previousDuration: ContractDuration | null = null,
    ): Promise<string> => {
        message.value = BlockchainMessage.Connecting;
        const core = new ethers.Contract(contractAddress.value, coreABI, wallet);

        message.value = BlockchainMessage.Creating;

        let duration = getDuration(contract.duration);
        if (
            contract.updatingContractId &&
            contract.metadata &&
            !contract.metadata.revise &&
            contract.duration.type !== 'forever' &&
            previousDuration
        ) {
            duration += getDuration(previousDuration);
        }
        try {
            const tx = await core.createContract(
                proxyAdminAddress.value,
                contract.updatingContractId ? contract.ethaddress : '0x0000000000000000000000000000000000000000',
                [
                    getContractStatus(contract.status as ContractStatus),
                    getReimbursementMethod(contract.metadata?.pricing?.reimbursementMethod),
                    contract.consumerOrg.ethaddress,
                    keccak256.array(
                        contract.updatingContractId ? `${contract.asset?.id}-updating` : `${contract.asset?.id}`,
                    ),
                    keccak256.array(contract.barteringAssetId ? `${contract.barteringAssetId}` : ''),
                    keccak256.hex(JSON.stringify(contract.fields)),
                    duration,
                    keccak256.array(
                        contract.metadata?.pricing
                            ? `${contract.metadata.pricing.cost}-${contract.metadata.pricing.vat}-${contract.metadata.pricing.currency}`
                            : '',
                    ),
                    keccak256.array(contract.terms ?? ''),
                    keccak256.array(platformTerms),
                    bundle ? bundle.ethaddress : '0x0000000000000000000000000000000000000000',
                    contract.metadata?.pricing?.reimbursementMethod === ReimbursementMethod.Online &&
                    !R.isNil(contract.metadata?.pricing?.cost)
                        ? ethers.utils.parseEther(contract.metadata.pricing.cost.toString())
                        : 0,
                    derivationContractAddress.value,
                    keccak256.array(
                        contract.metadata.license
                            ? `${contract.metadata.license.expectedUsage.join(
                                  ',',
                              )}-${contract.metadata.license.offlineRetention.join(',')}`
                            : '',
                    ),
                ],
                { gasLimit: 8000000 },
            );
            if (pendingTransactionId)
                await exec(ContractTransactionsAPI.update(String(pendingTransactionId), { transactionHash: tx.hash }));

            message.value = BlockchainMessage.Transaction;
            const receipt: any = await tx.wait();
            const contractCreated = receipt.events.pop();
            message.value = BlockchainMessage.Database;
            return contractCreated.args[0];
        } catch (error) {
            if (pendingTransactionId)
                await exec(
                    ContractTransactionsAPI.update(String(pendingTransactionId), {
                        transactionHash: (error as any).transactionHash,
                    }),
                );
            throw error;
        }
    };

    const accept = async (wallet: any, ethaddress: string, pendingTransactionId: string, derivation = false) => {
        message.value = BlockchainMessage.Connecting;
        const abi = derivation ? derivationContractABI : contractABI;
        const smartContract = new ethers.Contract(ethaddress, abi, wallet);
        message.value = BlockchainMessage.Accepting;
        try {
            const tx = await smartContract.acceptContract({ gasLimit: 8000000 });
            await exec(ContractTransactionsAPI.update(pendingTransactionId, { transactionHash: tx.hash }));
            message.value = BlockchainMessage.Transaction;
            await tx.wait();
            message.value = BlockchainMessage.Database;
        } catch (error) {
            await exec(
                ContractTransactionsAPI.update(pendingTransactionId, {
                    transactionHash: (error as any).transactionHash,
                }),
            );
            throw error;
        }
    };

    const reject = async (wallet: any, ethaddress: string, pendingTransactionId: string, derivation = false) => {
        message.value = BlockchainMessage.Connecting;
        const abi = derivation ? derivationContractABI : contractABI;
        const smartContract = new ethers.Contract(ethaddress, abi, wallet);
        message.value = BlockchainMessage.Rejecting;
        try {
            const tx = await smartContract.rejectContract({ gasLimit: 8000000 });
            await exec(ContractTransactionsAPI.update(pendingTransactionId, { transactionHash: tx.hash }));
            message.value = BlockchainMessage.Transaction;
            await tx.wait();
            message.value = BlockchainMessage.Database;
        } catch (error) {
            await exec(
                ContractTransactionsAPI.update(pendingTransactionId, {
                    transactionHash: (error as any).transactionHash,
                }),
            );
            throw error;
        }
    };

    const acceptOffer = async (wallet: any, ethaddress: string, pendingTransactionId: string, derivation = false) => {
        message.value = BlockchainMessage.Connecting;
        const abi = derivation ? derivationContractABI : contractABI;
        const smartContract = new ethers.Contract(ethaddress, abi, wallet);
        message.value = BlockchainMessage.Accepting;
        try {
            const tx = await smartContract.acceptOffer({ gasLimit: 8000000 });
            await exec(ContractTransactionsAPI.update(pendingTransactionId, { transactionHash: tx.hash }));
            message.value = BlockchainMessage.Transaction;
            await tx.wait();
            message.value = BlockchainMessage.Database;
        } catch (error) {
            await exec(
                ContractTransactionsAPI.update(pendingTransactionId, {
                    transactionHash: (error as any).transactionHash,
                }),
            );
            throw error;
        }
    };

    const rejectOffer = async (wallet: any, ethaddress: string, pendingTransactionId: string, derivation = false) => {
        message.value = BlockchainMessage.Connecting;
        const abi = derivation ? derivationContractABI : contractABI;
        const smartContract = new ethers.Contract(ethaddress, abi, wallet);
        message.value = BlockchainMessage.Rejecting;
        try {
            const tx = await smartContract.rejectOffer({ gasLimit: 8000000 });
            await exec(ContractTransactionsAPI.update(pendingTransactionId, { transactionHash: tx.hash }));
            message.value = BlockchainMessage.Transaction;
            await tx.wait();
            message.value = BlockchainMessage.Database;
        } catch (error) {
            await exec(
                ContractTransactionsAPI.update(pendingTransactionId, {
                    transactionHash: (error as any).transactionHash,
                }),
            );
            throw error;
        }
    };

    const negotiate = async (wallet: any, contract: Contract, pendingTransactionId: string) => {
        message.value = BlockchainMessage.Connecting;
        const smartContract = new ethers.Contract(contract.ethaddress ?? '', contractABI, wallet);
        message.value = BlockchainMessage.Updated;
        try {
            const tx = await smartContract.negotiate(
                contract.metadata?.pricing?.reimbursementMethod === ReimbursementMethod.Online &&
                    !R.isNil(contract.metadata?.pricing?.cost)
                    ? ethers.utils.parseEther(contract.metadata.pricing.cost.toString())
                    : 0,
                keccak256.array(contract.terms ?? ''),
                keccak256.array(
                    contract.metadata?.pricing
                        ? `${contract.metadata.pricing.cost}-${contract.metadata.pricing.vat}-${contract.metadata.pricing.currency}`
                        : '',
                ),
                keccak256.array(
                    contract.metadata.license
                        ? `${contract.metadata.license.expectedUsage.join(
                              ',',
                          )}-${contract.metadata.license.offlineRetention.join(',')}`
                        : '',
                ),
                { gasLimit: 8000000 },
            );
            await exec(ContractTransactionsAPI.update(pendingTransactionId, { transactionHash: tx.hash }));
            message.value = BlockchainMessage.Transaction;
            await tx.wait();
            message.value = BlockchainMessage.Database;
        } catch (error) {
            await exec(
                ContractTransactionsAPI.update(pendingTransactionId, {
                    transactionHash: (error as any).transactionHash,
                }),
            );
            throw error;
        }
    };

    const counterOffer = async (wallet: any, contract: Contract, pendingTransactionId: string) => {
        message.value = BlockchainMessage.Connecting;
        const smartContract = new ethers.Contract(contract.ethaddress ?? '', contractABI, wallet);
        message.value = BlockchainMessage.Updated;
        try {
            const tx = await smartContract.counterOffer(
                contract.metadata?.pricing?.reimbursementMethod === ReimbursementMethod.Online &&
                    !R.isNil(contract.metadata?.pricing?.cost)
                    ? ethers.utils.parseEther(contract.metadata.pricing.cost.toString())
                    : 0,
                keccak256.array(contract.terms ?? ''),
                keccak256.array(
                    contract.metadata?.pricing
                        ? `${contract.metadata.pricing.cost}-${contract.metadata.pricing.vat}-${contract.metadata.pricing.currency}`
                        : '',
                ),
                keccak256.array(
                    contract.metadata.license
                        ? `${contract.metadata.license.expectedUsage.join(
                              ',',
                          )}-${contract.metadata.license.offlineRetention.join(',')}`
                        : '',
                ),
                { gasLimit: 8000000 },
            );
            await exec(ContractTransactionsAPI.update(pendingTransactionId, { transactionHash: tx.hash }));
            message.value = BlockchainMessage.Transaction;
            await tx.wait();
            message.value = BlockchainMessage.Database;
        } catch (error) {
            await exec(
                ContractTransactionsAPI.update(pendingTransactionId, {
                    transactionHash: (error as any).transactionHash,
                }),
            );
            throw error;
        }
    };

    const contractActive = async (wallet: any, ethaddress: string, pendingTransactionId: string) => {
        message.value = BlockchainMessage.Connecting;
        const smartContract = new ethers.Contract(ethaddress, contractABI, wallet);
        message.value = BlockchainMessage.Activating;
        try {
            const tx = await smartContract.contractActive({ gasLimit: 8000000 });
            await exec(ContractTransactionsAPI.update(pendingTransactionId, { transactionHash: tx.hash }));
            message.value = BlockchainMessage.Transaction;
            await tx.wait();
            message.value = BlockchainMessage.Database;
        } catch (error) {
            await exec(
                ContractTransactionsAPI.update(pendingTransactionId, {
                    transactionHash: (error as any).transactionHash,
                }),
            );
            throw error;
        }
    };

    const payContractsAndMarkAsActive = async (
        wallet: any,
        ethaddress: string,
        totalCost: number,
        pendingTransactionId: string,
        bundle = false,
    ) => {
        message.value = BlockchainMessage.Connecting;
        const abi = bundle ? bundleContractABI : contractABI;
        const smartContract = new ethers.Contract(ethaddress, abi, wallet);
        message.value = BlockchainMessage.Activating;
        try {
            const tx = await smartContract.payContractsAndMarkAsActive({
                value: ethers.utils.parseEther(totalCost.toString()),
                gasLimit: 8000000,
            });
            await exec(ContractTransactionsAPI.update(pendingTransactionId, { transactionHash: tx.hash }));
            message.value = BlockchainMessage.Transaction;
            await tx.wait();
            message.value = BlockchainMessage.Database;
        } catch (error) {
            await exec(
                ContractTransactionsAPI.update(pendingTransactionId, {
                    transactionHash: (error as any).transactionHash,
                }),
            );
            throw error;
        }
    };

    return {
        message,
        rejectBundle,
        createBundleContract,
        createContract,
        accept,
        reject,
        acceptOffer,
        rejectOffer,
        negotiate,
        counterOffer,
        contractActive,
        payContractsAndMarkAsActive,
    };
}
