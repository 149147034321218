import { FacetFilter, FacetFilterType, useFacetsFilters } from '@/app/composable';
import { computed } from '@vue/composition-api';

export function useContractFilters(get: any) {
    const { reducedFacetsFilters, defaultValue } = useFacetsFilters();

    const contractFilters = computed(
        (): Record<string, FacetFilter> => ({
            dueDate: {
                label: 'Due Date',
                type: FacetFilterType.Date,
                default: {
                    min: defaultValue(get, 'dueDateMin', false),
                    max: defaultValue(get, 'dueDateMax', false),
                },
            },
        }),
    );

    const reducedContractFilters: any = reducedFacetsFilters(contractFilters);

    return { contractFilters, reducedContractFilters };
}
