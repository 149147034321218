





















































































































































































import { AlertBanner } from '@/app/components';
import store from '@/app/store';
import { ContractsAPI } from '@/modules/sharing/api';
import { useContractStatus } from '@/modules/sharing/composable';
import { BundleStatus, ContractAction, ContractStatus, ContractTransactionStatus } from '@/modules/sharing/constants';
import { useAxios } from '@/app/composable';
import { ChevronLeftIcon } from '@vue-hero-icons/outline';
import { PropType, Ref, computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { ValidationObserver } from 'vee-validate';
import { Contract } from '../../types';
import AssetFields from './AssetFields.vue';
import ContractActions from './ContractActions.vue';
import ContractDetails from './ContractDetails.vue';
import ContractPreview from './ContractPreview.vue';
import ContractSteps from './ContractSteps.vue';
import ContractTerms from './ContractTerms.vue';
import LicenseDetails from './LicenseDetails.vue';
import PaymentDetails from './PaymentDetails.vue';
import PlatformTerms from './PlatformTerms.vue';
import RequestDetails from './RequestDetails.vue';
import { AlertType } from '@/app/constants';
import { useRoute } from '@/app/composable/router';

export default defineComponent({
    name: 'ContractItem',
    components: {
        ValidationObserver,
        ContractPreview,
        RequestDetails,
        AssetFields,
        ContractActions,
        ContractDetails,
        LicenseDetails,
        PaymentDetails,
        ContractTerms,
        PlatformTerms,
        ContractSteps,
        ChevronLeftIcon,
        AlertBanner,
    },
    props: {
        creatingContract: {
            type: Boolean,
            default: false,
        },
        bundle: {
            type: Object,
            default: null,
        },
        contractData: {
            type: Object as PropType<Contract>,
            required: true,
        },
        isNewContract: {
            type: Boolean,
        },
        multiple: {
            type: Boolean,
            required: true,
        },
        isExpanded: {
            type: Boolean,
            default: false,
        },
        isBundle: {
            type: Boolean,
            default: false,
        },
        contractTotalCosts: {
            type: Object,
            default: null,
        },
        configurationError: {
            type: Boolean,
            default: false,
        },
        isConsumer: {
            type: Boolean,
            default: false,
        },
        isLegalRepresentative: {
            type: Boolean,
            default: false,
        },
        pendingUpdatingContracts: {
            type: Array,
            default: () => [],
        },
        activePendingContracts: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, { emit, root }) {
        const { exec, loading } = useAxios(true);
        const { statusClass } = useContractStatus();

        const user = ref<any>(store.state.auth.user);
        const currentStep = ref<number>(0);
        const negotiating = ref<boolean>(false);
        const acceptedRequest = ref<boolean>(false);
        const currentContract = ref<any>(null);
        const contract: Ref<Contract> = ref<Contract>(props.contractData);
        const updatingContract = ref<any>(null);
        const contractAction = ref<ContractAction | null>(null);
        const totalCosts = ref<any>(props.contractTotalCosts);
        const hasErrors = ref<boolean>(false);
        const isPublicAsset = computed(() => contract.value.asset?.accessLevel === 'Public');

        contract.value.metadata.organisationId = user.value.organisationId;

        const isRequest = computed(() => {
            return (
                !contract.value.status ||
                (contractAction.value &&
                    [ContractAction.Extend, ContractAction.Reactivate].includes(contractAction.value)) ||
                (contract.value.status === ContractStatus.Request && !acceptedRequest.value) ||
                contract.value.status === ContractStatus.Declined ||
                (contract.value.status === ContractStatus.Withdrawn && R.isNil(contract.value.metadata?.pricing.cost))
            );
        });

        // Returns if the current user is the creator of the last negotiation
        const isNegotiationCreator = computed(() => {
            return lastNegotiation.value?.changes.metadata?.organisationId === user.value.organisationId;
        });

        const noPayment = computed(
            () => contract.value.metadata?.revise || contract.value.costDetails?.totalCost === 0,
        );

        const pageTitle = computed(() => {
            if (!props.multiple) {
                if (props.creatingContract) {
                    if (contractAction.value === ContractAction.Reactivate)
                        return 'Create a Contract Request to Reactivate Contract';
                    if (contractAction.value === ContractAction.Extend)
                        return 'Create a Contract Request to Extend Contract';
                    return props.isNewContract
                        ? 'Create a Contract Request for an Asset'
                        : 'Contract Request for an Asset';
                }
                if (contractAction.value === ContractAction.Revise) return 'Revise a Contract';
                const forText = contract.value.updatingContractId ? 'Updating Contract' : 'an Asset';
                switch (contract.value.status) {
                    case ContractStatus.Request:
                        if (!acceptedRequest.value) return `Review a Request for ${forText}`;
                        return `Prepare a Draft Contract for ${forText}`;
                    case ContractStatus.Declined:
                        return 'Preview of the Declined Request';
                    case ContractStatus.Rejected:
                        return 'Preview of the Rejected Contract';
                    case ContractStatus.Withdrawn:
                        return 'Preview of the Withdrawn Contract';
                    case ContractStatus.Expired:
                        return 'Preview of the Expired Contract';
                    case ContractStatus.Draft:
                        if (!negotiating.value) {
                            if (props.isConsumer) return `Review the Draft Contract for ${forText}`;
                            return `Preview of the Draft Contract for ${forText}`;
                        }
                        return `Negotiate the Draft Contract for ${forText}`;

                    case ContractStatus.Negotiate:
                        if (!negotiating.value) {
                            if (isNegotiationCreator.value) return `Preview Contract Negotiation for ${forText}`;
                            return `Review Contract Negotiation for ${forText}`;
                        }
                        return `Negotiate the Draft Contract for ${forText}`;

                    case ContractStatus.Signed:
                        if (!props.isConsumer || noPayment.value) return `Activate the Contract for ${forText}`;
                        return `Pending Activation of the Draft Contract for ${forText}`;

                    case ContractStatus.Active:
                        if (isPublicAsset.value) return 'Preview of an Open Asset';
                        return `Preview of the Contract for ${forText}`;
                    default:
                        return null;
                }
            }
            return null;
        });

        const showSteps = computed(
            () =>
                (props.creatingContract && !props.isBundle) ||
                ![
                    ContractStatus.Declined,
                    ContractStatus.Rejected,
                    ContractStatus.Active,
                    ContractStatus.Expired,
                ].includes(contract.value.status as ContractStatus) ||
                (contractAction.value && [ContractAction.Revise, ContractAction.Extend].includes(contractAction.value)),
        );

        const showPreviewSteps = computed(() => {
            return (
                props.multiple &&
                ((!props.creatingContract &&
                    ![
                        ContractStatus.Declined,
                        ContractStatus.Rejected,
                        ContractStatus.Active,
                        ContractStatus.Expired,
                        ContractStatus.Withdrawn,
                    ].includes(contract.value.status as ContractStatus)) ||
                    (contractAction.value &&
                        [ContractAction.Extend, ContractAction.Revise].includes(contractAction.value)))
            );
        });

        const onEditMode = computed(() => {
            if (contract.value.status === ContractStatus.Request && !props.isConsumer) return true;
            if (negotiating.value) return true;
            return false;
        });

        const actionRequired = computed(() => {
            if (props.isLegalRepresentative) {
                // Consumer or Provider must "Sign" or "Cancel" after "Revise" or must "Submit" or "Cancel" after "Extend" or "Reactivate"
                if (contractAction.value) return true;
                // Consumer or Provider must "Sign" or "Cancel" after "Negotiate"
                if (negotiating.value) return true;
                // Consumer or Provider must "Accept" or "Negotiate" or "Reject"
                if (contract.value.status === ContractStatus.Negotiate && !isNegotiationCreator.value) return true;
                if (props.isConsumer) {
                    // Consumer must "Accept" or "Negotiate" or "Reject"
                    if (contract.value.status === ContractStatus.Draft) return true;
                    // Consumer must "Pay to Activate Contract" or "Activate Contract"
                    if (
                        contract.value.status === ContractStatus.Signed &&
                        (contract.value.updatingContractId ||
                            (props.bundle && props.bundle.status !== BundleStatus.Draft))
                    )
                        return true;
                } else {
                    // Provider must "Offer a contract" or "Decline Request" and then "Sign" or "Cancel"
                    if (contract.value.status === ContractStatus.Request) return true;
                    // Provider must "Activate Contract"
                    if (
                        contract.value.status === ContractStatus.Signed &&
                        (contract.value.updatingContractId ||
                            (props.bundle && props.bundle.status !== BundleStatus.Draft)) &&
                        noPayment.value
                    )
                        return true;
                }
            }
            return false;
        });

        const canExtendOrRevise = computed(
            () =>
                props.isLegalRepresentative &&
                contract.value.status === ContractStatus.Active &&
                !isPublicAsset.value &&
                !contract.value.updatingContractId &&
                !props.pendingUpdatingContracts.includes(contract.value.id),
        );

        const completedTransactions = computed(() => {
            return contract.value.transactions
                ? contract.value.transactions.filter((t) => t.status === ContractTransactionStatus.Completed)
                : [];
        });

        const lastNegotiation = computed(() => {
            const sort = R.sortBy(R.prop('createdAt'));
            return completedTransactions.value.length
                ? sort(completedTransactions.value)[completedTransactions.value.length - 1]
                : null;
        });

        const previousNegotiation = computed(() => {
            const sort = R.sortBy(R.prop('createdAt'));
            return completedTransactions.value.length > 1
                ? sort(completedTransactions.value)[completedTransactions.value.length - 2]
                : null;
        });

        const offerContract = () => {
            currentContract.value = R.clone(contract.value);
            acceptedRequest.value = true;
            currentStep.value = 1;
        };

        const negotiate = (revise = false) => {
            contract.value.metadata.revise = contract.value.metadata.revise || revise;
            negotiating.value = true;
        };

        const requestsSent = () => {
            currentStep.value = 1;
            emit('requests-sent');
        };

        const updateCurrentStep = () => {
            if (contractAction.value === ContractAction.Revise) {
                currentStep.value = 2;
            } else if (props.creatingContract || contractAction.value === ContractAction.Extend) {
                currentStep.value = 0;
            } else {
                switch (contract.value.status) {
                    case ContractStatus.Request:
                        currentStep.value = props.isConsumer ? 1 : 0;
                        break;
                    case ContractStatus.Draft:
                        currentStep.value = 2;
                        break;
                    case ContractStatus.Negotiate:
                        currentStep.value = 2;
                        break;
                    case ContractStatus.Signed:
                        currentStep.value = 3;
                        break;
                    default:
                    // do nothing
                }
            }
        };

        const extend = () => {
            currentContract.value = R.clone(contract.value);
            contract.value.updatingContractId = contract.value.id;
            if (contract.value.metadata.revise) contract.value.metadata.revise = false;
            contract.value.metadata.pricing.cost = 0;
            contractAction.value = ContractAction.Extend;
            emit('set-contract-action', contractAction.value);
            updateCurrentStep();
        };

        if (useRoute().params.action === 'extend') extend();

        const revise = () => {
            negotiate(true);
            contract.value.updatingContractId = contract.value.id;
            contractAction.value = ContractAction.Revise;
            emit('set-contract-action', contractAction.value);
            updateCurrentStep();
        };

        if (useRoute().params.action === 'revise') revise();

        const reactivate = () => {
            currentContract.value = R.clone(contract.value);
            if (contract.value.metadata.revise) contract.value.metadata.revise = false;
            contractAction.value = ContractAction.Reactivate;
            emit('set-contract-action', contractAction.value);
        };

        const cancel = () => {
            if (currentContract.value) {
                contract.value = currentContract.value;
                currentContract.value = null;
            }

            // Cancel from Negotiate
            if (negotiating.value && !contractAction.value) {
                negotiating.value = false;
            }
            // Cancel from Offer a Contract
            else if (acceptedRequest.value) {
                acceptedRequest.value = false;
                currentStep.value -= 1;
            }
            // Cancel from Revise/Extend/Reactivate
            else if (contractAction.value) {
                negotiating.value = false;
                contractAction.value = null;
                emit('set-contract-action', contractAction.value);
                updateCurrentStep();
            }
            // Cancel from Request
            else {
                emit('cancel');
            }
        };

        const reloadBundle = () => {
            contractAction.value = null;
            emit('set-contract-action', contractAction.value);
            emit('reload-bundle');
        };

        const updateContract = (newContract: any) => {
            contract.value = newContract;
            totalCosts.value[newContract.id] = newContract.costDetails;
        };

        const sync = () => {
            exec(ContractsAPI.syncContract(contract.value.id, 'acquisition'))
                .then((res: any) => {
                    const newContract = res.data;
                    if (!R.isNil(newContract.pendingTransactionId))
                        (root as any).$toastr.w(
                            'Blockchain transaction is still pending. Please try again later.',
                            'Pending Transaction',
                        );
                    else {
                        (root as any).$toastr.s('Contract state synchronised!', 'Success');
                        if (contract.value.bundleId) emit('reload-bundle');
                        else if (contract.value.updatingContractId) emit('back');
                        else {
                            updateContract(newContract);
                            updateCurrentStep();
                        }
                    }
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to synchronise contract state!', 'Error');
                });
        };

        if (contract.value.updatingContractId) {
            exec(ContractsAPI.get(contract.value.updatingContractId)).then((res: any) => {
                updatingContract.value = res.data;
            });
        }

        updateCurrentStep();

        return {
            ContractAction,
            loading,
            updatingContract,
            currentStep,
            contract,
            isRequest,
            isPublicAsset,
            isNegotiationCreator,
            showSteps,
            showPreviewSteps,
            onEditMode,
            pageTitle,
            actionRequired,
            canExtendOrRevise,
            ContractStatus,
            negotiating,
            acceptedRequest,
            contractAction,
            noPayment,
            totalCosts,
            previousNegotiation,
            offerContract,
            negotiate,
            cancel,
            statusClass,
            updateCurrentStep,
            requestsSent,
            extend,
            revise,
            reactivate,
            reloadBundle,
            updateContract,
            sync,
            hasErrors,
            AlertType,
        };
    },
});
