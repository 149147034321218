











































































import { useFilters } from '@/app/composable';
import { useAsset } from '@/modules/asset/composable/asset';
import { Asset } from '@/modules/asset/types';
import { ShoppingCartIcon } from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent } from '@vue/composition-api';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { useCart } from '../../composable';
import {
    ExpectedUsage,
    ExpectedUsageShortText,
    ReimbursementMethod,
    ReimbursementMethodShortText,
} from '../../constants';
import ExpectedUsageIcon from '../ExpectedUsageIcon.vue';
import ReimbursementMethodIcon from '../ReimbursementMethodIcon.vue';

dayjs.extend(minMax);

export default defineComponent({
    name: 'AssetCard',
    components: {
        ShoppingCartIcon,
        ReimbursementMethodIcon,
        ExpectedUsageIcon,
    },
    props: {
        asset: {
            type: Object as PropType<Asset>,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const updatedDate = computed(() =>
            props.asset.modifiedAt
                ? dayjs.max([dayjs(props.asset.modifiedAt), dayjs(props.asset.updatedAt)]).toString()
                : props.asset.updatedAt,
        );

        const { formatDate } = useFilters();

        const { cartAssetIds } = useCart();
        const { assetTypeClass, assetTypeName } = useAsset();

        return {
            dayjs,
            assetTypeClass,
            assetTypeName,
            formatDate,
            updatedDate,
            ReimbursementMethodShortText,
            ExpectedUsageShortText,
            cartAssetIds,
            ReimbursementMethod,
            ExpectedUsage,
        };
    },
});
