








































































































































































import { InputErrorIcon } from '@/app/components';
import { useFilters } from '@/app/composable';
import { useEthers } from '@/modules/sharing/composable';
import { ContractStatus, Currency, currencySymbol } from '@/modules/sharing/constants';
import { SwitchHorizontalIcon } from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { ValidationProvider, extend } from 'vee-validate';
import { regex } from 'vee-validate/dist/rules';
import { Contract, ContractTransaction } from '../../types';

extend('regex', regex);

export default defineComponent({
    name: 'EthersConverter',
    components: {
        ValidationProvider,
        InputErrorIcon,
        SwitchHorizontalIcon,
    },
    props: {
        contract: {
            type: Object as PropType<Contract>,
            required: true,
        },
        onEditMode: {
            type: Boolean,
            default: false,
        },
        negotiating: {
            type: Boolean,
            default: false,
        },
        previousNegotiation: {
            type: Object as PropType<ContractTransaction>,
            default: () => ({}),
        },
        title: {
            type: String,
            default: 'Price',
        },
        reviseContract: {
            type: Boolean,
            default: false,
        },
        initialCost: {
            type: Number,
            default: 0,
        },
    },
    setup(props, { emit }) {
        const costInOtherCurrency = ref<number>(0);
        const otherCurrency = ref<Currency>(Currency.Euro);

        const { transformToOtherCurrency, transformToEthers, exchangeRates } = useEthers();
        const { formatDecimals, countDecimals } = useFilters();

        const initialCostInOtherCurrency = computed(() =>
            props.initialCost ? transformToOtherCurrency(props.initialCost, otherCurrency.value) : null,
        );

        const callTransformToOtherCurrency = () => {
            costInOtherCurrency.value = formatDecimals(
                Number(
                    transformToOtherCurrency(
                        (props.contract.status === ContractStatus.Active || props.contract.updatingContractId) &&
                            props.contract.costDetails
                            ? props.contract.costDetails.totalCost - props.contract.costDetails.totalDerivationCost
                            : props.contract.metadata.pricing.cost,
                        otherCurrency.value,
                    ),
                ),
                2,
            );
        };

        const callTransformToEthers = () => {
            // eslint-disable-next-line no-param-reassign
            props.contract.metadata.pricing.cost = formatDecimals(
                Number(transformToEthers(costInOtherCurrency.value, otherCurrency.value)),
                4,
            );
        };

        const currencyChanged = () => {
            callTransformToOtherCurrency();
            emit('currency-changed', otherCurrency.value, exchangeRates.value);
        };

        watch(
            () => exchangeRates.value,
            (rates: any) => {
                if (rates) {
                    currencyChanged();
                }
            },
        );

        return {
            ContractStatus,
            currencySymbol,
            Currency,
            costInOtherCurrency,
            otherCurrency,
            callTransformToOtherCurrency,
            currencyChanged,
            callTransformToEthers,
            exchangeRates,
            formatDecimals,
            countDecimals,
            initialCostInOtherCurrency,
            R,
        };
    },
});
