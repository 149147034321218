









































































import { useFilters } from '@/app/composable';
import { useAsset } from '@/modules/asset/composable/asset';
import { ShoppingCartIcon } from '@vue-hero-icons/outline';
import { defineComponent } from '@vue/composition-api';
import { useCart } from '../../composable';

export default defineComponent({
    name: 'RelatedAssetCard',
    components: { ShoppingCartIcon },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { cartAssetIds } = useCart();
        const { formatDate } = useFilters();
        const { assetTypeClass, assetTypeName } = useAsset();

        return { formatDate, cartAssetIds, assetTypeClass, assetTypeName };
    },
});
