import { useAxios } from '@/app/composable';
import { defineModule } from 'direct-vuex';
import { clone, includes } from 'ramda';
import { moduleActionContext } from '@/app/store';
import { UserRoles } from '@/app/constants/user-roles';

export interface AuthState {
    user: any;
    keycloakToken: any;
    keycloakRefreshToken: any;
}

const authModule = defineModule({
    namespaced: true,
    state: (): AuthState => {
        return {
            user: null,
            keycloakToken: null,
            keycloakRefreshToken: null,
        };
    },
    mutations: {
        CLEAR_USER(state: AuthState) {
            state.user = null;
        },
        SET_USER(state: AuthState, newUser: any) {
            state.user = clone(newUser);
        },

        CLEAR_KEYCLOAK_TOKEN(state: AuthState) {
            state.keycloakToken = null;
        },
        SET_KEYCLOAK_TOKEN(state: AuthState, token: any) {
            state.keycloakToken = token;
        },
        SET_KEYCLOAK_REFRESH_TOKEN(state: AuthState, refreshToken: any) {
            state.keycloakRefreshToken = refreshToken;
        },
    },
    getters: {
        isAuthenticated: (state: AuthState) => !!state.user,
        hasRole: (state: AuthState) => (role: UserRoles) => includes(role, state.user.roles),
        getKeycloakToken: (state: AuthState) => state.keycloakToken,
    },
});

export default authModule;
export const authModuleActionContext = (context: any) => moduleActionContext(context, authModule);
