export enum WalletAction {
    Create = 'Create',
    Import = 'Import',
    Unlock = 'Unlock',
}

export const WalletActionMap: Record<string, { title: string; description: string; loadingMessage: string }> = {
    [WalletAction.Create]: {
        title: 'Create a new Ethereum wallet',
        description:
            'Enter a password to encrypt your wallet. This password will be used for signing all contracts within the SYNERGIES platform.',
        loadingMessage: 'Generating and Encrypting Wallet...',
    },
    [WalletAction.Import]: {
        title: 'Import an existing Ethereum wallet',
        description:
            'You can import an existing wallet by entering the private key. In addition, a password is required to be used for signing all contracts within the SYNERGIES platform.',
        loadingMessage: 'Importing and Encrypting Wallet...',
    },
    [WalletAction.Unlock]: {
        title: 'Unlock Ethereum Wallet',
        description: 'Access to your Ethereum wallet is required before you continue. Please enter your password.',
        loadingMessage: 'Unlocking Wallet...',
    },
};
