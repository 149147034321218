import { AxiosRequestConfig } from 'axios';

const endpoint = '/api/contracts';

export default {
    get: (id: string) => ({ method: 'GET', url: `${endpoint}/${id}` } as AxiosRequestConfig),
    getBundle: (id: string) => ({ method: 'GET', url: `${endpoint}/bundle/${id}` } as AxiosRequestConfig),
    getActivePending: () => ({ method: 'GET', url: `${endpoint}/active-pending` } as AxiosRequestConfig),
    getPendingUpdatingContracts: (ids: string) =>
        ({ method: 'GET', url: `${endpoint}/pending-updating-contracts/${ids}` } as AxiosRequestConfig),
    getByAssetIds: (ids: string) => ({ method: 'GET', url: `${endpoint}/assets/${ids}` } as AxiosRequestConfig),
    create: (data: any) => ({ method: 'POST', url: `${endpoint}`, data } as AxiosRequestConfig),
    declineRequest: (id: string, pending = false) =>
        ({ method: 'PATCH', url: `${endpoint}/${id}/decline-request?pending=${pending}` } as AxiosRequestConfig),
    signContract: (id: string, data: any, pending = false) =>
        ({ method: 'PUT', url: `${endpoint}/${id}/sign?pending=${pending}`, data } as AxiosRequestConfig),
    acceptContract: (id: string, pending = false) =>
        ({ method: 'PATCH', url: `${endpoint}/${id}/accept?pending=${pending}` } as AxiosRequestConfig),
    acceptCounterOffer: (id: string, pending = false) =>
        ({ method: 'PATCH', url: `${endpoint}/${id}/accept-counter-offer?pending=${pending}` } as AxiosRequestConfig),
    rejectContract: (id: string, pending = false) =>
        ({ method: 'PATCH', url: `${endpoint}/${id}/reject?pending=${pending}` } as AxiosRequestConfig),
    rejectCounterOffer: (id: string, pending = false) =>
        ({ method: 'PATCH', url: `${endpoint}/${id}/reject-counter-offer?pending=${pending}` } as AxiosRequestConfig),
    negotiate: (id: string, data: any, pending = false) =>
        ({ method: 'PUT', url: `${endpoint}/${id}/negotiate?pending=${pending}`, data } as AxiosRequestConfig),
    counterOffer: (id: string, data: any, pending = false) =>
        ({ method: 'PUT', url: `${endpoint}/${id}/counter-offer?pending=${pending}`, data } as AxiosRequestConfig),
    makeActive: (id: string, pending = false) =>
        ({ method: 'PATCH', url: `${endpoint}/${id}/activate?pending=${pending}` } as AxiosRequestConfig),
    makeBundleActive: (id: string, pending = false) =>
        ({ method: 'PATCH', url: `${endpoint}/bundle/${id}/activate?pending=${pending}` } as AxiosRequestConfig),
    getPDF: (id: string) => ({ method: 'GET', url: `${endpoint}/${id}/pdf` } as AxiosRequestConfig),
    extend: (id: string, data: any) =>
        ({ method: 'POST', url: `${endpoint}/${id}/extend`, data } as AxiosRequestConfig),
    revise: (id: string, data: any) =>
        ({ method: 'POST', url: `${endpoint}/${id}/revise`, data } as AxiosRequestConfig),
    reactivate: (id: string, data: any) =>
        ({ method: 'PUT', url: `${endpoint}/${id}/reactivate`, data } as AxiosRequestConfig),
    syncContract: (id: string, type = 'acquisition') =>
        ({ method: 'POST', url: `${endpoint}/${id}/sync?type=${type}` } as AxiosRequestConfig),
};
