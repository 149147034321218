import { FacetFilter, FacetFilterType, useFacetsFilters } from '@/app/composable';
import { computed } from '@vue/composition-api';
import { ExpectedUsageText, ReimbursementMethodText } from '../constants';

export function useContractFacets(get: any) {
    const { valueLabel, reducedFacetsFilters, defaultValue } = useFacetsFilters();

    const contractFacets = computed(
        (): Record<string, FacetFilter> => ({
            status: {
                label: 'Status',
                type: FacetFilterType.Checkbox,
                default: defaultValue(get, 'status'),
                valueLabel,
            },
            type: {
                label: 'Contract Type',
                type: FacetFilterType.Checkbox,
                default: defaultValue(get, 'type'),
                valueLabel,
            },
            reimbursementMethod: {
                label: 'Reimbursement Method',
                type: FacetFilterType.Checkbox,
                default: defaultValue(get, 'reimbursementMethod'),
                valueLabel: (value: string) => ReimbursementMethodText[value],
            },
            expectedUsage: {
                label: 'Expected Usage',
                type: FacetFilterType.Checkbox,
                default: defaultValue(get, 'expectedUsage'),
                valueLabel: (value: string) => ExpectedUsageText[value],
            },
            assetTimeliness: {
                label: 'Asset Timeliness',
                type: FacetFilterType.Checkbox,
                default: defaultValue(get, 'assetTimeliness'),
                valueLabel,
            },
        }),
    );

    const reducedContractFacets: any = reducedFacetsFilters(contractFacets);

    return { contractFacets, reducedContractFacets };
}
