import { AxiosRequestConfig } from 'axios';
import { CreateOrUpdateContractTemplate } from '../types';

const endpoint = '/api/contract-templates';

export default {
    create: (data: CreateOrUpdateContractTemplate) =>
        ({ method: 'POST', url: `${endpoint}`, data } as AxiosRequestConfig),
    update: (id: number, data: CreateOrUpdateContractTemplate) =>
        ({ method: 'PUT', url: `${endpoint}/${id}`, data } as AxiosRequestConfig),
    retrieveForOrganisation: (id: number) => ({ method: 'GET', url: `${endpoint}/${id}` } as AxiosRequestConfig),
    delete: (id: number) => ({ method: 'DELETE', url: `${endpoint}/${id}` } as AxiosRequestConfig),
};
