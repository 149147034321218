import { AuthzPoliciesPayload } from '@/modules/access-policy/interfaces';
import { AxiosRequestConfig } from 'axios';
import { DataCheckinJob } from '../types';

const endpoint = '/api/data-checkin';

export default {
    all: () => ({ method: 'GET', url: `${endpoint}/jobs` } as AxiosRequestConfig),
    get: (id: number) => ({ method: 'GET', url: `${endpoint}/jobs/${id}` } as AxiosRequestConfig),
    isLock: (id: number) => ({ method: 'GET', url: `${endpoint}/jobs/${id}/is-lock` } as AxiosRequestConfig),
    getJobSteps: (id: number) => ({ method: 'GET', url: `${endpoint}/jobs/${id}/steps` } as AxiosRequestConfig),
    create: (data: DataCheckinJob) =>
        ({ method: 'POST', url: `/api/v2/data-checkin/jobs`, data } as AxiosRequestConfig),
    update: (id: number, data: DataCheckinJob) =>
        ({ method: 'PUT', url: `${endpoint}/jobs/${id}`, data } as AxiosRequestConfig),
    updateAccessPolicies: (id: number, data: AuthzPoliciesPayload) =>
        ({ method: 'PUT', url: `${endpoint}/jobs/${id}/update-policies`, data } as AxiosRequestConfig),
    lock: (id: number) => ({ method: 'PUT', url: `${endpoint}/jobs/${id}/lock` } as AxiosRequestConfig),
    unlock: (id: number) => ({ method: 'PUT', url: `${endpoint}/jobs/${id}/unlock` } as AxiosRequestConfig),
    delete: (id: number) => ({ method: 'DELETE', url: `${endpoint}/jobs/${id}` } as AxiosRequestConfig),
    getStepTypes: () => ({ method: 'GET', url: `${endpoint}/step-types` } as AxiosRequestConfig),
    getStepType: (id: number) => ({ method: 'GET', url: `${endpoint}/step-types/${id}` } as AxiosRequestConfig),
    getStep: (jobId: number, stepName: string) =>
        ({ method: 'GET', url: `${endpoint}/jobs/${jobId}/steps/${stepName}` } as AxiosRequestConfig),
    getStepById: (stepId: number) => ({ method: 'GET', url: `${endpoint}/steps/${stepId}` } as AxiosRequestConfig),
    getStepIdByNameAndAssetId: (id: number, stepName: string) =>
        ({ method: 'GET', url: `${endpoint}/jobs/assets/${id}/steps/${stepName}` } as AxiosRequestConfig),
    getStepProcessedFiles: (id: number) =>
        ({ method: 'GET', url: `${endpoint}/steps/${id}/processed-files` } as AxiosRequestConfig),
    updateStep: (stepId: number, data: any) =>
        ({ method: 'PUT', url: `${endpoint}/steps/${stepId}`, data } as AxiosRequestConfig),
    finalize: (stepId: number) =>
        ({ method: 'PATCH', url: `${endpoint}/steps/${stepId}/finalize` } as AxiosRequestConfig),
    getJobByAssetId: (id: number) => ({ method: 'GET', url: `${endpoint}/jobs/assets/${id}` } as AxiosRequestConfig),
    retrieveNextStep: (id: number) => ({ method: 'GET', url: `${endpoint}/steps/${id}/next` } as AxiosRequestConfig),
    restartStep: (id: number) => ({ method: 'PUT', url: `${endpoint}/steps/${id}/restart` } as AxiosRequestConfig),
    retryExecution: (id: number) => ({ method: 'POST', url: `${endpoint}/jobs/${id}/retry` } as AxiosRequestConfig),
    executeSampleRun: (stepId: number) =>
        ({ method: 'POST', url: `${endpoint}/steps/${stepId}/sample-run` } as AxiosRequestConfig),
    clone: (id: number, data: DataCheckinJob) =>
        ({ method: 'POST', url: `${endpoint}/jobs/${id}/clone`, data } as AxiosRequestConfig),
    resetS3SecretKey: (stepId: number) =>
        ({ method: 'POST', url: `${endpoint}/steps/${stepId}/reset-secret-key` } as AxiosRequestConfig),
    reset: (id: number, kafkaMessageDeletion: boolean) =>
        ({
            method: 'DELETE',
            url: `${endpoint}/jobs/${id}/reset`,
            params: { kafkaMessageDeletion },
        } as AxiosRequestConfig),
};
