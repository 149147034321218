/* eslint-disable no-underscore-dangle */
import { AssetSchema } from '@/modules/asset/types';
import { ScheduleAPI } from '@/modules/workflow-designer/api';
import { Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { AssetStructure } from '../asset-config.types';
import { DataCheckinJob } from '../types';
import { FieldConfiguration, MappingConfig } from '../views/mapping/mapping.types';

const nameCapitalized = (name: string) => name.charAt(0).toUpperCase() + name.slice(1);

const dataTypes = ['integer', 'double', 'string', 'boolean', 'datetime', 'time', 'date', 'base64binary'];

export function useJobConfiguration(job: Ref<DataCheckinJob | null>) {
    const getPeriodicity = async (config: any) => {
        // TODO: check this again if should use distribution.accrualPeriodicity and not .periodicity here
        if (config.source === 'kafka') return 'Continous';
        if (config.source === 'api') {
            if (config.retrieval.type === 'periodic') {
                if (job.value?.workflowId) {
                    const { data: schedules } = await ScheduleAPI.getSchedules(job.value.workflowId);
                    const periods = {
                        hourly: 1,
                        daily: 2,
                        weekly: 3,
                        monthly: 4,
                    };

                    let period = 4;
                    schedules.forEach((schedule: any) => {
                        if (periods[schedule.frequency] < period) {
                            period = periods[schedule.frequency];
                        }
                    });

                    return nameCapitalized(Object.keys(periods).find((key) => periods[key] === period) as string);
                }
            }
            if (config.retrieval.type === 'polling') return 'Continous';
        }

        return 'Provider-dependent';
    };

    const getUnmappedStructure = async (harvester: any) => {
        return {
            source: harvester.source,
            type: harvester.fileType === 'other' ? 'other' : 'json',
            periodicity: await getPeriodicity(harvester), // TODO: check this again
            schema: null,
            domain: null,
            primaryConcept: null,
            otherConcepts: [],
        };
    };

    const getStructure = async (
        harvester: any,
        mapping: any,
        conceptUIds: any,
        droppedFields: any[],
    ): Promise<AssetStructure> => {
        const primaryConcept = mapping.concept;

        const structure: AssetStructure = {
            dataType: harvester.dataType,
            source: harvester.source,
            type: harvester.fileType === 'other' ? 'other' : 'json',
            domain: {
                uid: conceptUIds[mapping.domain.id].uid,
                name: mapping.domain.name,
                majorVersion: conceptUIds[mapping.domain.id].majorVersion,
            },
            primaryConcept: { uid: conceptUIds[primaryConcept.id].uid, name: primaryConcept.name },
            otherConcepts: [],
            temporalFields: [],
            spatialFields: [],
            periodicity: await getPeriodicity(harvester), // TODO: check this again
            schema: [
                {
                    uid: conceptUIds[primaryConcept.id].uid,
                    field: primaryConcept.name,
                    type: primaryConcept.name,
                    path: [],
                    parentUids: [],
                    index: false,
                },
            ],
        };

        const customizedConceptsKeys = mapping.customizedConcepts ? Object.keys(mapping.customizedConcepts) : [];
        const orderedMappedFields = R.sort(R.ascend<any>(R.path(['transformation', 'order'])), mapping.fields);

        orderedMappedFields.forEach((fieldConf: FieldConfiguration) => {
            const field = R.clone(fieldConf);
            if (!field.target.id || !field.target.title) return;

            const isDroppedField = droppedFields.find(
                (droppedField: any) =>
                    droppedField.id === field.target.id &&
                    droppedField.originalName === field.source.title &&
                    JSON.stringify(droppedField.path) === JSON.stringify(field.target.path),
            );
            if (isDroppedField) return;

            const fieldUid = conceptUIds[field.target.id].uid;
            const parentUids = [];
            field.target.path = field.target.path.map((pathField: string) => pathField.replace('[]', ''));

            const schemaField = structure.schema.find(
                (sf: AssetSchema) =>
                    sf.uid === fieldUid && JSON.stringify(sf.path) === JSON.stringify(field.target.path),
            );

            if (schemaField) {
                if (field.annotation) schemaField.annotation?.push(field.annotation);
            } else {
                for (let i = 0; i < field.target.path.length; i++) {
                    const uid = conceptUIds[field.target.parentIds[i]].uid;
                    const path = field.target.path.slice(0, i);
                    const category = field.target.categories[i];
                    const name = field.target.path[i];

                    if (
                        !structure.schema.find(
                            (sf: AssetSchema) =>
                                sf.uid === uid && sf.field === name && JSON.stringify(sf.path) === JSON.stringify(path),
                        )
                    ) {
                        if (!structure.otherConcepts.find((conc) => conc.uid === uid))
                            structure.otherConcepts.push({ uid, name: category });

                        let customConceptPrefix = null;
                        let customConceptDescription = null;
                        if (customizedConceptsKeys.length > 0) {
                            const parentId = field.target.parentIds[i - 1].toString();
                            if (customizedConceptsKeys.includes(parentId)) {
                                const prefixes = mapping.customizedConcepts[parentId];
                                const customConcept = prefixes.find(
                                    (prefixItem: { prefix: string; description: string }) =>
                                        `${prefixItem.prefix}${category}` === name,
                                );
                                if (customConcept) {
                                    customConceptPrefix = customConcept.prefix;
                                    customConceptDescription = customConcept.description;
                                }
                            }
                        }

                        structure.schema.push({
                            uid,
                            field: name,
                            type: category,
                            path,
                            parentUids: R.clone(parentUids),
                            index: false,
                            customConceptPrefix,
                            customConceptDescription,
                        });
                    }

                    parentUids.push(uid);
                }

                if (field.metadata?.indexES) {
                    const prefix =
                        field.target.path && field.target.path.length > 0 ? `${field.target.path.join('__')}__` : '';
                    const fieldObj = { uid: fieldUid, name: prefix + field.target.title, type: field.target.type };
                    if (
                        field.metadata?.spatial &&
                        !structure.spatialFields.find(
                            (spatialField) => spatialField.uid === fieldUid && spatialField.name === fieldObj.name,
                        )
                    )
                        structure.spatialFields.push(fieldObj);
                    if (
                        field.metadata?.temporal &&
                        !structure.temporalFields.find(
                            (temporalField) => temporalField.uid === fieldUid && temporalField.name === fieldObj.name,
                        )
                    )
                        structure.temporalFields.push(fieldObj);
                }

                structure.schema.push({
                    uid: fieldUid,
                    field: field.target.title,
                    type: field.target.type,
                    path: field.target.path,
                    parentUids: R.clone(parentUids),
                    index: !!field.metadata?.indexMongo,
                    annotation: field.annotation ? [field.annotation] : [],
                });
            }
        });

        return structure;
    };

    const getLoaderSchema = (structure: AssetStructure, mappingConfig: MappingConfig): any => {
        if (!structure.primaryConcept || !mappingConfig) return {};
        const mappedFields = mappingConfig.fields.filter((field) => !!field.target.id);

        const findMappedField = (path: string[], name: string) =>
            mappedFields.find(
                (field) =>
                    R.equals(
                        field.target.path.map((pathField: string) => pathField.replace('[]', '')),
                        path,
                    ) && field.target.title === name,
            );

        const extractChildren = (schema: AssetSchema[], parent: string): Record<string, unknown>[] =>
            schema.reduce((result: Record<string, unknown>[], field: AssetSchema) => {
                if (field.path && field.path.length > 0 && field.path[field.path.length - 1] === parent && field.type) {
                    if (!dataTypes.includes(field.type))
                        result.push({
                            key: field.field,
                            type: 'object',
                            children: extractChildren(schema, field.field),
                        });
                    else {
                        const mappedField = findMappedField(field.path, field.field);
                        result.push({
                            uid: field.uid,
                            key: field.field,
                            type: field.type,
                            indexES: !!mappedField?.metadata?.indexES,
                            indexMongo: !!mappedField?.metadata?.indexMongo,
                        });
                    }
                }

                return result;
            }, [] as Record<string, unknown>[]);
        return {
            [structure.primaryConcept.name]: {
                children: extractChildren(structure.schema, structure.primaryConcept.name),
            },
        };
    };

    return { getPeriodicity, getStructure, getLoaderSchema, getUnmappedStructure };
}
