import { AuthGuard } from '@/modules/auth/router/auth.guard';
import multiguard from 'vue-router-multiguard';
import { LockGuard } from './lock.guard';

export default [
    {
        name: 'data-checkin-jobs',
        path: '/dcp',
        component: () =>
            import(/* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/DataCheckinJobs.vue'),
        meta: { title: 'Data Check-in Pipelines' },
        beforeEnter: AuthGuard,
    },
    {
        name: 'data-checkin-jobs:create',
        path: '/dcp/create',
        component: () => import(/* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/ConfigureJob.vue'),
        meta: { title: 'Create Data Check-in Pipeline' },
        props: true,
        beforeEnter: AuthGuard,
    },
    {
        name: 'dcj:monitoring',
        path: '/monitoring',
        component: () => import(/* webpackChunkName: "apollo" */ '@/modules/data-checkin/views/JobMonitoring.vue'),
        meta: { title: 'Data Check-in Pipelines Monitoring' },
        props: true,
        beforeEnter: AuthGuard,
    },
    {
        name: 'data-checkin-jobs:edit',
        path: '/dcp/:id',
        component: () => import(/* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/ConfigureJob.vue'),
        meta: { title: 'Update Data Check-in Pipeline' },
        props: true,
        beforeEnter: multiguard([AuthGuard, LockGuard]),
    },
    {
        name: 'data-checkin-jobs:clone',
        path: '/dcp/:id/clone',
        component: () => import(/* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/ConfigureJob.vue'),
        meta: { title: 'Clone Data Check-in Pipeline' },
        props: true,
        beforeEnter: multiguard([AuthGuard, LockGuard]),
    },
    {
        name: 'harvester',
        path: '/dcp/:id/harvester',
        component: () =>
            import(/* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/harvester/Harvester.vue'),
        meta: { title: 'Configure Harvester' },
        props: true,
        beforeEnter: multiguard([AuthGuard, LockGuard]),
    },
    {
        name: 'mapping',
        path: '/dcp/:id/mapping/:step?',
        component: () =>
            import(/* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/mapping/Mapping.vue'),
        meta: { title: 'Configure Mapping' },
        props: true,
        beforeEnter: multiguard([AuthGuard, LockGuard]),
    },

    {
        name: 'cleaning',
        path: '/dcp/:id/cleaning',
        component: () =>
            import(/* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/cleaning/Cleaning.vue'),
        meta: { title: 'Configure Cleaning' },
        props: true,
        beforeEnter: multiguard([AuthGuard, LockGuard]),
    },
    {
        name: 'anonymiser',
        path: '/dcp/:id/anonymiser',
        component: () =>
            import(
                /* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/anonymisation/Anonymisation.vue'
            ),
        meta: { title: 'Configure Anonymisation' },
        props: true,
        beforeEnter: multiguard([AuthGuard, LockGuard]),
    },
    {
        name: 'encryption',
        path: '/dcp/:id/encryption',
        component: () =>
            import(/* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/encryption/Encryption.vue'),
        meta: { title: 'Configure Encryption' },
        props: true,
        beforeEnter: multiguard([AuthGuard, LockGuard]),
    },
    {
        name: 'loader',
        path: '/dcp/:id/loader',
        component: () =>
            import(/* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/loader/Loader.vue'),
        meta: { title: 'Configure Loader' },
        props: true,
        beforeEnter: multiguard([AuthGuard, LockGuard]),
    },
    {
        name: 'dcj:history',
        path: '/dcp/:id/history',
        component: () =>
            import(
                /* webpackChunkName: "data-checkin" */ '@/modules/data-checkin/views/DataCheckinJobsExecutionHistory.vue'
            ),
        meta: { title: 'Execution History' },
        beforeEnter: AuthGuard,
        props: true,
    },
];
