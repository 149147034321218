











































































































































































































































































import { Scrollbar } from '@/app/components';
import Treeselect from '@riophae/vue-treeselect';
import { ChevronDownIcon, ChevronRightIcon, InformationCircleIcon } from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import { FacetFilter, FacetFilterType, useFacetsFilters } from '../composable';

export default defineComponent({
    name: 'FacetsFilters',
    props: {
        facets: {
            type: Object as PropType<Record<string, { value: string; count: number; selected: boolean }[]>>,
            default: () => {
                return {};
            },
        },
        filters: {
            type: Object,
            default: () => {
                return {};
            },
        },
        facetsInfo: {
            type: Object,
            default: () => {
                return {};
            },
        },
        filtersInfo: {
            type: Object,
            default: () => {
                return {};
            },
        },
        textNumberFilters: {
            type: Object,
            default: () => {
                return {};
            },
        },
        loading: { type: Boolean, default: false },
        noOfAlwaysDisplayedFilters: { type: Number, default: 5 },
        options: {
            type: Object,
            default: null,
        },
    },
    components: { Treeselect, Scrollbar, ChevronRightIcon, ChevronDownIcon, InformationCircleIcon },
    setup(props) {
        const { userOptions, getValueLabel, facetTotalCount } = useFacetsFilters();

        const facetsArray = computed(() =>
            Object.keys(props.facets).reduce((acc: any[], facetKey: string) => {
                if (Object.keys(props.facetsInfo).includes(facetKey))
                    acc.push({
                        ...props.facetsInfo[facetKey],
                        key: facetKey,
                        options: props.facets[facetKey],
                        isFacet: true,
                    });
                return acc;
            }, []),
        );

        const filtersArray = computed(() =>
            Object.keys(props.filters).reduce((acc: any[], filterKey: string) => {
                if (Object.keys(props.filtersInfo).includes(filterKey))
                    acc.push({
                        ...props.filtersInfo[filterKey],
                        key: filterKey,
                        values: props.filters[filterKey],
                        isFacet: false,
                    });
                return acc;
            }, []),
        );

        const facetsFilters = computed(() => facetsArray.value.concat(filtersArray.value));

        const showClearAll = computed(() => {
            const activeFacets = facetsArray.value.some(
                (facet: any) => facet.options && facet.options.some((value: any) => value.selected),
            );
            const activeFilters = filtersArray.value.some(
                (filter: any) => filter.values.length > 0 || filter.values.min || filter.values.max || filter.values.id,
            );
            return activeFacets || activeFilters;
        });

        const closed = ref<string[]>([]);
        const showMore = ref<string[]>([]);

        const open = (key: string) => {
            if (closed.value.includes(key)) {
                closed.value.splice(closed.value.indexOf(key), 1);
            } else {
                closed.value.push(key);
            }

            if (showMore.value.includes(key)) {
                showMore.value.splice(showMore.value.indexOf(key), 1);
            }
        };

        const show = (key: string) => {
            if (showMore.value.includes(key)) {
                showMore.value.splice(showMore.value.indexOf(key), 1);
            } else {
                showMore.value.push(key);
            }
        };

        const getOptions = (facetFilter: FacetFilter) => {
            if (facetFilter.label === 'User') return userOptions.value;
            else if (props.options && props.options[facetFilter.label]) return props.options[facetFilter.label];
            else return facetFilter.options;
        };

        return {
            userOptions,
            facetsFilters,
            showClearAll,
            closed,
            showMore,
            getValueLabel,
            facetTotalCount,
            open,
            show,
            FacetFilterType,
            getOptions,
        };
    },
});
