













































import { FormBlock } from '@/app/components';
import { useAxios } from '@/app/composable';
import Schedule from '@/modules/apollo/components/Schedule.vue';
import { JobsAPI } from '@/modules/data-checkin/api';
import { HarvesterSourceType, ProcessingOptions } from '@/modules/data-checkin/constants';
import { ScheduleAPI } from '@/modules/workflow-designer/api';
import { ScheduleFrequency } from '@/modules/workflow-designer/constants';
import { ScheduleType } from '@/modules/workflow-designer/types';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';

export default defineComponent({
    name: 'Schedules',
    components: {
        FormBlock,
        Schedule,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        workflowId: {
            type: String,
            required: true,
        },
        stepConfig: {
            type: Object,
            required: true,
        },
        stepId: {
            type: Number,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        frequencies: {
            type: Array,
            default: () => ['hourly', 'daily', 'weekly', 'monthly'],
        },
        startDate: {
            type: [Date, Number],
            required: false,
        },
        defaultStartDate: {
            type: Boolean,
            default: false,
        },
        schedulesPerFrequency: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        requiredSchedulesMessage: {
            type: String,
            default: `data check-in pipeline to be executed`,
        },
        frequency: {
            type: String,
            default: null,
        },
    },
    setup(props, { root, emit }) {
        const editingSchedule = ref<boolean>(false);
        const closeScheduleEditor = ref<boolean>(false);
        const schedulesError = ref<string | null>(null);
        const loadingSchedules = ref<boolean>(false);
        const schedules = ref<any>([]);
        const schedulerError = ref<boolean>(false);
        const { exec } = useAxios(true);
        const retrievalType = computed(() => props.stepConfig.retrieval?.type);

        const oldFrequency = computed(() => {
            if (
                schedules.value.find(
                    (schedule: { frequency: string }) => schedule.frequency === ScheduleFrequency.Hourly,
                )
            )
                return ScheduleFrequency.Hourly;

            if (
                schedules.value.find(
                    (schedule: { frequency: string }) => schedule.frequency === ScheduleFrequency.Daily,
                )
            )
                return ScheduleFrequency.Daily;

            return props.frequency;
        });

        const loadingSchedule = ref<boolean>(false);

        const getSchedules = () => {
            loadingSchedules.value = true;
            emit('loading-schedules', loadingSchedules.value);
            ScheduleAPI.getSchedules(props.workflowId)
                .then((resSchedules: any) => {
                    schedules.value = resSchedules.data;
                    if (retrievalType.value !== 'once')
                        schedules.value.forEach((schedule: ScheduleType) => {
                            schedule.endDate = new Date(
                                dayjs(new Date(schedule.endDate as Date))
                                    .subtract(1, 'day')
                                    .format(),
                            );
                        });

                    emit('updated-schedules', schedules.value);
                    if (schedules.value.length) {
                        closeScheduleEditor.value = true;
                    }
                    loadingSchedules.value = false;
                    emit('loading-schedules', loadingSchedules.value);
                })
                .catch((e) => {
                    loadingSchedules.value = false;
                    emit('loading-schedules', loadingSchedules.value);
                    schedulerError.value = e.response?.status === 500;
                })
                .finally(() => (closeScheduleEditor.value = false));
        };

        const setLoading = (loadingValue: boolean) => {
            loadingSchedule.value = loadingValue;
            emit('set-loading', loadingValue);
        };

        const saveSchedule = async (schedule: any) => {
            setLoading(true);
            schedulesError.value = null;
            if (schedule.id) {
                ScheduleAPI.update(schedule.id, schedule)
                    .then(() => {
                        getSchedules();
                        setLoading(false);
                        (root as any).$toastr.s('Schedule has been updated successfully', 'Success');
                    })
                    .catch((e) => {
                        setLoading(false);
                        (root as any).$toastr.e(e.response.data.message, 'Error');
                        schedulesError.value = e.response.data.message;
                    });
            } else {
                ScheduleAPI.create({ ...schedule, workflowType: 'datacheckin' })
                    .then(async () => {
                        // save retrieval type to step's configuration or processing option (if step not saved yet)
                        if (
                            ((!props.stepConfig.isSaved && props.stepConfig.retrieval?.type === 'once') ||
                                props.stepConfig.retrieval?.type === 'periodic' ||
                                props.stepConfig.processing === ProcessingOptions.TimeBased) &&
                            props.stepConfig.source !== HarvesterSourceType.Api
                        ) {
                            await exec(JobsAPI.updateStep(props.stepId, { configuration: props.stepConfig }));
                        }

                        setLoading(false);
                        (root as any).$toastr.s('Schedule has been created successfully', 'Success');
                        getSchedules();
                    })
                    .catch((e) => {
                        setLoading(false);
                        (root as any).$toastr.e(e.response.data.message, 'Error');
                        schedulesError.value = e.response.data.message;
                    });
            }
        };

        const changeScheduleStatus = (schedule: any) => {
            const idx = schedules.value.findIndex((obj: any) => obj.id === schedule.id);
            if (schedule.isEnabled) {
                ScheduleAPI.deactivate(schedule.id).then(() => {
                    schedules.value[idx].isEnabled = false;
                });
            } else {
                ScheduleAPI.activate(schedule.id).then(() => {
                    schedules.value[idx].isEnabled = true;
                });
            }
        };

        const deleteSchedule = (id: string) => {
            ScheduleAPI.delete(id).then(() => {
                const idx = schedules.value.findIndex((obj: any) => obj.id === id);
                schedules.value.splice(idx, 1);
                emit('updated-schedules', schedules.value);
            });
        };

        getSchedules();

        const scheduleRequired = computed(() => retrievalType.value !== 'immediately');

        const deleteSchedules = async () => {
            const promises: any = [];

            if (schedules.value.length) {
                schedules.value.forEach((schedule: any) => {
                    promises.push(ScheduleAPI.delete(schedule.id));
                });
                await Promise.all(promises);
                schedules.value.splice(0);
                closeScheduleEditor.value = true;
                emit('updated-schedules', schedules.value);
            }
        };

        watch(
            () => props.stepConfig?.retrieval?.type,
            (retrieval: string) => {
                if (retrieval) deleteSchedules();
            },
        );

        watch(
            () => props.frequency,
            async (frequency: string) => {
                if (frequency && oldFrequency.value && oldFrequency.value !== frequency) deleteSchedules();
            },
        );

        return {
            editingSchedule,
            saveSchedule,
            deleteSchedule,
            changeScheduleStatus,
            loadingSchedules,
            schedules,
            schedulesError,
            closeScheduleEditor,
            scheduleRequired,
            retrievalType,
            loadingSchedule,
            schedulerError,
        };
    },
});
