





























































































































































import { useAxios, useFilters } from '@/app/composable';
import { ClockIcon, EyeIcon, PlusCircleIcon, ReceiptRefundIcon, RefreshIcon } from '@vue-hero-icons/outline';
import { CalendarIcon, CollectionIcon, DatabaseIcon, SwitchHorizontalIcon, UserIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, ref } from '@vue/composition-api';
import dayjs from 'dayjs';
import * as R from 'ramda';
import store from '@/app/store';
import { ReimbursementMethodIcon } from '..';
import { useContractStatus } from '../../composable';
import { ContractStatus, ReimbursementMethod, ReimbursementMethodShortText } from '../../constants';
import { ContractsAPI } from '../../api';

export default defineComponent({
    name: 'ContractOverview',
    components: {
        UserIcon,
        CalendarIcon,
        SwitchHorizontalIcon,
        CollectionIcon,
        DatabaseIcon,
        ClockIcon,
        PlusCircleIcon,
        ReceiptRefundIcon,
        ReimbursementMethodIcon,
        EyeIcon,
        RefreshIcon,
    },
    props: {
        contract: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit, root }) {
        const { formatDate } = useFilters();
        const { statusClass } = useContractStatus();
        const user = computed(() => store.state.auth.user);
        const isLegalRepresentative = ref<boolean>(user.value.roles.includes('legal-representative'));
        const isConsumer = ref<boolean>(props.contract.consumerOrg.id === user.value.organisationId);
        const { exec, loading } = useAxios(true);

        const uniqueAssets: any = computed(() => R.uniq(props.contract.assets));

        const isBundle = computed(() => {
            if (props.contract.assets) return true;
            return false;
        });

        const getUser = (type: string) => {
            if (type === 'provider') return props.contract.providerOrg.businessName;
            if (type === 'consumer') return props.contract.consumerOrg.businessName;
            return null;
        };

        const goToContract = () => {
            root.$router.push({
                name: 'contract-details',
                params: { type: isBundle.value ? 'bundle' : 'details', id: `${props.contract.id}` },
            });
        };

        const goToContractMonitoring = () => {
            root.$router.push({
                name: 'contract-monitoring',
                params: { type: isBundle.value ? 'bundle' : 'contract', id: `${props.contract.id}` },
            });
        };

        const configureContract = () => {
            emit('configure', props.contract);
        };

        const goToContractAction = (action: string) => {
            root.$router.push({
                name: 'contract-action',
                params: { type: isBundle.value ? 'bundle' : 'details', id: `${props.contract.id}`, action },
            });
        };

        const syncContract = (contractId: string) => {
            exec(ContractsAPI.syncContract(contractId, 'acquisition'))
                .then((res: any) => {
                    const newContract = res.data;
                    if (!R.isNil(newContract.pendingTransactionId))
                        (root as any).$toastr.w(
                            'Blockchain transaction is still pending. Please try again later.',
                            'Pending Transaction',
                        );
                    else {
                        (root as any).$toastr.s('Contract state synchronised!', 'Success');
                        emit('reload');
                    }
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to synchronise contract state!', 'Error');
                });
        };

        return {
            isBundle,
            statusClass,
            formatDate,
            configureContract,
            goToContract,
            dayjs,
            getUser,
            uniqueAssets,
            ReimbursementMethod,
            ReimbursementMethodShortText,
            ContractStatus,
            goToContractAction,
            goToContractMonitoring,
            isConsumer,
            user,
            isLegalRepresentative,
            loading,
            syncContract,
        };
    },
});
