export interface Source {
    id: number;
    title: string;
    path: string[];
    type: string;
}

export interface Target {
    id: number | null;
    title: string | null;
    parentIds: number[];
    pathUids: string[];
    path: string[];
    type: string | null;
    categories: string[];
}

export interface Result {
    target: number;
    score: number;
}

export interface Prediction {
    target: number;
    score: number;
    confidence: number;
    alternativeResults?: Result[];
}

export interface FieldPrediction {
    id: number;
    matchings: Prediction | null;
    path: string[];
    title: string;
}

export interface Transformation {
    order?: number;
    multiple?: boolean;
    measurementType?: string;
    sourceUnit?: string;
    targetUnit?: string;
    sourceDateFormat?: string;
    sourceTimezone?: string;
    dateOrder?: string;
    oneElementArrays?: number[];
}

export interface Metadata {
    indexES: boolean;
    indexMongo: boolean;
    temporal: boolean;
    spatial: boolean;
}

export interface FieldConfiguration {
    source: Source;
    target: Target;
    prediction?: Prediction;
    transformation?: Transformation;
    metadata?: Metadata;
    temp: Record<string, any>;
    annotation?: string | null;
    alias?: string | null;
}

export interface ReferencePrefix {
    prefix: string;
    description: string;
}

export interface MappingConfig {
    domain: {
        id: number;
        name: string;
    } | null;
    standard: Record<string, any> | null;
    concept: {
        id: number;
        name: string;
    } | null;
    versions: {
        editor: string;
        transformationEngine: string;
        model: string | null;
        predictionEngine: string | null;
    };
    fields: FieldConfiguration[];
    customizedConcepts: Record<number, ReferencePrefix[]>;
    multiple: boolean;
    basePath: string | null;
    scheduler?: string | null;
    alternateNaming?: string;
}

export enum AlternateNaming {
    None = 'none',
    Original = 'original',
    Alias = 'alias',
}
