






import { defineComponent, PropType } from '@vue/composition-api';
import { Contract } from '../../types';

export default defineComponent({
    name: 'MonitoringOverTime',
    props: {
        contract: {
            type: Object as PropType<Contract>,
            required: true,
        },
    },
});
