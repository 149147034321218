var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"filtersValidationRef",staticClass:"flex flex-col space-y-5",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form-modal',{attrs:{"title":"Add filters to the field values","allowSave":!invalid && !_vm.error},on:{"cancel":_vm.cancel,"save":_vm.save}},[(_vm.temporalCoverage && _vm.minTemporal && _vm.maxTemporal)?_c('div',{staticClass:"mb-3 text-sm italic text-neutral-600"},[_vm._v(" The dataset contains values from "),_c('span',{staticClass:"text-neutral-800"},[_vm._v(_vm._s(_vm.formatDate(_vm.minTemporal.toISOString())))]),_vm._v(" to "),_c('span',{staticClass:"text-neutral-800"},[_vm._v(_vm._s(_vm.formatDate(_vm.maxTemporal.toISOString())))]),_vm._v(". ")]):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"grid grid-cols-2 gap-5"},[(_vm.type === 'datetime' || _vm.type === 'date')?_c('div',[_c('div',{staticClass:"text-sm font-medium text-neutral-600"},[_vm._v("From:")]),_c('validation-provider',{attrs:{"rules":"required"}},[_c('v-date-picker',{staticClass:"w-full",attrs:{"timezone":"utc","mode":_vm.type === 'datetime' ? 'datetime' : 'date',"is24hr":"","min-date":_vm.minTemporal,"max-date":_vm.maxDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"w-full py-1 text-sm form-input disabled:bg-neutral-300",attrs:{"type":"text"},domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1)],1):_vm._e(),(_vm.type === 'datetime' || _vm.type === 'date')?_c('div',[_c('div',{staticClass:"text-sm font-medium text-neutral-600"},[_vm._v("To:")]),_c('validation-provider',{attrs:{"rules":"required"}},[_c('v-date-picker',{staticClass:"w-full",attrs:{"timezone":"utc","mode":_vm.type === 'datetime' ? 'datetime' : 'date',"is24hr":"","min-date":_vm.minDate,"max-date":_vm.maxTemporal},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"w-full py-1 text-sm form-input disabled:bg-neutral-300",attrs:{"type":"text"},domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1)],1):_vm._e()]),(_vm.error)?_c('div',{staticClass:"mt-1 text-xs text-red-700"},[_vm._v(_vm._s(_vm.error))]):_vm._e()]),(_vm.filters && Object.keys(_vm.filters).length)?_c('a',{staticClass:"text-sm text-neutral-500 hover:text-red-500 hover:underline",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$emit('remove-filters')}}},[_vm._v(" Remove Filters ")]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }