export enum AccessLevel {
    Public = 'Public',
    SelectiveSharing = 'SelectiveSharing',
    OrganisationLevel = 'OrganisationLevel',
}

export const AccessLevelsOptions = {
    [AccessLevel.OrganisationLevel]: 'Organisation-level Access',
    [AccessLevel.SelectiveSharing]: 'Selective Sharing',
    [AccessLevel.Public]: 'Public Access',
};

export const AccessLevelsExtensiveOptions = {
    [AccessLevel.OrganisationLevel]: 'Organisation-level Access (All users within the same organization can access)',
    [AccessLevel.SelectiveSharing]:
        'Selective Sharing (Selected external / third-party organisations can access, depending on the applicable access policies)', // TODO: hide for now
    [AccessLevel.Public]: 'Public Access (All users can access)',
};
