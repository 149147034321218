import { useJsonObject } from '@/app/composable';
import { Concept } from '@/app/interfaces';
import { computed, Ref } from '@vue/composition-api';
import dayjs from 'dayjs';
import * as R from 'ramda';
import { FieldConfiguration, Metadata, Source } from '../views/mapping/mapping.types';
import { useSampleFields } from './sample-fields';

export function useMappingFields(
    sample: Ref<any[]>,
    rootConcept: Ref<Concept | undefined>,
    basePath: Ref<string[] | undefined>,
) {
    let id = 1; // Auto-increment identifier for source fields. Required for mapping
    const { extractFieldSample } = useSampleFields();
    const { getSuperObject } = useJsonObject();

    const checkType = (value: any): string => {
        if (isNaN(value) && !R.is(Array, value) && dayjs(Date.parse(value)).isValid()) {
            return 'Date';
        }

        return R.type(value); // Booleans are considered numbers, thus we need to account for that as well
    };

    const fieldType = (data: Record<string, any>[]) => {
        const types = R.uniq(R.map(checkType, data));
        if (R.contains('Array', types)) return 'array';
        if (R.contains('Object', types)) return 'object';
        if (R.contains('String', types)) return 'string';
        if (R.contains('Date', types)) return 'date';
        if (R.contains('Number', types)) return 'number';
        if (R.contains('Boolean', types)) return 'boolean';
        if (types.length === 1 && types[0] === 'Null') return 'null';
        return '';
    };
    const superSample = computed(() => getSuperObject(R.clone(sample.value), []));
    /**
     * Recursive function to extract all fields from the given sample
     * @param title The title of the field
     * @param path The path of the field
     */
    const extractFields = (title: string, path: string[], result: Source[]) => {
        const fieldSample = extractFieldSample(superSample.value, title, path);
        const type = title === '_uploaded_file' ? 'base64binary' : fieldType(fieldSample);
        const notEmptySample = fieldSample.find((fs: any) => fs);
        if (type === 'array') {
            if (fieldSample.length > 0) {
                let fieldSamples: any[] = [];
                fieldSample.forEach((fs) => {
                    fieldSamples = fieldSamples.concat(fs);
                });
                const arrayType = fieldType(fieldSamples);
                if (
                    arrayType === 'string' ||
                    arrayType === 'number' ||
                    arrayType === 'date' ||
                    arrayType === 'boolean'
                ) {
                    if (!result.find((item) => R.equals(item.title, title) && R.equals(item.path, path))) {
                        result.push({ id: id++, title, path, type: `array [${arrayType}]` });
                    }
                    return;
                }

                if (arrayType === 'object') {
                    if (notEmptySample) {
                        const field = notEmptySample.find((fs: any) => fs);
                        if (field) {
                            Object.keys(field).forEach((key: string) =>
                                extractFields(key, [...path, `${title}[]`], result),
                            );
                            return;
                        }
                    }
                } else if (arrayType === 'array') {
                    const pathArray: any[] = [...path, title];
                    let nestedArrayCount = 0;
                    let finalTitle = title;

                    // build nested array structure using the innermost object nesting level
                    // e.g. superSample = { value: [{ field1: { title: [[{ key: 'value' }]]} }] }
                    // e.g. pathArray = ['field1', 'title'] => final path array will be ['field1', 'title', 0, 0]
                    // e.g. finalTitle = 'title[][]'
                    while (R.is(Array, R.map(R.path(pathArray), superSample.value)[0])) {
                        pathArray.push(0);
                        finalTitle += '[]';
                        nestedArrayCount += 1;
                    }

                    notEmptySample.forEach((obj: any) => {
                        const field = obj.flat(nestedArrayCount).find((fs: any) => fs);

                        if (field) {
                            Object.keys(field).forEach((key: string) =>
                                extractFields(key, [...path, finalTitle], result),
                            );
                        }
                    });
                    return;
                }
            }
            return;
        }

        if (type === 'object') {
            if (notEmptySample) {
                Object.keys(notEmptySample).forEach((key: string) => extractFields(key, [...path, title], result));
                return;
            }
        }

        if (!result.find((item) => R.equals(item.title, title) && R.equals(item.path, path))) {
            result.push({ id: id++, title, path, type });
        }

        return;
    };

    /**
     * Returns a blank field
     * @param source
     */
    const initEmptyField = (source: Source): FieldConfiguration => {
        if (!rootConcept.value) throw Error('Root concept not defined!');
        return {
            source,
            target: {
                id: null,
                title: null,
                parentIds: [rootConcept.value.id],
                type: null,
                path: [rootConcept.value.name],
                categories: [rootConcept.value.name],
                pathUids: [rootConcept.value.uid],
            },
            prediction: undefined,
            temp: {
                invalid: false,
            },
        };
    };

    /**
     * Extracts the value of a field given the path
     *
     * @param keepSelections Whether to return existing value
     * @param field - The field to query
     * @param path - The path of interest
     * @param defaultValue - The default value to return if we are not
     * interested in the existing value of the field
     */
    const extractFieldValue = (
        keepSelections: boolean,
        field: FieldConfiguration,
        path: string[],
        defaultValue: any = null,
    ) => {
        if (!keepSelections) {
            return defaultValue;
        }

        return R.path(path, field);
    };

    const removeArrayBracketsFromBasePath = (field: FieldConfiguration): FieldConfiguration => {
        if (!basePath.value || field.source.path.length < (basePath.value as string[]).length) return field;
        const newField = R.clone(field);
        for (let i = 0; i < (basePath.value as string[]).length; i++) {
            if (newField.source.path[i].replaceAll('[]', '') === basePath.value[i]) {
                newField.source.path[i] = newField.source.path[i].replaceAll('[]', '');
            } else break;
        }
        return newField;
    };

    const createFieldConfiguration = (
        field: FieldConfiguration,
        concept: Concept,
        prediction: any,
        keepSelections = false,
        defaultOrder: any = null,
    ): FieldConfiguration => {
        let newField = {
            source: field.source,
            target: {
                ...field.target,
                id: concept.id,
                title: concept.name,
                type: concept.type,
            },
            transformation: {},
            metadata: R.pick(['indexES', 'indexMongo', 'temporal', 'spatial'], concept.metadata) as Metadata,
            temp: {
                ...field.temp,
                userDefined: prediction === null,
            },
            annotation: null,
            alias: null,
        };

        if (prediction) {
            newField = R.assocPath(['prediction'], prediction, newField);
        } else if (R.hasPath(['prediction'], newField)) {
            newField = R.assocPath(['prediction'], R.clone(field.prediction), newField);
        }

        // Date transformations
        if (concept.type === 'datetime') {
            newField = R.assocPath(
                ['transformation', 'sourceTimezone'],
                extractFieldValue(keepSelections, newField, ['transformation', 'sourceTimezone']),
                newField,
            );
            newField = R.assocPath(
                ['transformation', 'sourceDateFormat'],
                extractFieldValue(keepSelections, newField, ['transformation', 'sourceDateFormat']),
                newField,
            );
            newField = R.assocPath(
                ['transformation', 'dateOrder'],
                extractFieldValue(keepSelections, newField, ['transformation', 'dateOrder']),
                newField,
            );
        }

        if (concept.type === 'date') {
            newField = R.assocPath(
                ['transformation', 'sourceDateFormat'],
                extractFieldValue(keepSelections, newField, ['transformation', 'sourceDateFormat']),
                newField,
            );
            newField = R.assocPath(
                ['transformation', 'dateOrder'],
                extractFieldValue(keepSelections, newField, ['transformation', 'dateOrder']),
                newField,
            );
        }

        if (concept.type === 'time') {
            newField = R.assocPath(
                ['transformation', 'sourceTimezone'],
                extractFieldValue(keepSelections, newField, ['transformation', 'sourceTimezone']),
                newField,
            );
        }

        if (concept.type === 'double') {
            newField = R.assocPath(['transformation', 'thousandsSeperator'], '', newField);
            newField = R.assocPath(['transformation', 'decimalPoint'], '.', newField);
        }

        // More arrays in target path than source path
        const tempField = removeArrayBracketsFromBasePath(field);
        const arraysInSource = tempField.source.path.join('')?.match(/\[\]/g)?.length ?? 0;
        const arraysInTarget = tempField.target.path.join('')?.match(/\[\]/g)?.length ?? 0;

        if (tempField.source.path.some((p) => p.includes('[]')) && arraysInTarget > arraysInSource) {
            newField = R.assocPath(['transformation', 'oneElementArrays'], [], newField);
        }

        // Multiple (and ordered) fields
        if (concept.metadata?.multiple) {
            newField = R.assocPath(
                ['transformation', 'multiple'],
                extractFieldValue(keepSelections, newField, ['transformation', 'multiple'], true),
                newField,
            );
            if (concept.metadata.ordered) {
                newField = R.assocPath(
                    ['transformation', 'order'],
                    extractFieldValue(keepSelections, newField, ['transformation', 'order'], defaultOrder),
                    newField,
                );
            }
        }

        // Unit Transformations
        if (concept.metadata?.measurementType) {
            newField = R.assocPath(
                ['transformation', 'measurementType'],
                extractFieldValue(
                    keepSelections,
                    newField,
                    ['transformation', 'measurementType'],
                    concept.metadata.measurementType,
                ),
                newField,
            );
            newField = R.assocPath(
                ['transformation', 'sourceUnit'],
                extractFieldValue(keepSelections, newField, ['transformation', 'sourceUnit']),
                newField,
            );
            newField = R.assocPath(
                ['transformation', 'targetUnit'],
                extractFieldValue(
                    keepSelections,
                    newField,
                    ['transformation', 'targetUnit'],
                    concept.metadata.measurementUnit,
                ),
                newField,
            );
        }

        return newField;
    };

    return { extractFields, initEmptyField, createFieldConfiguration, removeArrayBracketsFromBasePath };
}
