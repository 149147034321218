













import { DocumentTextIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'NotificationContractIcon',
    components: {
        DocumentTextIcon,
    },
    props: {
        eventType: {
            type: String,
            required: true,
        },
        dropdown: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const iconType = computed(() => {
            switch (props.eventType) {
                case 'contract.created':
                case 'contract.negotiation':
                case 'contract.draft.signed':
                case 'contract.extended':
                case 'contract.revised':
                case 'contract.reactivated':
                    return 'neutral';
                case 'contract.activated':
                case 'contract.accepted':
                    return 'success';
                case 'contract.rejected':
                    return 'fail';
                default:
                    return 'neutral';
            }
        });

        return { iconType };
    },
});
