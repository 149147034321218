





















































































































































import { SvgImage } from '@/app/components';
import { useSockets } from '@/app/composable/socket';
import { StatusCode } from '@/modules/asset/constants';
import {
    ClipboardListIcon,
    CloudDownloadIcon,
    CloudUploadIcon,
    FireIcon,
    HomeIcon,
    SearchIcon,
    ServerIcon,
    ShareIcon,
    ShoppingBagIcon,
    TemplateIcon,
    ViewGridIcon,
    XIcon,
} from '@vue-hero-icons/outline';
import { computed, defineComponent } from '@vue/composition-api';
import { useFeatureFlags } from '../composable';

export default defineComponent({
    name: 'SideMenu',
    components: {
        HomeIcon,
        CloudUploadIcon,
        ViewGridIcon,
        SearchIcon,
        ShareIcon,
        TemplateIcon,
        XIcon,
        SvgImage,
        ShoppingBagIcon,
        FireIcon,
        ClipboardListIcon,
        ServerIcon,
    },
    props: {
        mobileMenuOpen: { type: Boolean, default: false },
    },
    setup() {
        const { isEnabled: isFeatureEnabled, areAnyEnabled: areAnyFeaturesEnabled } = useFeatureFlags();
        const { connected, socketConnect } = useSockets();
        const defaultAssetStatus = Object.values(StatusCode).filter(
            (statusCode: StatusCode) => ![StatusCode.Deleted, StatusCode.Archived].includes(statusCode),
        );
        const sidebarNavigation = computed(() => [
            { name: 'Home', route: 'dashboard', icon: HomeIcon, exact: true, visible: true },
            { name: 'Data Check-in', route: 'data-checkin-jobs', icon: CloudUploadIcon, exact: false, visible: true },
            {
                name: 'Data Monitoring',
                route: 'dcj:monitoring',
                icon: FireIcon,
                exact: false,
                visible: true,
            },
            {
                name: 'My Assets',
                route: 'assets',
                query: { status: defaultAssetStatus.join(','), reset: true },
                icon: ViewGridIcon,
                exact: false,
                visible: true,
            },
            {
                name: 'Marketplace',
                route: 'marketplace',
                icon: ShoppingBagIcon,
                exact: true,
                visible: isFeatureEnabled('sharing'),
            },
            {
                name: 'Contracts',
                route: 'contracts',
                icon: ClipboardListIcon,
                exact: true,
                visible: isFeatureEnabled('sharing'),
            },
            {
                name: 'Data Retrieval',
                route: 'retrieval',
                icon: CloudDownloadIcon,
                exact: false,
                visible: areAnyFeaturesEnabled(['retrieve.api', 'retrieve.files', 'retrieve.stream']),
            },
            {
                name: 'Data Analytics',
                route: 'workflows',
                icon: ShareIcon,
                exact: false,
                visible: isFeatureEnabled('analytics'),
            },
            {
                name: 'Data Space Management',
                route: 'data-space-management',
                icon: ServerIcon,
                exact: false,
                visible: isFeatureEnabled('on-premise'),
            },
        ]);

        return { sidebarNavigation, connected, socketConnect };
    },
});
