export enum DCJStep {
    Mapping = 'mapping',
    Encryption = 'encryption',
    Harvester = 'harverster',
    Cleaning = 'cleaning',
    Anonymiser = 'anonymiser',
    Loader = 'loader',
}

export const SAMPLE_LIMIT = 1000 * 500; // 500KB
