



























import { Concept, Model } from '@/app/interfaces';
import { ModelAPI } from '@/modules/data-checkin/api';
import { SuggestionModal } from '@/modules/data-checkin/components';
import { useSuggestions } from '@/modules/data-checkin/composable';
import { useAxios } from '@/app/composable';
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';

export default defineComponent({
    name: 'ConceptSuggestion',
    props: {
        model: { type: Object as PropType<Model>, required: true },
        concepts: { type: Array as PropType<Concept[]>, required: true },
        inline: { type: Boolean, default: false },
    },
    components: { SuggestionModal },
    setup(props, { root }) {
        const { exec } = useAxios(true);
        const model = computed(() => props.model);
        const concepts = computed(() => props.concepts);
        const { showModal: showSuggestionModal, initConcept, domainConcepts, domainStandards } = useSuggestions(
            model,
            concepts,
        );
        const newSuggestion = ref<any>(null);

        const createSuggestion = () => {
            newSuggestion.value = initConcept();
            showSuggestionModal.value = true;
        };

        const saveSuggestion = () => {
            exec(ModelAPI.createSuggestion(newSuggestion.value))
                .then(() => {
                    showSuggestionModal.value = false;
                    (root as any).$toastr.s('Suggestion submitted successfuly');
                })
                .catch(() => {
                    (root as any).$toastr.e('Error submitting suggestion');
                });
        };

        return {
            createSuggestion,
            saveSuggestion,
            domainConcepts,
            domainStandards,
            showSuggestionModal,
            newSuggestion,
        };
    },
});
