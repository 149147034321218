import * as R from 'ramda';
import { Route, NavigationGuard } from 'vue-router';
import store from '@/app/store';
import { UserRoles } from '@/app/constants';
import { AuthGuard } from './auth.guard';

type Next = Parameters<NavigationGuard>[2];

export const RoleGuard = async (to: Route, from: Route, next: Next, role: UserRoles) => {
    const { user } = store.state.auth;
    if (user && user.roles && user.roles.length > 0) {
        if (R.includes(role, user.roles)) {
            return AuthGuard(to, from, next);
        }
    }
    return AuthGuard({ name: 'home' } as Route, from, next);
};
