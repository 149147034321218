import { moduleActionContext } from '@/app/store';
import { defineModule } from 'direct-vuex';

export interface OrganisationState {
    details: OrganisationDetails | null;
    departments: Department[];
    users: OrgUser[];
}

export interface OrganisationDetails {
    id: string;
    legalName: string;
    bussinessName: string;
    description: string | null;
    status: string;
    website: string;
    ethaddress?: string | null;
    ethwallet?: string | null;
    hasWallet: boolean;
    createdBy: { id: string } | null;
    type: { name: string } | null;
    location: Location | null;
    departments: Department[];
    approvalDate?: Date;
}

export interface Location {
    id: string;
    address: string;
    postalCode: string;
    city: { id: string; name: string } | null;
    country: { id: string; name: string; flag: string; continent?: { id: string } | null } | null;
}

export interface OrgUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: string[];
    username: string;
    status: string;
}

export interface Department {
    id: string;
    name: string;
    description: string | null;
    location: Location | null;
    users?: {
        user: { id: string; firstName: string; lastName: string };
        role: { id: string; name: string } | null;
    }[];
}

export const organisationModule = defineModule({
    namespaced: true,
    state: (): OrganisationState => {
        return {
            details: null,
            departments: [],
            users: [],
        };
    },
    mutations: {
        SET_ORGANISATION_DETAILS(state: OrganisationState, details: OrganisationDetails) {
            state.details = details;
        },
        SET_DEPARTMENTS(state: OrganisationState, departments: Department[]) {
            state.departments = departments;
        },
        SET_USERS(state: OrganisationState, users: OrgUser[]) {
            state.users = users;
        },
        SET_WALLET(state: OrganisationState, ethwallet: string) {
            if (!state.details) return;
            state.details.ethwallet = ethwallet;
        },
        SET_HAS_WALLET(state: OrganisationState, hasWallet: boolean) {
            if (!state.details) return;
            state.details.hasWallet = hasWallet;
        },
    },
    getters: {
        details: (state: OrganisationState) => state.details,
        departments: (state: OrganisationState) => state.departments,
        users: (state: OrganisationState) => state.users,
        userOptions: (state: OrganisationState) =>
            state.users.map((user: OrgUser) => ({
                id: parseInt(user.id),
                label: `${user.firstName} ${user.lastName}`,
            })),
        hasWallet: (state: OrganisationState) => (state.details ? state.details.hasWallet : false),
    },
    actions: {
        setOrganisationDetails: ({ commit }, details: OrganisationDetails) => {
            commit('SET_ORGANISATION_DETAILS', details);
        },
        setUsers: ({ commit }, users: OrgUser[]) => {
            commit('SET_USERS', users);
        },
        setDepartments: ({ commit }, departments: Department[]) => {
            commit('SET_DEPARTMENTS', departments);
        },
        setWallet: ({ commit }, ethwallet: string) => {
            commit('SET_WALLET', ethwallet);
        },
        setHasWallet: ({ commit }, hasWallet: boolean) => {
            commit('SET_HAS_WALLET', hasWallet);
        },
    },
});

export const organisationModuleActionContext = (context: any) => moduleActionContext(context, organisationModule);
