



































































































import { defineComponent } from '@vue/composition-api';
import { HtmlModal, Scrollbar } from '@/app/components';

export default defineComponent({
    name: 'Terms',
    components: { HtmlModal, Scrollbar },
});
