import * as R from 'ramda';
import { AlternateNaming } from '../views/mapping/mapping.types';

export function useSampleFields() {
    /**
     * Extract an array of values, using the provided sample
     * @param title The title of the field to search for
     * @param path The path of the field to search for
     */
    const extractFieldSample = (sample: any[], title: string, path: string[]): any[] => {
        const finalPaths: any[] = [[]]; // Contains the paths to get samples from every record
        path.forEach((subPath: any) => {
            if (subPath.endsWith('[]')) {
                finalPaths.forEach((fPath: any) => {
                    fPath.push(subPath.replaceAll('[]', '')); // Remove [] from subPath
                    const subPathSamples: any[] = R.map(R.path(fPath), sample); // Contains the samples from every record for subPath
                    let subPathSamplesLength = 0;
                    subPathSamples.forEach((subPathSample: any) => {
                        // add nested paths in case of array in array
                        let arraysCount = 0;
                        while (R.is(Array, R.map(R.path(fPath), sample)[0])) {
                            fPath.push(0);
                            arraysCount += 1;

                            if (arraysCount > 1) subPathSample = subPathSample[0];
                        }

                        if (subPathSample && subPathSample.length > subPathSamplesLength) {
                            subPathSamplesLength = subPathSample.length;
                        }
                    });

                    // Calculate all the paths based on subPath's sample length
                    for (let i = 1; i < subPathSamplesLength; i += 1) {
                        const newPath = R.clone(fPath);
                        newPath.pop();
                        newPath.push(i);
                        finalPaths.push(newPath);
                    }
                });
            } else {
                // Add subPath to all paths
                finalPaths.forEach((p: any) => p.push(subPath));
            }
        });

        // Add title to all paths
        finalPaths.forEach((p: any) => p.push(title));

        // Find the sample values of every path
        const allSamples: any[][] = [];
        finalPaths.forEach((fPath: any[]) => {
            let fPathSample = R.map(R.path(fPath), sample);
            fPathSample = fPathSample.filter((value: any) => value !== undefined);
            allSamples.push(fPathSample);
        });
        /**
         * Extract the values of each row
         * e.g. from [[1,3], [2,4]] => [1,2,3,4]
         */
        const sampleValuesPerRow = R.transpose(allSamples);
        return sampleValuesPerRow.reduce((acc: any[], current: any) => acc.concat(current), []);
    };

    /**
     * Constructs and returns the fields with the new names after mapping.
     * @param fields The list of fields before mapping
     */
    const extractMappingFieldNames = (fields: Array<any[]>) => {
        const mappingFields: any[] = [];
        fields
            .filter(
                // If multiple fields are mapped to the same field under the same path, only show it once
                (field: any, index: number) =>
                    field.target &&
                    (fields.map((f: any) => f.target.id).indexOf(field.target.id) === index ||
                        fields.map((f: any) => f.target.path.join('_')).indexOf(field.target.path.join('_')) === index),
            )
            .forEach((field: any) => {
                if (field.target && field.target.id) {
                    // add mapped field target object
                    const obj = field.target;
                    const defaultKey = field.target.title;

                    if (field.transformation) {
                        obj.multiple = field.transformation.multiple;
                    }
                    // if mapped field has a path then add it to the name
                    if (field.target.path.length > 0) {
                        const path = field.target.path.map((pathField: string) => pathField.replace('[]', '__0'));
                        obj.name = `${path.join('__')}__${defaultKey}`;
                    } else {
                        obj.name = defaultKey;
                    }

                    // if the field has been revised in mapping
                    if (field.temp && 'modified' in field.temp) {
                        obj.modified = field.temp.modified;
                    }

                    obj.originalName = field.source.title;
                    obj.originalPath = field.source.path;
                    obj.alias = field.alias;

                    mappingFields.push(obj);
                }
            });
        return mappingFields;
    };

    /**
     * Constructs and returns an object containing the alternate name (title and path) of each mapped field.
     * @param mappingConfig the mapping configuration
     * @returns the alternate naming schema
     */
    const extractAlternateNames = (mappingConfig: any) => {
        if (mappingConfig.alternateNaming === AlternateNaming.None) return null;
        const mappedFields = mappingConfig.fields.filter((field: any) => !!field.target?.id);
        const orderedMappedFields = R.sort(R.ascend<any>(R.path(['transformation', 'order'])), mappedFields);
        const alternateNamesResult = {};

        switch (mappingConfig.alternateNaming) {
            case AlternateNaming.Alias: {
                // In case of Alias naming, return the selected alias names with the target path
                orderedMappedFields.forEach((field: any) => {
                    if (!alternateNamesResult[field.target.id]) {
                        alternateNamesResult[field.target.id] = {
                            title: [field.target.title],
                            path: field.target.path,
                        };
                    }
                    if (field.alias) {
                        if (
                            alternateNamesResult[field.target.id].title.length === 1 &&
                            alternateNamesResult[field.target.id].title[0] === field.target.title
                        ) {
                            alternateNamesResult[field.target.id].title = [];
                        }
                        alternateNamesResult[field.target.id].title.push(field.alias);
                    }
                });
                break;
            }
            case AlternateNaming.Original: {
                // In case of Original naming, return the source names and path
                orderedMappedFields.forEach((field: any) => {
                    if (!alternateNamesResult[field.target.id]) {
                        alternateNamesResult[field.target.id] = {
                            title: [],
                            path: field.target.path,
                        };
                    }
                    alternateNamesResult[field.target.id].title.push(field.source.title);
                });
                break;
            }
        }
        return alternateNamesResult;
    };

    return { extractFieldSample, extractMappingFieldNames, extractAlternateNames };
}
