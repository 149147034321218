import {
    AnnotationIcon,
    CheckCircleIcon,
    CogIcon,
    InformationCircleIcon,
    PencilAltIcon,
    SwitchVerticalIcon,
} from '@vue-hero-icons/outline';

export enum TransformationType {
    None = 'None',
    Alias = 'Alias',
    Annotation = 'Annotation',
    DatetimeFormat = 'DatetimeFormat',
    DecimalPoint = 'DecimalPoint',
    NestingLevel = 'NestingLevel',
    ThousandsSeparator = 'ThousandsSeparator',
    Timezone = 'Timezone',
    TypeCast = 'TypeCast',
    Unit = 'Unit',
}

interface TransformationInfo {
    type: TransformationType;
    decimalPoint?: string;
    thousandsSeperator?: string;
    measurementType?: string;
    source?: string;
    target?: string;
    annotation?: string;
    alias?: string;
}

export function useMappingTransformations() {
    const getTransformations = (field: Readonly<any>): Array<TransformationInfo> => {
        const transformations: Array<TransformationInfo> = [];

        if (field.source.type !== field.target.type)
            transformations.push({ type: TransformationType.TypeCast, target: field.target.type });

        if (field.transformation?.sourceDateFormat) {
            const target = field.target.type === 'datetime' ? 'ISO 8601' : 'YYYY-MM-DD';
            if (field.transformation.sourceDateFormat !== target)
                transformations.push({
                    type: TransformationType.DatetimeFormat,
                    source: field.transformation.sourceDateFormat,
                    target,
                });
        }

        if (field.transformation?.sourceTimezone && field.transformation.sourceTimezone !== 'UTC')
            transformations.push({
                type: TransformationType.Timezone,
                source: field.transformation.sourceTimezone,
                target: 'UTC',
            });

        if (field.transformation?.measurementType)
            transformations.push({
                type: TransformationType.Unit,
                source: field.transformation.sourceUnit,
                target: field.transformation.targetUnit,
                measurementType: field.transformation.measurementType,
            });

        if (field.transformation?.thousandsSeperator)
            transformations.push({
                type: TransformationType.ThousandsSeparator,
                thousandsSeperator: field.transformation.thousandsSeperator,
            });

        if (field.transformation?.decimalPoint && field.transformation.decimalPoint !== '.')
            transformations.push({
                type: TransformationType.DecimalPoint,
                decimalPoint: field.transformation.decimalPoint,
            });

        if (field.transformation?.oneElementArrays) {
            const fields: string[] = [];
            for (const idx of field.transformation.oneElementArrays) {
                fields.push(field.target.path[idx + 1]);
            }
            transformations.push({ type: TransformationType.NestingLevel, target: fields.join(', ') });
        }

        if (transformations.length === 0) transformations.push({ type: TransformationType.None });

        if (field.annotation)
            transformations.push({ type: TransformationType.Annotation, annotation: field.annotation });

        if (field.alias) transformations.push({ type: TransformationType.Alias, alias: field.alias });

        return transformations;
    };

    const getTransformationText = (transformation: TransformationInfo, hasStats = false) => {
        switch (transformation.type) {
            case TransformationType.None:
                return `No transformation ${hasStats ? 'was performed' : 'will be performed'} for this field.`;
            case TransformationType.TypeCast:
                if (transformation.target === 'integer')
                    return `Field values ${hasStats ? 'were' : 'will be'} transformed and truncated to <b>${
                        transformation.target
                    }</b> data type, if required.`;
                return `Field values ${hasStats ? 'were' : 'will be'} transformed to <b>${
                    transformation.target
                }</b> data type.`;
            case TransformationType.DatetimeFormat:
                if (transformation.source === 'infer')
                    return `Field values ${hasStats ? 'were' : 'will be'} transformed to <b>${
                        transformation.target
                    }</b> format.`;
                return `Field values ${hasStats ? 'were' : 'will be'} transformed from <b>${
                    transformation.source
                }</b> format to <b>${transformation.target}</b> format.`;
            case TransformationType.DecimalPoint:
                return `The <b>comma</b> decimal point ${
                    hasStats ? 'was' : 'will be'
                } replaced by <b>dot</b> in the field values.`;
            case TransformationType.ThousandsSeparator:
                return `The <b>${transformation.thousandsSeperator === '.' ? 'dot' : 'comma'}</b> thousands separator ${
                    hasStats ? 'was' : 'will be'
                } <b>removed</b> from the field values.`;
            case TransformationType.Timezone:
                return `Field values ${hasStats ? 'were' : 'will be'} transformed from <b>${
                    transformation.source
                }</b> timezone to <b>${transformation.target}</b> timezone, if there ${
                    hasStats ? 'was' : 'is'
                } no timezone information in the data.`;
            case TransformationType.Unit:
                if (transformation.source === transformation.target)
                    return `Field values of measurement type <b>${
                        transformation.measurementType
                    }</b> are already in measurement unit <b>${transformation.target}</b> so no transformation ${
                        hasStats ? 'was' : 'will be'
                    } performed.`;
                return `Field values of measurement type <b>${transformation.measurementType}</b> ${
                    hasStats ? 'were' : 'will be'
                } transformed from <b>${transformation.source}</b> measurement unit to <b>${
                    transformation.target
                }</b> measurement unit.`;
            case TransformationType.NestingLevel:
                return `The structure of the field ${
                    hasStats ? 'was' : 'will be'
                } adapted to address mapping path inconsistencies, handling any extra arrays under <b>${
                    transformation.target
                }</b> in the path as one-element arrays.`;
            case TransformationType.Annotation:
                return `An annotation <b>${transformation.annotation}</b> ${
                    hasStats ? 'was' : 'will be'
                } added for this field.`;
            case TransformationType.Alias:
                return `An alias <b>${transformation.alias}</b> ${hasStats ? 'was' : 'will be'} added for this field.`;
            default:
                return '';
        }
    };

    const getTransformationIcon = (transformationType: TransformationType) => {
        switch (transformationType) {
            case TransformationType.None:
                return CheckCircleIcon;
            case TransformationType.TypeCast:
            case TransformationType.DatetimeFormat:
            case TransformationType.DecimalPoint:
            case TransformationType.ThousandsSeparator:
                return InformationCircleIcon;
            case TransformationType.Timezone:
            case TransformationType.Unit:
                return CogIcon;
            case TransformationType.NestingLevel:
                return SwitchVerticalIcon;
            case TransformationType.Annotation:
                return AnnotationIcon;
            case TransformationType.Alias:
                return PencilAltIcon;
            default:
                return null;
        }
    };

    return { getTransformations, getTransformationText, getTransformationIcon };
}
