import { AssetType } from '@/modules/asset/constants';

export function useAsset() {
    // #TODO: better rename this file to asset-type.ts
    const assetTypeName = (assetTypeId: number) => {
        switch (assetTypeId) {
            case 1:
                return AssetType.Dataset;
            case 2:
                return AssetType.Result;
            case 3:
                return AssetType.Model;
            default:
                return AssetType.Dataset;
        }
    };

    const assetTypeClass = {
        1: 'bg-green-100 text-green-800',
        2: 'bg-blue-100 text-blue-800',
        3: 'bg-yellow-100 text-yellow-800',
    };

    return { assetTypeName, assetTypeClass };
}
