import { moduleActionContext } from '@/app/store';
import { defineModule } from 'direct-vuex';
import { ethers } from 'ethers';

export interface SharingState {
    provider: any;
    network: any;
    contractAddress: string;
    derivationContractAddress: string;
    proxyAdminAddress: string;
    cart: any[];
    wishListAssetsIds: number[];
}

export const sharingModule = defineModule({
    namespaced: true,
    state: (): SharingState => {
        return {
            provider: null,
            network: null,
            contractAddress: process.env.VUE_APP_ETH_CONTRACT ?? '',
            derivationContractAddress: process.env.VUE_APP_ETH_DERIVATION_CONTRACT ?? '',
            proxyAdminAddress: process.env.VUE_APP_ETH_PROXY_ADMIN ?? '',
            cart: [],
            wishListAssetsIds: [],
        };
    },
    mutations: {
        SET_PROVIDER(state: SharingState, provider: any) {
            state.provider = provider;
        },
        SET_NETWORK(state: SharingState, network: any) {
            state.network = network;
        },
        CLEAR_NETWORK(state: SharingState) {
            state.provider = null;
            state.network = null;
        },
        SET_CART(state: SharingState, cart: any[]) {
            state.cart = cart;
        },
        CLEAR_CART(state: SharingState) {
            state.cart = [];
        },
        ADD_TO_CART(state: SharingState, asset: any) {
            state.cart.push(asset);
        },
        REMOVE_FROM_CART(state: SharingState, assetId: number) {
            state.cart = state.cart.filter((asset: any) => asset.id !== assetId);
        },
        ADD_ASSET_TO_WISH_LIST(state: SharingState, assetId: number) {
            if (!state.wishListAssetsIds.includes(assetId)) {
                state.wishListAssetsIds.push(assetId);
            }
        },
        REMOVE_ASSET_FROM_WISH_LIST(state: SharingState, assetId: number) {
            state.wishListAssetsIds = state.wishListAssetsIds.filter((id: number) => id !== assetId);
        },
        SET_WISH_LIST_ASSETS_IDS(state: SharingState, assetIds: number[]) {
            if (state.wishListAssetsIds.length === 0) {
                state.wishListAssetsIds = assetIds;
            }
        },
    },
    getters: {
        isBlockchainEnabled: () => {
            return !!process.env.VUE_APP_ETH_NODE;
        },
    },
    actions: {
        async loadProviderAndNetwork({ commit }, url) {
            const provider = new ethers.providers.JsonRpcProvider(url);
            commit('SET_PROVIDER', provider);

            if (provider)
                try {
                    const network = await provider.getNetwork();
                    commit('SET_NETWORK', network);
                } catch {
                    commit('SET_NETWORK', null);
                }
            else commit('SET_NETWORK', null);
        },
    },
});

export const marketplaceModuleActionContext = (context: any) => moduleActionContext(context, sharingModule);
