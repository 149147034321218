import { AuthGuard } from '@/modules/auth/router/auth.guard';

export default [
    {
        name: 'alerts',
        path: '/alerts',
        component: () => import(/* webpackChunkName: "alert" */ '@/modules/alert/views/Alerts.vue'),
        meta: { title: 'Alerts' },
        props: true,
        beforeEnter: AuthGuard,
    },
    {
        name: 'alerts:create',
        path: '/alerts/create',
        component: () => import(/* webpackChunkName: "alert" */ '@/modules/alert/views/ConfigureAlert.vue'),
        meta: { title: 'Create Alert', feature: 'alerts' },
        props: true,
        beforeEnter: AuthGuard,
    },
    {
        name: 'alerts:edit',
        path: '/alerts/:id',
        component: () => import(/* webpackChunkName: "alert" */ '@/modules/alert/views/ConfigureAlert.vue'),
        meta: { title: 'Update Alert', feature: 'alerts' },
        props: true,
        beforeEnter: AuthGuard,
    },
];
