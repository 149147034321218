import { FacetFilter, FacetFilterType, useFacetsFilters } from '@/app/composable';
import store from '@/app/store';
import { AssetTypeId } from '@/modules/asset/constants';
import { computed } from '@vue/composition-api';
import { ExpectedUsageText, ReimbursementMethodText } from '../constants';

export function useMarketplaceFacets(get: any) {
    const domains = computed(() => store.getters.dataModel.domains);

    const { valueLabel, reducedFacetsFilters, defaultValue } = useFacetsFilters();

    const marketplaceFacets = computed(
        (): Record<string, FacetFilter> => ({
            assetType: {
                label: 'Type',
                type: FacetFilterType.Checkbox,
                default: defaultValue(get, 'assetType'),
                valueLabel: (id: number) => {
                    switch (id) {
                        case AssetTypeId.Dataset:
                            return 'Dataset';
                        case AssetTypeId.Result:
                            return 'Result';
                        case AssetTypeId.Model:
                            return 'Model';
                        default:
                            return '';
                    }
                },
            },
            domain: {
                label: 'Domain',
                type: FacetFilterType.Checkbox,
                default: defaultValue(get, 'domain'),
                valueLabel: (uid: string) => {
                    const domain = domains.value.find((dom: any) => dom.uid === uid);
                    return domain ? domain.name : uid;
                },
            },
            organisationType: {
                label: 'Data Provider Type',
                type: FacetFilterType.Checkbox,
                default: defaultValue(get, 'organisationType'),
                valueLabel,
            },
            reimbursementMethod: {
                label: 'Reimbursement Method',
                type: FacetFilterType.Checkbox,
                default: defaultValue(get, 'reimbursementMethod'),
                valueLabel: (value: string) => ReimbursementMethodText[value],
            },
            expectedUsage: {
                label: 'Expected Usage',
                type: FacetFilterType.Checkbox,
                default: defaultValue(get, 'expectedUsage'),
                valueLabel: (value: string) => ExpectedUsageText[value],
            },
            offlineRetention: {
                label: 'Offline Retention',
                type: FacetFilterType.Checkbox,
                default: defaultValue(get, 'offlineRetention'),
                valueLabel,
            },
        }),
    );

    const reducedMarketplaceFacets: any = reducedFacetsFilters(marketplaceFacets);

    return { marketplaceFacets, reducedMarketplaceFacets };
}
