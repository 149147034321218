

















































import { UserIcon, DatabaseIcon } from '@vue-hero-icons/solid';
import { PropType, defineComponent } from '@vue/composition-api';
import { Bundle } from '../../types';

export default defineComponent({
    name: 'BundlePreview',
    components: {
        UserIcon,
        DatabaseIcon,
    },
    props: {
        bundle: {
            type: Object as PropType<Bundle>,
            required: true,
        },
    },
});
