
































































import { FormBlock, ConfirmModal } from '@/app/components';
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import { StreamingConnectionDetails } from '../types/streaming.interface';
import { HarvesterSourceType } from '../constants';
import { useFeatureFlags } from '@/app/composable';

export default defineComponent({
    name: 'PubSubMechanismOptions',
    components: {
        FormBlock,
        ValidationProvider,
        ConfirmModal,
    },
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        mechanismTypes: {
            type: Array as PropType<string[]>,
            default: () => [HarvesterSourceType.Kafka, HarvesterSourceType.MQTT],
        },
        mappingStepExists: {
            type: Boolean,
            default: true,
        },
        disablePubSubMechanismChange: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { isEnabled: isFeatureEnabled } = useFeatureFlags();
        const showConfirmModal = ref(false);
        const pubSubMechanism = ref<string | null>(null);

        const pubSubConfiguration = computed({
            get: () => props.configuration,
            set: (newConfiguration: any) => emit('change', newConfiguration),
        });

        const confirmPubSubMechanism = (value: string) => {
            pubSubMechanism.value = value;
            showConfirmModal.value = true;
        };

        const setPubSubMechanism = () => {
            pubSubConfiguration.value.mechanism = pubSubMechanism.value;
            if ([HarvesterSourceType.Kafka, HarvesterSourceType.MQTT].includes(pubSubConfiguration.value.source))
                pubSubConfiguration.value.source = pubSubMechanism.value;
            else
                pubSubConfiguration.value.source =
                    pubSubMechanism.value === HarvesterSourceType.Kafka
                        ? HarvesterSourceType.ExternalKafka
                        : HarvesterSourceType.ExternalMQTT;

            let connectionDetails: StreamingConnectionDetails = {
                topic: '',
                url: '',
                username: null,
                password: null,
            };
            if (
                [HarvesterSourceType.ExternalKafka, HarvesterSourceType.Kafka].includes(
                    pubSubConfiguration.value.source,
                )
            ) {
                connectionDetails = {
                    ...connectionDetails,
                    securityProtocol: null,
                    saslMechanism: null,
                    groupId: null,
                };
            } else if (
                [HarvesterSourceType.ExternalMQTT, HarvesterSourceType.MQTT].includes(pubSubConfiguration.value.source)
            ) {
                connectionDetails = {
                    ...connectionDetails,
                    clientId: null,
                    protocolVersion: '5',
                    topics: [],
                };
                if (HarvesterSourceType.ExternalMQTT === pubSubConfiguration.value.source)
                    connectionDetails.securityProtocol = null;
            }
            pubSubConfiguration.value.connectionDetails = connectionDetails;
            showConfirmModal.value = false;
        };

        const isInternalStreaming = computed(() =>
            [HarvesterSourceType.Streaming, HarvesterSourceType.Kafka, HarvesterSourceType.MQTT].includes(
                pubSubConfiguration.value.source,
            ),
        );

        const isExternalStreaming = computed(() =>
            [
                HarvesterSourceType.ExternalStreaming,
                HarvesterSourceType.ExternalKafka,
                HarvesterSourceType.ExternalMQTT,
            ].includes(pubSubConfiguration.value.source),
        );

        const isKafkaAvailable = computed(
            () =>
                props.mechanismTypes.includes(HarvesterSourceType.Kafka) &&
                ((isInternalStreaming.value && isFeatureEnabled('harvester.platform-kafka')) ||
                    (isExternalStreaming.value && isFeatureEnabled('harvester.kafka'))),
        );

        const isMqttAvailable = computed(
            () =>
                props.mechanismTypes.includes(HarvesterSourceType.MQTT) &&
                ((isInternalStreaming.value && isFeatureEnabled('harvester.platform-mqtt')) ||
                    (isExternalStreaming.value && isFeatureEnabled('harvester.mqtt'))),
        );
        return {
            confirmPubSubMechanism,
            setPubSubMechanism,
            showConfirmModal,
            HarvesterSourceType,
            isKafkaAvailable,
            isMqttAvailable,
            pubSubConfiguration,
        };
    },
});
