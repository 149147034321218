import { secured } from '@/app/config/axios';
import { CreateScheduleDTO, UpdateScheduleDTO } from '../dto';

const endpoint = '/schedules';

export default {
    getSchedules: (uid: string | number) => secured.get(`${endpoint}/Workflow/${uid}`),
    create: (data: CreateScheduleDTO) => secured.post(`${endpoint}`, data),
    update: (uid: string, data: UpdateScheduleDTO) => secured.put(`${endpoint}/${uid}`, data),
    delete: (uid: string) => secured.delete(`${endpoint}/${uid}`),
    activate: (uid: string) => secured.post(`${endpoint}/${uid}/activate`),
    deactivate: (uid: string) => secured.post(`${endpoint}/${uid}/deactivate`),
};
