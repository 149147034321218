













































import { FormBlock } from '@/app/components';
import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import { HarvesterSourceType } from '../constants';

export default defineComponent({
    name: 'RetrievalSettings',
    components: {
        FormBlock,
        ValidationProvider,
    },
    model: {
        prop: 'endDate',
        event: 'update',
    },
    props: {
        title: {
            type: String,
            default: 'Retrieval Settings',
        },
        description: {
            type: String,
            default: 'Until when you want to retrieve data from Kafka topic?',
        },
        endDate: {
            type: [Date, String],
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        running: {
            type: Boolean,
            default: true,
        },
        source: {
            type: String,
        },
    },
    setup(props, { emit }) {
        const today = ref(new Date());
        const streaming = computed(() =>
            [
                HarvesterSourceType.Kafka,
                HarvesterSourceType.ExternalKafka,
                HarvesterSourceType.MQTT,
                HarvesterSourceType.ExternalMQTT,
            ].includes(props.source as HarvesterSourceType),
        );
        const retrievalEndDate: Ref<Date | string | undefined> = computed({
            get: () => props.endDate,
            set: (newDate: Date | string | undefined) => {
                emit('update', newDate);
            },
        });

        return {
            retrievalEndDate,
            streaming,
            today,
        };
    },
});
