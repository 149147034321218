



































import { FormBlock } from '@/app/components';
import { AccessLevel } from '@/modules/access-policy/constants';
import { CalendarIcon, ClockIcon, CollectionIcon } from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ContractDurationType } from '../../constants';
import { Contract } from '../../types';

dayjs.extend(utc);

export default defineComponent({
    name: 'ContractDetails',
    components: {
        FormBlock,
        CalendarIcon,
        ClockIcon,
        CollectionIcon,
    },
    props: {
        contract: {
            type: Object as PropType<Contract>,
            required: true,
        },
    },
    setup(props) {
        const isPublicAsset = computed(() => {
            return props.contract.asset?.accessLevel === AccessLevel.Public;
        });

        const validUntil = computed(() => {
            if (props.contract.duration && props.contract.duration.type !== ContractDurationType.Forever) {
                if (props.contract.updatingContractId && !props.contract.metadata.revise && props.contract.validUntil) {
                    let durationInDays = props.contract.duration.number ?? 0;
                    if (props.contract.duration.type === ContractDurationType.Year) durationInDays *= 365;
                    else if (props.contract.duration.type === ContractDurationType.Month) durationInDays *= 30;

                    const date = new Date(props.contract.validUntil);
                    return new Date(date.setDate(date.getDate() + durationInDays));
                }
                return props.contract.validUntil;
            }
            return null;
        });

        return {
            isPublicAsset,
            dayjs,
            validUntil,
        };
    },
});
