import store from '@/app/store';
import { computed, ref } from '@vue/composition-api';
import { ethers } from 'ethers';

export function useWallet() {
    const progress = ref<number>(0);
    const ethwallet = computed(() => store.getters.organisation.details?.ethwallet);
    const provider = computed(() => store.state.sharing.provider);

    const createRandomWallet = () => {
        return ethers.Wallet.createRandom(ethers.utils.randomBytes(32));
    };

    const importExistingWallet = (privateKey: string) => {
        return new ethers.Wallet(privateKey);
    };

    const walletProgress = (progressValue: number) => {
        progress.value = Math.round(progressValue * 100);
    };

    const connectToWallet = async (password: string) => {
        if (!ethwallet.value) return null;

        const wallet = await ethers.Wallet.fromEncryptedJson(ethwallet.value, password, walletProgress);
        return wallet.connect(provider.value);
    };

    const encryptWallet = (wallet: any, password: string) => {
        return wallet.encrypt(password, walletProgress);
    };

    const getWalletPrivateKey = (wallet: any): string => {
        let walletPrivateKey = wallet.privateKey;
        if (walletPrivateKey.startsWith('0x')) {
            walletPrivateKey = walletPrivateKey.substring(2);
        }
        return walletPrivateKey;
    };

    const getWalletBalanceInEthers = async (wallet: any): Promise<string> => {
        return ethers.utils.formatEther(await wallet.getBalance());
    };

    return {
        createRandomWallet,
        importExistingWallet,
        connectToWallet,
        encryptWallet,
        getWalletPrivateKey,
        getWalletBalanceInEthers,
        progress,
    };
}
