import { useLocations, useModelBrowser } from '@/app/composable';
import router from '@/app/router';
import store from '@/app/store';
import Auth from '@/modules/auth/api/auth';
import { useOrganisation } from '@/modules/organization/composable';
import { UserStatus } from '@/modules/organization/constants';
import Keycloak from 'keycloak-js';
import * as R from 'ramda';
import { Route } from 'vue-router';

let keycloak: any | null = null;
if (
    !R.isNil(process.env.VUE_APP_KEYCLOAK_URL) &&
    !R.isEmpty(process.env.VUE_APP_KEYCLOAK_URL) &&
    !R.isNil(process.env.VUE_APP_KEYCLOAK_REALM) &&
    !R.isEmpty(process.env.VUE_APP_KEYCLOAK_REALM) &&
    !R.isNil(process.env.VUE_APP_KEYCLOAK_CLIENT_ID) &&
    !R.isEmpty(process.env.VUE_APP_KEYCLOAK_CLIENT_ID)
) {
    keycloak = Keycloak({
        url: process.env.VUE_APP_KEYCLOAK_URL,
        realm: process.env.VUE_APP_KEYCLOAK_REALM || '',
        clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID || '',
    });
}

const keycloakService = {
    isEnabled: () => {
        return !R.isNil(keycloak);
    },
    isAuthenticated: () => {
        return keycloak.authenticated && !keycloak.isTokenExpired();
    },
    login: async (to?: Route | null | undefined) => {
        return new Promise((resolve, reject) => {
            if (!R.isNil(keycloak)) {
                keycloak
                    .init({
                        onLoad: 'login-required',
                        promiseType: 'native',
                        checkLoginIframe: false,
                    })
                    .then(async (authenticated: any) => {
                        store.commit.auth.SET_KEYCLOAK_TOKEN(keycloak.idToken);
                        if (authenticated) {
                            await Auth.keycloakLogin(keycloak.subject, keycloak.idToken, keycloak.refreshToken)
                                .then(async (res: { data: { isNewUser: boolean } }) => {
                                    if (res.data.isNewUser) keycloakService.login(to);
                                    store.commit.auth.CLEAR_KEYCLOAK_TOKEN();
                                    const { data } = await Auth.user();
                                    store.commit.auth.SET_USER(data);
                                    // Users that register for the first time in the platform without organization
                                    if (data.status === UserStatus.Pending && !data.organisationId) {
                                        router.push({ name: 'organization-registration' });
                                        // Users that have already register an organization and waiting for verification
                                    } else if (data.status === UserStatus.Pending && data.organisationId) {
                                        router.push({ name: 'unverified-organization' });
                                    } else {
                                        useModelBrowser().initialize();
                                        useLocations().initialize();
                                        if (data.organisationId) useOrganisation(data.organisationId).initialize();
                                        store.dispatch.notificationEngine.fetchNotifications();
                                        store.dispatch.executionVersions.loadVersions();
                                        store.dispatch.executionErrors.loadExecutionErrors();
                                        store.dispatch.dataModel.loadDomains();
                                        if (process.env.VUE_APP_ETH_NODE)
                                            store.dispatch.sharing.loadProviderAndNetwork(process.env.VUE_APP_ETH_NODE);
                                    }
                                    if (to && to.name && data.organisationId && data.status === UserStatus.Active) {
                                        router.push({ name: to.name, params: to.params });
                                    }
                                    resolve(true);
                                })
                                .catch((err) => {
                                    if (err.response?.data?.message || err.response?.data?.statusCode === 403) {
                                        reject(
                                            new Error(
                                                err.response.data.statusCode === 403
                                                    ? 'The account is not activated'
                                                    : err.response.data.message,
                                            ),
                                        );
                                    }
                                    if (err.message) {
                                        reject(new Error(err.message));
                                    }
                                    reject(new Error('Failed to login using keycloak'));
                                });
                        }
                        resolve(true);
                    })
                    .catch(() => {
                        reject(new Error('Authenticated Failed'));
                    });
            }
        });
    },
    logout: async (redirectUri?: string) => {
        if (!keycloak) return;
        keycloak.init({ checkLoginIframe: false, token: store.state.auth.keycloakToken });
        return keycloak.logout({ redirectUri });
    },
    register: async () => {
        if (!R.isNil(keycloak)) {
            keycloak.init({ onLoad: 'login-required', promiseType: 'native' });
            await keycloak.register();
        }
    },
    updateToken: async () => {
        keycloak
            //update token if its near to expire <360 seconds => <6 min
            .updateToken(180)
            .then(function (refreshed: any) {
                if (refreshed) {
                    // Token has been successfully refreshed (future usage)
                } else {
                    // Token is still valid
                }
            })
            .catch(function () {
                new Error('Failed to refresh the token, or the session has expired');
            });
    },
};
export default keycloakService;
