export enum HarvesterSourceType {
    File = 'file',
    Api = 'api',
    InternalApi = 'internalApi',
    Streaming = 'streaming',
    ExternalStreaming = 'externalStreaming',
    Kafka = 'kafka',
    LargeFiles = 'largeFiles',
    ExternalKafka = 'externalKafka',
    MQTT = 'mqtt',
    ExternalMQTT = 'externalMqtt',
}

export enum RetrievalType {
    Immediately = 'immediately',
    Once = 'once',
    Periodic = 'periodic',
    Polling = 'polling',
}
