






































































import { HtmlModal } from '@/app/components';
import SvgImage from '@/app/components/SvgImage.vue';
import { defineComponent, onMounted, ref } from '@vue/composition-api';

export default defineComponent({
    name: 'StepCompletionModal',
    components: { SvgImage, HtmlModal },
    props: {
        job: { type: Object, required: true },
        currentStep: { type: Object, required: true },
        nextStep: Object,
        message: String,
        restartedStep: { type: Boolean, default: false },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    setup() {
        const nextStepRef = ref<any>(null);
        const backRef = ref<any>(null);

        onMounted(() => {
            if (nextStepRef.value) {
                nextStepRef.value.$el.focus();
            } else if (backRef.value) {
                backRef.value.$el.focus();
            }
        });

        return {
            nextStepRef,
            backRef,
        };
    },
});
