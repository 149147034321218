import { offlineRetentionOptions } from '@/modules/asset/config/asset';
import { AccrualPeriodicityInterval, AccrualPeriodicityUnits } from '@/modules/asset/constants';
import { computed } from '@vue/composition-api';
import { ExpectedUsageText } from '../constants';
import { Contract } from '../types';

export function useContractMetadata(contract: Contract) {
    const accrualPeriodicityToSeconda = (accrual: { value: string | null; unit: string }) => {
        if (!accrual?.value) return null;
        const result = Number(accrual.value);
        switch (accrual.unit) {
            case AccrualPeriodicityInterval.Minute:
                return result * 60;
            case AccrualPeriodicityInterval.Hour:
                return result * 3600;
            case AccrualPeriodicityInterval.Day:
                return result * 86400;
            case AccrualPeriodicityInterval.Week:
                return result * 604800;
            case AccrualPeriodicityInterval.Month:
                return result * 2628000;
            default:
                return result;
        }
    };

    const assetSeconds = accrualPeriodicityToSeconda(contract.asset?.metadata?.distribution?.accrualPeriodicity);

    const assetAccrualPeriodicityOptions = computed(() => {
        const singular = contract.metadata.license.expectedUpdates?.value === '1';
        return Object.values(AccrualPeriodicityInterval).reduce((acc: Record<string, string>, key: string) => {
            if (key !== AccrualPeriodicityInterval.NA)
                acc[key] = singular
                    ? AccrualPeriodicityUnits[key].extendedSingular
                    : AccrualPeriodicityUnits[key].extendedPlural;
            else acc[key] = key;
            return acc;
        }, {});
    });

    const getLicensingSchema = computed(() => {
        const schema: any = [];
        const items = [
            {
                type: 'multiselect',
                name: 'expectedUsage',
                label: 'Expected Usage',
                placeholder: 'Select expected usage',
                options: ExpectedUsageText,
                searchable: false,
                multiple: true,
                closeOnSelect: false,
                clearOnSelect: false,
                help:
                    'The specific data asset can be used for data analytics purposes in the following specified locations.',
                helpPosition: 'before',
                validation: 'required',
                validationName: 'expected Usage',
                customLabel: (expectedUsage: string) => ExpectedUsageText[expectedUsage],
                errorBehavior: 'value',
                labelClass: ['pb-1'],
            },
            {
                type: 'checkbox',
                name: 'offlineRetention',
                label: 'Offline Retention',
                options: offlineRetentionOptions,
                help:
                    'An indication whether downloading outside the Energy Data Space (e.g. as a file or through an API) is permitted for the data asset.',
                helpPosition: 'before',
                inputClass: 'inline-flex',
            },
        ];
        Array.prototype.push.apply(schema, items);
        return schema;
    });

    const getExpectedUpdatesSchema = computed(() => {
        const schema: any = [];
        const items: any = [];
        if (
            contract.metadata.license.expectedUpdates?.unit &&
            contract.metadata.license.expectedUpdates.unit !== AccrualPeriodicityInterval.NA
        ) {
            const { unit: assetUnit, value: assetValue } = contract.asset?.metadata?.distribution?.accrualPeriodicity;
            const contractSeconds = accrualPeriodicityToSeconda(contract.metadata.license.expectedUpdates);
            items.push({
                type: 'number',
                name: 'value',
                key: 'expectedUpdatesValue',
                placeholder: 'Enter expected updates',
                helpPosition: 'before',
                validation: `bail|required|greaterThanAsset|lessThanOrEqual:1000`,
                validationRules: {
                    greaterThanAsset: () => (contractSeconds && assetSeconds ? contractSeconds >= assetSeconds : false),
                    lessThanOrEqual: ({ value }: any) => value <= 1000,
                },
                validationMessages: {
                    greaterThanAsset: `Expected updates must be greater or equal to ${assetValue} ${assetUnit}.`,
                    lessThanOrEqual: 'Expected updates must be less than or equal to 1000.',
                },
                validationName: 'expected Updates',
                inputClass: 'form-input',
                errorBehavior: 'value',
                class: 'w-full',
            });
        }
        items.push({
            type: 'select',
            name: 'unit',
            key: 'expectedUpdatesUnit',
            placeholder: 'Select expected updates unit',
            options: assetAccrualPeriodicityOptions.value,
            helpPosition: 'before',
            validation: 'required',
            disabled: contract.asset?.metadata.distribution.accrualPeriodicity?.unit === AccrualPeriodicityInterval.NA,
            validationName: 'expected Updates',
            inputClass: 'form-select',
            errorBehavior: 'value',
            class: 'w-full',
        });
        Array.prototype.push.apply(schema, items);
        return schema;
    });

    return { getLicensingSchema, getExpectedUpdatesSchema };
}
