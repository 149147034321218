





















import { FormBlock } from '@/app/components';
import { defineComponent, ref } from '@vue/composition-api';
import TermsModal from './TermsModal.vue';

export default defineComponent({
    name: 'PlatformTems',
    components: {
        FormBlock,
        TermsModal,
    },
    setup() {
        const showTermsModal = ref<boolean>(false);

        return { showTermsModal };
    },
});
