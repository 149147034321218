











































































































































import { ConfirmButton, Scrollbar, Toggle } from '@/app/components';
import { cronToString } from '@/modules/data-checkin/views/harvester/Schedule.vue';
import { PlusIcon, TrashIcon } from '@vue-hero-icons/outline';
import { XCircleIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';

export default defineComponent({
    name: 'ViewSchedules',
    components: {
        Scrollbar,
        Toggle,
        ConfirmButton,
        TrashIcon,
        PlusIcon,
        XCircleIcon,
    },
    props: {
        schedules: {
            type: Array,
            required: true,
        },
        selectedSchedule: {
            type: Object,
            default: null,
        },
        scheduleEditorOpen: {
            type: Boolean,
            default: false,
        },
        fixedHeight: {
            type: String,
            default: 'h-56',
        },
        minimalLayout: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        retrievalType: {
            type: String,
            default: 'periodic',
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        schedulesPerFrequency: {
            type: Number,
            default: null,
        },
        loadingSchedule: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        disabledMessage: {
            type: String,
            default: null,
        },
    },
    setup(props, { emit }) {
        const retrieveOnce = computed(() => props.retrievalType && props.retrievalType === 'once');
        const hoveredItem = ref(null);
        const disableScheduleToggling = computed(() => props.disabled || props.readOnly);
        const modifiedSchedules = computed(() => {
            const cronSchedules: any = [];

            props.schedules.forEach((s: any) => {
                const cronSchedule = {
                    id: s.id,
                    schedule: `${R.isNil(s.minute) ? '*' : s.minute} ${R.isNil(s.hour) ? '*' : s.hour} ${
                        R.isNil(s.dayOfMonth) ? '*' : s.dayOfMonth
                    } ${R.isNil(s.month) ? '*' : s.month} ${R.isNil(s.dayOfWeek) ? '*' : s.dayOfWeek}`,
                    startDate: s.startDate,
                    endDate: s.endDate,
                    isEnabled: s.isEnabled,
                    frequency: s.frequency,
                };

                cronSchedules.push(cronSchedule);
            });

            return cronSchedules;
        });

        const changeStatus = (idx: number) => {
            emit('change-status', props.schedules[idx]);
        };

        const deleteSchedule = (idx: number) => {
            emit('delete-schedule', props.schedules[idx]);
        };

        const editSchedule = (idx: number) => {
            const schedule: any = props.schedules[idx];
            const scheduleToBeEdited = {
                ...modifiedSchedules.value[idx],
                startDate: schedule.startDate,
                endDate: schedule.endDate,
            };
            emit('edit-schedule', scheduleToBeEdited);
        };

        const selectedScheduleIdx = computed(() => {
            if (props.selectedSchedule) {
                return props.schedules.findIndex((obj: any) => obj.id === props.selectedSchedule.id);
            }
            return null;
        });

        const setToggleBgColour = (idx: string | number) => {
            if (props.minimalLayout) {
                return 'bg-white';
            }
            if (hoveredItem.value === idx) return 'bg-neutral-200';
            if (selectedScheduleIdx.value === idx) return 'bg-primary-100';
            return 'bg-neutral-100';
        };

        const setToggleAdditionalInputClass = (enabled: boolean) => {
            return !enabled ? 'bg-neutral-300' : '';
        };

        const enableAddNewScheduleBtn = computed(
            () =>
                !props.scheduleEditorOpen &&
                props.multiple &&
                !props.readOnly &&
                !props.loadingSchedule &&
                (!props.schedulesPerFrequency || props.schedules.length < props.schedulesPerFrequency),
        );

        return {
            cronToString,
            modifiedSchedules,
            emit,
            deleteSchedule,
            changeStatus,
            editSchedule,
            hoveredItem,
            selectedScheduleIdx,
            setToggleBgColour,
            setToggleAdditionalInputClass,
            retrieveOnce,
            disableScheduleToggling,
            enableAddNewScheduleBtn,
        };
    },
});
