var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-between"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.fieldPaths.hidden.length > 0 ? _vm.getHiddenTooltip(_vm.fieldPaths.all) : '',
            classes: 'max-w-lg break-all',
        }),expression:"{\n            content: fieldPaths.hidden.length > 0 ? getHiddenTooltip(fieldPaths.all) : '',\n            classes: 'max-w-lg break-all',\n        }"}],ref:"pathContainerRef",staticClass:"flex items-center flex-auto mt-2 space-x-1 text-sm text-neutral-600"},[_vm._l((_vm.fieldPaths.visible),function(level,idx){return _c('div',{key:("sp-" + idx),staticClass:"flex flex-row space-x-1"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.isBasePath(level, idx) ? 'Field was selected as base path' : null),expression:"isBasePath(level, idx) ? 'Field was selected as base path' : null"}],staticClass:"max-w-lg break-all line-clamp-1",class:{ 'line-through': _vm.isBasePath(level, idx) }},[_vm._v(" "+_vm._s(level)+" ")]),(idx < _vm.fieldPaths.visible.length - 1)?_c('svg',{staticClass:"flex flex-grow-0 flex-shrink-0 w-5 h-5 text-neutral-400",attrs:{"viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z","clip-rule":"evenodd"}})]):(idx !== 0 && !_vm.field.id)?_c('button',{attrs:{"type":"button ml-1 p-1"},on:{"click":function($event){_vm.trimPath();
                    $event.stopImmediatePropagation();}}},[_c('svg',{staticClass:"flex flex-grow-0 flex-shrink-0 w-4 h-4 text-neutral-400",attrs:{"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M6 18L18 6M6 6l12 12"}})])]):_vm._e()])}),(_vm.fieldPaths.all.length > 0)?_c('div',{staticClass:"flex flex-row space-x-1"},[_c('svg',{ref:"pathSeparatorRef",staticClass:"flex flex-grow-0 flex-shrink-0 w-5 h-5 text-neutral-400",class:{
                    'text-neutral-400': _vm.field.title && _vm.fieldPaths.visible.length > 0,
                    'text-white': !(_vm.field.title && _vm.fieldPaths.visible.length > 0),
                },attrs:{"viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z","clip-rule":"evenodd"}})]),_c('div',{ref:"fieldTitleRef",staticClass:"max-w-lg break-all text-neutral-700 line-clamp-1"},[(_vm.showAlternateNaming && _vm.alternateName && _vm.alternateName.title)?_c('span',[_vm._v(" "+_vm._s(_vm.alternateName.title[0])+" "+_vm._s(_vm.alternateName.title.length > 1 ? '[1]' : '')+" ")]):_c('span',[_vm._v(_vm._s(_vm.field.title))])])]):_vm._e()],2),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }