export type TTriggerType = TriggerConditionType | TriggerEntityConditionType;

export enum TriggerConditionType {
    DataLoaded = 'data-loaded',
    PipelineExecuted = 'pipeline-executed',
}

export enum TriggerEntityConditionType {
    DataCheckin = 'job-executed',
    Workflow = 'workflow-executed',
    Dataset = 'dataset-data-loaded',
    Result = 'result-data-loaded',
}

export enum TriggerEntityType {
    Asset = 'Asset',
    Workflow = 'Workflow',
    DataCheckinJob = 'DataCheckinJob',
}

export enum TriggerPipelineStatus {
    Success = 'Success',
    Failure = 'Failure',
}

export enum TriggerDataType {
    Double = 'double',
    Integer = 'integer',
}

export enum TriggerCheckEvaluation {
    same = 'same',
    any = 'any',
}

export const TRIGGER_MAP = {
    [TriggerEntityConditionType.Dataset]: TriggerConditionType.DataLoaded,
    [TriggerEntityConditionType.Result]: TriggerConditionType.DataLoaded,
    [TriggerEntityConditionType.DataCheckin]: TriggerConditionType.PipelineExecuted,
    [TriggerEntityConditionType.Workflow]: TriggerConditionType.PipelineExecuted,
};

export const TRIGGER_PIPELINE_STATUS_MAP = {
    [TriggerPipelineStatus.Success]: 'completed',
    [TriggerPipelineStatus.Failure]: 'failed',
};

export const TRIGGER_OPERATORS_MAP = {
    '==': 'Equal',
    '!=': 'Not equal',
    '>': 'Greater than',
    '<': 'Less than',
    '>=': 'Greater than or equal',
    '<=': 'Less than or equal',
};
