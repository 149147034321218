export enum ExpectedUsage {
    Centralized = 'centralized',
    DirectDownload = 'directDownload',
    OnPremise = 'onPremise',
}

export const ExpectedUsageText = {
    [ExpectedUsage.Centralized]: 'In the Centralized Data Spaces',
    // [ExpectedUsage.DirectDownload]: 'Through Direct Download in the Data Consumer’s Data Space',
    // [ExpectedUsage.OnPremise]: 'Only in the Data Provider’s On-Premise Data Space',
};

export const ExpectedUsageShortText = {
    [ExpectedUsage.Centralized]: 'Centralized',
    [ExpectedUsage.DirectDownload]: 'Direct Download',
    [ExpectedUsage.OnPremise]: 'On-Premise',
};
