






























































































































/* eslint-disable no-param-reassign */
import { FormBlock, InputErrorIcon } from '@/app/components';
import { maxLengthValidator, maxValueValidator, minValueValidator, requiredValidator } from '@/app/validators';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { ValidationProvider, extend } from 'vee-validate';
import BlockItem from './BlockItem.vue';

extend('required', requiredValidator);
extend('min_value', minValueValidator);
extend('max_value', maxValueValidator);
extend('max', maxLengthValidator);

export default defineComponent({
    name: 'RequestDetails',
    components: {
        FormBlock,
        BlockItem,
        ValidationProvider,
        InputErrorIcon,
    },
    model: {
        prop: 'contract',
    },
    props: {
        contract: {
            type: Object,
            required: true,
        },
        updatingContract: {
            type: Object,
            default: null,
        },
        isNewContract: {
            type: Boolean,
            default: false,
        },
        isRequest: {
            type: Boolean,
            default: false,
        },
        reviseContract: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        if (R.isNil(props.contract.duration.number) || R.isEmpty(props.contract.duration.number))
            props.contract.duration.number = 1;
        const durationType = ref<string>('day');
        const contractDuration = ref<any>(R.clone(props.contract.duration));

        const initialDuration = computed(() =>
            props.updatingContract ? props.updatingContract.duration : contractDuration.value,
        );

        const changeDuration = (type: string) => {
            if (type === 'forever') {
                props.contract.duration.type = 'forever';
                if (props.contract.duration.number.length === 0) props.contract.duration.number = 1;
            } else {
                props.contract.duration.type = durationType.value;
            }
        };

        const changeDurationType = (event: any) => {
            props.contract.duration.type = event.target.value;
        };

        if (props.contract.updatingContractId && (props.isNewContract || props.reviseContract)) {
            if (props.isNewContract && props.contract.duration && props.contract.duration.type !== 'forever') {
                props.contract.duration.number = 0;
            }
            props.contract.message = null;
        }

        return {
            changeDuration,
            durationType,
            changeDurationType,
            initialDuration,
        };
    },
});
