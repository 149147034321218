







































































































































import { PlayIcon, TableIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, ref } from '@vue/composition-api';
import ConfirmModal from '../../../app/components/ConfirmModal.vue';

export default defineComponent({
    name: 'WizardActions',
    props: {
        backRoute: {
            type: String,
            default: 'home',
        },
        hasChanges: {
            type: Boolean,
            default: false,
        },
        hasSave: {
            type: Boolean,
            default: true,
        },
        canSave: {
            type: Boolean,
            default: false,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        canFinalize: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        canRestart: {
            type: Boolean,
            default: false,
        },
        hasSampleRun: {
            type: Boolean,
            default: false,
        },
        canExecuteSampleRun: {
            type: Boolean,
            default: false,
        },
        processedSample: {
            type: Array,
            default: null,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    components: { ConfirmModal, PlayIcon, TableIcon },
    setup(props: any, { root, emit }: { root: any; emit: any }) {
        const showConfirmCancelModal = ref(false);

        const saveTooltip = computed(() => {
            if (!props.canSave) {
                return 'Not all requirements are satisfied in order to be able to save.';
            }
            if (!props.hasChanges) {
                return 'There are no changes to save!';
            }

            return 'Save changes made!';
        });
        const finalizeTooltip = computed(() => {
            if (!props.canFinalize) {
                return 'Not all requirements are satisfied in order to be able to finalise.';
            }

            return 'Finalise the configuration of this step!';
        });

        const save = () => {
            emit('save');
        };

        const finalize = () => {
            emit('finalize');
        };

        const cancel = () => {
            // TODO: Confirm discard changes
            root.$router.push({ name: props.backRoute, query: JSON.parse(props.queryParams) });
            emit('cancel');
        };

        return { showConfirmCancelModal, saveTooltip, finalizeTooltip, finalize, save, cancel };
    },
});
