export enum BlockchainMessage {
    Connecting = 'Connecting to blockchain...',
    Transaction = 'Waiting for transaction to be mined and confirmed. This might take a while...',
    Database = 'Saving to SYNERGIES database...',
    Creating = 'Creating Smart Contract...',
    Rejecting = 'Rejecting Smart Contract...',
    Accepting = 'Accepting Smart Contract...',
    Updated = 'Writing updated Smart Contract...',
    Activating = 'Activating Smart Contract...',
}
