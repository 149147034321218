import store from '@/app/store';
import Keycloak from '@/modules/auth/api/keycloak';
import axios from 'axios';

const baseURL = '/api/';

const authCheck = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

const secured = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

const plain = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

authCheck.interceptors.request.use(async (config) => {
    const token = store.getters.auth.getKeycloakToken;

    if (token) {
        if (!config.headers) {
            // eslint-disable-next-line no-param-reassign
            config.headers = {};
        }
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

secured.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            if (!store.getters.auth.isAuthenticated) return;
            store.commit.auth.CLEAR_USER();
            store.commit.sharing.CLEAR_NETWORK();

            if (Keycloak.isEnabled()) {
                Keycloak.login();
            }
        } else {
            throw error;
        }
    },
);

plain.interceptors.request.use(async (config) => {
    const token = store.getters.auth.getKeycloakToken;

    if (token) {
        if (!config.headers) {
            // eslint-disable-next-line no-param-reassign
            config.headers = {};
        }
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export { authCheck, plain, secured };
