import { useQuery } from '@/app/composable';
import { UserRoles } from '@/app/constants';
import store from '@/app/store';
import { computed } from '@vue/composition-api';
import * as R from 'ramda';
import GET_COMPLETE_ORGANIZATION from '../graphql/getCompleteOrganization.graphql';
import { Department, OrganisationDetails } from '../store';

export function useOrganisation(id: Number) {
    const initialize = () => {
        const isLegalRepresentative = computed(() => store.getters.auth.hasRole(UserRoles.LegalRepresentative));
        const { result, onResult } = useQuery(
            GET_COMPLETE_ORGANIZATION,
            { id, isLegalRepresentative: isLegalRepresentative.value },
            { fetchPolicy: 'no-cache' },
        );

        onResult(() => {
            if (result.value?.organisation) {
                const departments = result.value.organisation.departments;
                const details = R.omit(['users', 'departments'], result.value.organisation);
                details.departments = departments.map((dep: Department) => R.omit(['users'], dep));
                store.dispatch.organisation.setOrganisationDetails(details as OrganisationDetails);
                store.dispatch.organisation.setUsers(result.value.organisation.users);
                store.dispatch.organisation.setDepartments(departments);
            }
        });
    };
    return { initialize };
}
