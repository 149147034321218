import { AuthGuard } from '@/modules/auth/router/auth.guard';

export default [
    {
        name: 'data-space-management',
        path: '/data-space-management',
        component: () =>
            import(
                /* webpackChunkName: "data-space-management" */ '@/modules/data-space-management/views/DataSpaceManagement.vue'
            ),
        meta: { title: 'Data Space Management' },
        beforeEnter: AuthGuard,
    },
];
