






































































































































































































import { useFilters } from '@/app/composable';
import { ContractsAPI, ContractTransactionsAPI, DerivationContractsAPI } from '@/modules/sharing/api';
import { useBlockchain, useWallet } from '@/modules/sharing/composable';
import {
    BundleStatus,
    ContractAction,
    ContractDurationType,
    ContractStatus,
    ReimbursementMethod,
    WalletAction,
} from '@/modules/sharing/constants';
import * as Sentry from '@sentry/browser';
import { useAxios } from '@/app/composable';
import {
    ArrowsExpandIcon,
    CashIcon,
    CheckIcon,
    DocumentTextIcon,
    PencilIcon,
    PlusIcon,
    RefreshIcon,
    XIcon,
} from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { Bundle, Contract, ContractDuration } from '../../types';
import LoadingModal from '../wallet/LoadingModal.vue';
import WalletModal from '../wallet/WalletModal.vue';
import ModalPaymentDetails from './ModalPaymentDetails.vue';

export default defineComponent({
    name: 'ContractActions',
    components: {
        WalletModal,
        LoadingModal,
        ModalPaymentDetails,
        ArrowsExpandIcon,
        CashIcon,
        CheckIcon,
        DocumentTextIcon,
        PencilIcon,
        PlusIcon,
        RefreshIcon,
        XIcon,
    },
    props: {
        isDerivationContract: {
            type: Boolean,
            default: false,
        },
        contract: {
            type: Object as PropType<Contract>,
            default: null,
        },
        bundle: {
            type: Object as PropType<Bundle>,
            default: null,
        },
        isConsumer: {
            type: Boolean,
            default: false,
        },
        isLegalRepresentative: {
            type: Boolean,
            default: false,
        },
        negotiating: {
            type: Boolean,
            default: false,
        },
        acceptedRequest: {
            type: Boolean,
            default: false,
        },
        invalid: {
            type: Boolean,
            default: false,
        },
        isNegotiationCreator: {
            type: Boolean,
            default: false,
        },
        totalCosts: {
            type: Object,
            default: () => ({}),
        },
        isBundle: {
            type: Boolean,
            default: false,
        },
        isNewContract: {
            type: Boolean,
            default: false,
        },
        canExtendOrRevise: {
            type: Boolean,
            default: false,
        },
        contractAction: {
            type: String as PropType<ContractAction>,
            default: null,
        },
        activePendingContracts: {
            type: Array,
            default: () => [],
        },
        noPayment: {
            type: Boolean,
            default: false,
        },
        previousDuration: {
            type: Object as PropType<ContractDuration>,
            default: null,
        },
    },
    setup(props, { root, emit }) {
        const API = props.isDerivationContract ? DerivationContractsAPI : ContractsAPI;

        const { exec, loading } = useAxios(true);
        const {
            message: modalMessage,
            createContract,
            negotiate,
            counterOffer,
            accept,
            reject,
            acceptOffer,
            rejectOffer,
            rejectBundle,
            payContractsAndMarkAsActive,
            contractActive,
        } = useBlockchain();
        const { getWalletBalanceInEthers } = useWallet();
        const { formatDecimals, sortByKeyNames } = useFilters();

        const isBlockchainEnabled = ref<boolean>(!!process.env.VUE_APP_ETH_NODE);
        const showUnlockWalletModal = ref<boolean>(false);
        const showLoadingModal = ref<boolean>(false);
        const action = ref<Function | null>(null);

        const missingFields = computed(
            () =>
                props.contract.asset?.assetTypeId === 1 &&
                props.contract.asset.structure.type !== 'other' &&
                props.contract.fields.length === 0,
        );

        const flattenObject = (obj: any) => {
            const result = {};
            const objKeys = Object.keys(obj);

            for (let i = 0; i < objKeys.length; i += 1) {
                const key = objKeys[i];
                if (typeof obj[key] === 'object' && obj[key] !== null && Object.keys(obj[key]).length) {
                    const flatObject = flattenObject(obj[key]);
                    const flatObjKeys = Object.keys(flatObject);
                    for (let j = 0; j < flatObjKeys.length; j += 1) {
                        const flatKey = flatObjKeys[j];
                        result[`${key}.${flatKey}`] = flatObject[flatKey];
                    }
                } else {
                    result[key] = obj[key];
                }
            }
            return result;
        };

        const formatFields = (payload: any) => {
            const { fields } = props.isDerivationContract ? payload.contract : payload;
            const sortedFields = R.sort(R.ascend(R.prop('uid')) as any, fields);
            const flattenFields = sortedFields.map((field: any) => flattenObject(field));
            return flattenFields.map((field: any) => sortByKeyNames(field));
        };

        const revise = async (wallet: any) => {
            showLoadingModal.value = true;
            emit('close-negotiation');
            const payload: Contract = R.clone(props.contract);
            payload.status = props.isConsumer ? ContractStatus.Negotiate : ContractStatus.Draft;
            if (isBlockchainEnabled.value) {
                const blockchainPayload = R.clone(payload);
                blockchainPayload.fields = formatFields(payload);
                try {
                    payload.ethaddress = await createContract(wallet, blockchainPayload, null);
                } catch (e) {
                    const error: any = e;
                    Sentry.captureException(error && error.error && error.error.message ? error.error.message : error);
                    (root as any).$toastr.e('Writing to blockchain failed', 'Error');
                    showLoadingModal.value = false;
                }
            }
            if (showLoadingModal.value) {
                exec(ContractsAPI.revise(payload.id, payload))
                    .then(() => {
                        (root as any).$toastr.s('Contract revision sent', 'Success');
                        emit(props.isBundle ? 'reload-bundle' : 'back');
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Writing to database failed', 'Error');
                    })
                    .finally(() => {
                        showLoadingModal.value = false;
                    });
            }
        };

        const confirmPayment = async (wallet: any) => {
            showLoadingModal.value = true;
            if (isBlockchainEnabled.value) {
                let pendingTransactionId = null;
                try {
                    const resContract = await exec(ContractsAPI.makeActive(props.contract.id, true));
                    pendingTransactionId = resContract?.data?.pendingTransactionId;
                } catch (error) {
                    (root as any).$toastr.e('Writing transaction failed', 'Error');
                    showLoadingModal.value = false;
                    return;
                }
                try {
                    await contractActive(wallet, props.contract.ethaddress as string, pendingTransactionId);
                } catch (e) {
                    const error: any = e;
                    Sentry.captureException(error && error.error && error.error.message ? error.error.message : error);
                    await exec(ContractTransactionsAPI.update(pendingTransactionId, { status: 'failed' }));
                    (root as any).$toastr.e('Writing to blockchain failed', 'Error');
                    showLoadingModal.value = false;
                }
            }
            if (showLoadingModal.value) {
                exec(ContractsAPI.makeActive(props.contract.id))
                    .then((res: any) => {
                        (root as any).$toastr.s('Contract activated!', 'Success');
                        if (props.contract.updatingContractId) {
                            emit(props.isBundle ? 'reload-bundle' : 'back');
                        } else {
                            emit('update-contract', res.data);
                            emit('scroll-up');
                        }
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Writing to database failed', 'Error');
                    })
                    .finally(() => {
                        showLoadingModal.value = false;
                    });
            }
        };

        const negotiateContract = async (wallet: any, payload: any, message: string) => {
            if (isBlockchainEnabled.value) {
                let pendingTransactionId = null;
                try {
                    const resContract = await exec(API.negotiate(props.contract.id, payload, true));
                    pendingTransactionId = resContract?.data?.pendingTransactionId;
                } catch (error) {
                    (root as any).$toastr.e('Writing transaction failed', 'Error');
                    showLoadingModal.value = false;
                    return;
                }
                try {
                    if (props.isDerivationContract)
                        // await negotiateDerivationContract(wallet, payload, pendingTransactionId);
                        console.log();
                    else await negotiate(wallet, payload, pendingTransactionId);
                } catch (e) {
                    const error: any = e;
                    Sentry.captureException(error && error.error && error.error.message ? error.error.message : error);
                    await exec(ContractTransactionsAPI.update(pendingTransactionId, { status: 'failed' }));
                    (root as any).$toastr.e('Writing to blockchain failed', 'Error');
                    showLoadingModal.value = false;
                }
            }
            if (showLoadingModal.value) {
                exec(API.negotiate(props.contract.id, payload))
                    .then((res: any) => {
                        (root as any).$toastr.s(message, 'Success');
                        emit('update-contract', res.data);
                        emit('scroll-up');
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Writing to database failed', 'Error');
                    })
                    .finally(() => {
                        showLoadingModal.value = false;
                    });
            }
        };

        const counterOfferContract = async (wallet: any, payload: any, message: string) => {
            if (isBlockchainEnabled.value) {
                let pendingTransactionId = null;
                try {
                    const resContract = await exec(API.counterOffer(props.contract.id, payload, true));
                    pendingTransactionId = resContract?.data?.pendingTransactionId;
                } catch (error) {
                    (root as any).$toastr.e('Writing transaction failed', 'Error');
                    showLoadingModal.value = false;
                    return;
                }
                try {
                    if (props.isDerivationContract) console.log();
                    // await counterDerivationContractOffer(wallet, payload, pendingTransactionId);
                    else await counterOffer(wallet, payload, pendingTransactionId);
                } catch (e) {
                    const error: any = e;
                    Sentry.captureException(error && error.error && error.error.message ? error.error.message : error);
                    await exec(ContractTransactionsAPI.update(pendingTransactionId, { status: 'failed' }));
                    (root as any).$toastr.e('Writing to blockchain failed', 'Error');
                    showLoadingModal.value = false;
                }
            }
            if (showLoadingModal.value) {
                exec(API.counterOffer(props.contract.id, payload))
                    .then((res: any) => {
                        emit('update-contract', res.data);
                        emit('scroll-up');
                        (root as any).$toastr.s(message, 'Success');
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Writing to database failed', 'Error');
                    })
                    .finally(() => {
                        showLoadingModal.value = false;
                    });
            }
        };

        const create = async (wallet: any, payload: any, message: string) => {
            if (isBlockchainEnabled.value) {
                if (!props.isDerivationContract && !props.bundle && payload.bundleId) {
                    showLoadingModal.value = false;
                    return;
                }
                const blockchainPayload = R.clone(payload);
                blockchainPayload.fields = formatFields(payload);
                let pendingTransactionId = null;
                try {
                    const resContract = await exec(API.signContract(props.contract.id, payload, true));
                    pendingTransactionId = resContract?.data?.pendingTransactionId;
                } catch (error) {
                    (root as any).$toastr.e('Writing transaction failed', 'Error');
                    showLoadingModal.value = false;
                    return;
                }
                try {
                    // eslint-disable-next-line no-param-reassign
                    payload.ethaddress = props.isDerivationContract
                        ? // ? await createDerivationContract(wallet, payload, pendingTransactionId)
                          console.log()
                        : await createContract(
                              wallet,
                              blockchainPayload,
                              props.bundle && props.bundle.status !== BundleStatus.Draft ? null : props.bundle,
                              pendingTransactionId,
                              props.previousDuration,
                          );
                } catch (e) {
                    const error: any = e;
                    Sentry.captureException(error && error.error && error.error.message ? error.error.message : error);
                    await exec(ContractTransactionsAPI.update(pendingTransactionId, { status: 'failed' }));
                    (root as any).$toastr.e('Writing to blockchain failed', 'Error');
                    showLoadingModal.value = false;
                }
            }
            if (showLoadingModal.value) {
                exec(API.signContract(props.contract.id, payload))
                    .then((res: any) => {
                        (root as any).$toastr.s(message, 'Success');
                        emit('update-contract', res.data);
                        emit('update-current-step');
                        emit('scroll-up');
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Writing to database failed', 'Error');
                    })
                    .finally(() => {
                        showLoadingModal.value = false;
                    });
            }
        };

        const signContract = async (wallet: any) => {
            showLoadingModal.value = true;
            emit('close-negotiation');
            let newStatus = ContractStatus.Draft;
            let message = 'Draft contract created!';
            if (props.contract.status !== ContractStatus.Request) {
                newStatus = ContractStatus.Negotiate;
                message = 'Contract negotiation sent!';
            }
            const payload: Contract = R.clone(props.contract);
            payload.status = newStatus;
            if (newStatus === ContractStatus.Draft) {
                await create(wallet, payload, message);
            } else {
                if (props.isConsumer) {
                    await negotiateContract(wallet, payload, message);
                } else {
                    await counterOfferContract(wallet, payload, message);
                }
            }
        };

        const acceptContract = async (wallet: any) => {
            showLoadingModal.value = true;
            if (isBlockchainEnabled.value) {
                let pendingTransactionId = null;
                try {
                    const resContract = await exec(API.acceptContract(props.contract.id, true));
                    pendingTransactionId = resContract?.data?.pendingTransactionId;
                } catch (error) {
                    (root as any).$toastr.e('Writing transaction failed', 'Error');
                    showLoadingModal.value = false;
                    return;
                }
                try {
                    await accept(
                        wallet,
                        props.contract.ethaddress as string,
                        pendingTransactionId,
                        props.isDerivationContract,
                    );
                } catch (e) {
                    const error: any = e;
                    Sentry.captureException(error && error.error && error.error.message ? error.error.message : error);
                    await exec(ContractTransactionsAPI.update(pendingTransactionId, { status: 'failed' }));
                    (root as any).$toastr.e('Writing to blockchain failed', 'Error');
                    showLoadingModal.value = false;
                }
            }
            if (showLoadingModal.value) {
                exec(API.acceptContract(props.contract.id))
                    .then((res: any) => {
                        if (props.isDerivationContract) (root as any).$toastr.s('Contract activated!', 'Success');
                        else (root as any).$toastr.s('Contract accepted!', 'Success');
                        emit('update-contract', res.data);
                        emit('update-current-step');
                        emit('scroll-up');
                        if (props.isBundle) emit('check-signed-contracts');
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Writing to database failed', 'Error');
                    })
                    .finally(() => {
                        showLoadingModal.value = false;
                    });
            }
        };

        const acceptCounterOffer = async (wallet: any) => {
            showLoadingModal.value = true;
            if (isBlockchainEnabled.value) {
                let pendingTransactionId = null;
                try {
                    const resContract = await exec(API.acceptCounterOffer(props.contract.id, true));
                    pendingTransactionId = resContract?.data?.pendingTransactionId;
                } catch (error) {
                    (root as any).$toastr.e('Writing transaction failed', 'Error');
                    showLoadingModal.value = false;
                    return;
                }
                try {
                    await acceptOffer(
                        wallet,
                        props.contract.ethaddress as string,
                        pendingTransactionId,
                        props.isDerivationContract,
                    );
                } catch (e) {
                    const error: any = e;
                    Sentry.captureException(error && error.error && error.error.message ? error.error.message : error);
                    await exec(ContractTransactionsAPI.update(pendingTransactionId, { status: 'failed' }));
                    (root as any).$toastr.e('Writing to blockchain failed', 'Error');
                    showLoadingModal.value = false;
                }
            }
            if (showLoadingModal.value) {
                exec(API.acceptCounterOffer(props.contract.id))
                    .then((res: any) => {
                        (root as any).$toastr.s('Contract accepted!', 'Success');
                        emit('update-contract', res.data);
                        emit('update-current-step');
                        emit('scroll-up');
                        if (props.isBundle) emit('check-signed-contracts');
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Writing to database failed', 'Error');
                    })
                    .finally(() => {
                        showLoadingModal.value = false;
                    });
            }
        };

        const rejectContract = async (wallet: any) => {
            showLoadingModal.value = true;
            if (isBlockchainEnabled.value) {
                let pendingTransactionId = null;
                try {
                    const resContract = await exec(API.rejectContract(props.contract.id, true));
                    pendingTransactionId = resContract?.data?.pendingTransactionId;
                } catch (error) {
                    (root as any).$toastr.e('Writing transaction failed', 'Error');
                    showLoadingModal.value = false;
                    return;
                }
                try {
                    await reject(
                        wallet,
                        props.contract.ethaddress as string,
                        pendingTransactionId,
                        props.isDerivationContract,
                    );
                } catch (e) {
                    const error: any = e;
                    Sentry.captureException(error && error.error && error.error.message ? error.error.message : error);
                    await exec(ContractTransactionsAPI.update(pendingTransactionId, { status: 'failed' }));
                    (root as any).$toastr.e('Writing to blockchain failed', 'Error');
                    showLoadingModal.value = false;
                }
            }
            if (showLoadingModal.value) {
                exec(API.rejectContract(props.contract.id))
                    .then((res: any) => {
                        (root as any).$toastr.s('Contract rejected!', 'Success');
                        if (props.isBundle) {
                            emit('update-contract', res.data);
                            emit('reject-bundle');
                        } else if (props.contract.updatingContractId) emit('back');
                        else {
                            emit('update-contract', res.data);
                            emit('scroll-up');
                        }
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Writing to database failed', 'Error');
                    })
                    .finally(() => {
                        showLoadingModal.value = false;
                    });
            }
        };

        const rejectCounterOffer = async (wallet: any) => {
            showLoadingModal.value = true;
            if (isBlockchainEnabled.value) {
                let pendingTransactionId = null;
                try {
                    const resContract = await exec(API.rejectCounterOffer(props.contract.id, true));
                    pendingTransactionId = resContract?.data?.pendingTransactionId;
                } catch (error) {
                    (root as any).$toastr.e('Writing transaction failed', 'Error');
                    showLoadingModal.value = false;
                    return;
                }
                try {
                    await rejectOffer(
                        wallet,
                        props.contract.ethaddress as string,
                        pendingTransactionId,
                        props.isDerivationContract,
                    );
                } catch (e) {
                    const error: any = e;
                    Sentry.captureException(error && error.error && error.error.message ? error.error.message : error);
                    await exec(ContractTransactionsAPI.update(pendingTransactionId, { status: 'failed' }));
                    (root as any).$toastr.e('Writing to blockchain failed', 'Error');
                    showLoadingModal.value = false;
                }
            }
            if (showLoadingModal.value) {
                exec(API.rejectCounterOffer(props.contract.id))
                    .then((res: any) => {
                        (root as any).$toastr.s('Contract rejected!', 'Success');
                        if (props.isBundle) {
                            emit('update-contract', res.data);
                            emit('reject-bundle');
                        } else if (props.contract.updatingContractId) emit('back');
                        else {
                            emit('update-contract', res.data);
                            emit('scroll-up');
                        }
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Writing to database failed', 'Error');
                    })
                    .finally(() => {
                        showLoadingModal.value = false;
                    });
            }
        };

        const payToActivate = async (wallet: any) => {
            showLoadingModal.value = true;
            if (isBlockchainEnabled.value) {
                const balance = await getWalletBalanceInEthers(wallet);
                let totalCost = props.totalCosts[props.contract.id].totalCostWithCommissionFee;
                totalCost = formatDecimals(totalCost, 4);
                if (props.contract.metadata?.revise) {
                    totalCost = 0;
                }
                let pendingTransactionId = null;
                try {
                    const resContract = await exec(ContractsAPI.makeActive(props.contract.id, true));
                    pendingTransactionId = resContract?.data?.pendingTransactionId;
                } catch (error) {
                    (root as any).$toastr.e('Writing transaction failed', 'Error');
                    showLoadingModal.value = false;
                    return;
                }
                try {
                    if (Number(balance) < totalCost) {
                        (root as any).$toastr.e('Insufficient wallet balance', 'Error');
                        await exec(ContractTransactionsAPI.update(pendingTransactionId, { status: 'failed' }));
                        showLoadingModal.value = false;
                    } else {
                        await payContractsAndMarkAsActive(
                            wallet,
                            props.contract.ethaddress as string,
                            totalCost,
                            pendingTransactionId,
                        );
                    }
                } catch (e) {
                    const error: any = e;
                    Sentry.captureException(error && error.error && error.error.message ? error.error.message : error);
                    await exec(ContractTransactionsAPI.update(pendingTransactionId, { status: 'failed' }));
                    (root as any).$toastr.e('Writing to blockchain failed', 'Error');
                    showLoadingModal.value = false;
                }
            }
            if (showLoadingModal.value) {
                exec(ContractsAPI.makeActive(props.contract.id))
                    .then((res: any) => {
                        (root as any).$toastr.s('Contract activated!', 'Success');
                        if (props.contract.updatingContractId) {
                            emit(props.isBundle ? 'reload-bundle' : 'back');
                        } else {
                            emit('update-contract', res.data);
                            emit('scroll-up');
                        }
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Writing to database failed', 'Error');
                    })
                    .finally(() => {
                        showLoadingModal.value = false;
                    });
            }
        };

        const confirmAction = (actionMethod: Function) => {
            action.value = actionMethod;
            if (isBlockchainEnabled.value) showUnlockWalletModal.value = true;
            else actionMethod();
        };

        const declineRequest = async (wallet: any = null) => {
            showLoadingModal.value = true;
            if (props.isBundle) {
                if (isBlockchainEnabled.value) {
                    let pendingTransactionId = null;
                    try {
                        const resContract = await exec(API.declineRequest(props.contract.id, true));
                        pendingTransactionId = resContract?.data?.pendingTransactionId;
                    } catch (error) {
                        (root as any).$toastr.e('Writing transaction failed', 'Error');
                        showLoadingModal.value = false;
                        return;
                    }
                    try {
                        await rejectBundle(wallet, props.bundle.ethaddress as string, pendingTransactionId);
                    } catch (e) {
                        const error: any = e;
                        Sentry.captureException(
                            error && error.error && error.error.message ? error.error.message : error,
                        );
                        await exec(ContractTransactionsAPI.update(pendingTransactionId, { status: 'failed' }));
                        (root as any).$toastr.e('Writing to blockchain failed', 'Error');
                        showLoadingModal.value = false;
                    }
                }
            }
            if (!props.isBundle || showLoadingModal.value) {
                exec(API.declineRequest(props.contract.id))
                    .then((res: any) => {
                        (root as any).$toastr.s('Contract request declined!', 'Success');
                        if (props.isBundle) {
                            emit('update-contract', res.data);
                            emit('reject-bundle');
                        } else if (props.contract.updatingContractId) emit('back');
                        else {
                            emit('update-contract', res.data);
                            emit('scroll-up');
                        }
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Action failed.', 'Error');
                    })
                    .finally(() => {
                        showLoadingModal.value = false;
                    });
            }
        };

        const reactivate = async () => {
            const payload: Contract = R.clone(props.contract);
            payload.duration.number = payload.duration.type !== 'forever' ? Number(payload.duration.number) : null;
            exec(ContractsAPI.reactivate(payload.id, payload))
                .then((res: any) => {
                    (root as any).$toastr.s(`Contract request sent!`, 'Success');
                    emit('requests-sent');
                    emit('update-contract', res.data);
                    emit('update-current-step');
                    emit('scroll-up');
                })
                .catch(() => {
                    (root as any).$toastr.e(`Contract request failed to sent.`, 'Error');
                })
                .finally(() => {
                    showLoadingModal.value = false;
                });
        };

        const extend = async () => {
            const payload: Contract = R.clone(props.contract);
            payload.duration.number = payload.duration.type !== 'forever' ? Number(payload.duration.number) : null;
            exec(ContractsAPI.extend(payload.id, payload))
                .then(() => {
                    emit('requests-sent');
                    (root as any).$toastr.s(`Contract request sent!`, 'Success');
                    emit(props.isBundle ? 'reload-bundle' : 'back');
                })
                .catch(() => {
                    (root as any).$toastr.e(`Contract request failed to sent.`, 'Error');
                })
                .finally(() => {
                    showLoadingModal.value = false;
                });
        };

        const submit = async () => {
            if (props.contractAction === ContractAction.Reactivate) {
                await reactivate();
            } else if (props.contractAction === ContractAction.Extend) {
                await extend();
            } else {
                const ctr: Contract = R.clone(props.contract);
                if (ctr.duration.type !== ContractDurationType.Forever) {
                    ctr.duration.number = Number(ctr.duration.number);
                } else {
                    ctr.duration.number = null;
                }
                const payload: any = {
                    contracts: [
                        {
                            duration: ctr.duration,
                            message: ctr.message,
                            metadata: ctr.metadata,
                            status: ContractStatus.Request,
                            consumerOrgId: ctr.consumerOrgId,
                            providerOrgId: ctr.providerOrgId,
                            assetId: ctr.assetId,
                            fields: ctr.fields,
                        },
                    ],
                    isBundle: false,
                };
                exec(ContractsAPI.create(payload))
                    .then((res: any) => {
                        const contract = res.data?.contracts[0];
                        emit('update-contract', contract);
                        emit('requests-sent');
                        emit('scroll-up');
                        (root as any).$toastr.s(`Contract request sent!`, 'Success');
                    })
                    .catch(() => {
                        (root as any).$toastr.e(`Contract request failed to sent.`, 'Error');
                    });
            }
        };

        const paymentDetails = computed(() =>
            action.value === payToActivate && !props.noPayment ? props.contract.costDetails : null,
        );

        const canReactivateContract = computed(
            () =>
                props.isLegalRepresentative &&
                props.isConsumer &&
                props.contract.status === ContractStatus.Expired &&
                props.contractAction !== ContractAction.Reactivate &&
                !props.activePendingContracts.find((item: any) => item.assetId === props.contract.assetId) &&
                !props.activePendingContracts.find(
                    (item: any) =>
                        props.contract.barteringAssetId && item.barteringAssetId === props.contract.barteringAssetId,
                ),
        );

        return {
            ReimbursementMethod,
            ContractAction,
            modalMessage,
            isBlockchainEnabled,
            missingFields,
            ContractStatus,
            loading,
            showUnlockWalletModal,
            showLoadingModal,
            action,
            paymentDetails,
            canReactivateContract,
            WalletAction,
            confirmAction,
            revise,
            signContract,
            acceptContract,
            acceptCounterOffer,
            rejectContract,
            rejectCounterOffer,
            payToActivate,
            declineRequest,
            submit,
            confirmPayment,
        };
    },
});
