

























































































































































































































































































































import { LoaderCircle, Scrollbar } from '@/app/components';
import { useFilters } from '@/app/composable';
import store from '@/app/store';
import { AccessLevel } from '@/modules/access-policy/constants/access-levels.constants';
import { useAsset } from '@/modules/asset/composable/asset';
import { useAssetExtentMetadata } from '@/modules/asset/composable/asset-extent-metadata';
import { useWishList } from '@/modules/asset/composable/wish-list';
import { Asset } from '@/modules/asset/types';
import { useAxios } from '@/app/composable';
import { ArrowCircleLeftIcon } from '@vue-hero-icons/outline';
import { ClipboardListIcon, CubeTransparentIcon } from '@vue-hero-icons/solid';
import { PropType, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { ContractsAPI } from '../../api';
import { useCart } from '../../composable';
import RelatedAssetCard from './RelatedAssetCard.vue';

export default defineComponent({
    name: 'AssetDetails',
    components: {
        LoaderCircle,
        ClipboardListIcon,
        CubeTransparentIcon,
        OrbitSpinner,
        RelatedAssetCard,
        ArrowCircleLeftIcon,
        Scrollbar,
    },
    props: {
        asset: {
            type: Object as PropType<Asset>,
            default: null,
        },
        showTabs: {
            type: Boolean,
            default: true,
        },
        activePendingContracts: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, { root, emit }) {
        const assetDetailsTabs = [
            { key: 'overview', title: 'Overview', icon: ClipboardListIcon },
            { key: 'related', title: 'Related', icon: CubeTransparentIcon },
        ];

        const { exec, loading } = useAxios(true);

        const { formatLargeNumber, formatDate, capitalise } = useFilters();
        const { assetTypeClass, assetTypeName } = useAsset();

        const { cartAssetIds, addToCart, removeFromCart, cart, cartLimit } = useCart();
        const {
            wishListBtnName,
            isInWishList,
            viewedAsset,
            loading: changeWishListState,
            toggleWishListState,
            removeAssetFromWishList,
            fetchWishListAssetIds,
        } = useWishList(root);

        const isBlockchainEnabled = computed(() => store.getters.sharing.isBlockchainEnabled);
        const user = computed(() => store.state.auth.user);
        const ethNetwork = computed(() => store.state.sharing.network);
        const hasWallet = computed(() => store.getters.organisation.hasWallet);
        const isAssetPublic = computed(() => props.asset && props.asset.accessLevel === AccessLevel.Public);
        const cartLimitReached = computed(() => cart.value.length >= cartLimit);

        const activePendingContract = computed(() => {
            if (props.asset) {
                const contract: any = props.activePendingContracts.find(
                    (item: any) => item.assetId === props.asset.id || item.barteringAssetId === props.asset.id,
                );
                return contract ?? null;
            }
            return null;
        });

        const belongsToUserOrOrganisation = computed(() => {
            if (props.asset && user.value) {
                if (user.value.organisationId && props.asset.organisation)
                    return user.value.organisationId === props.asset.organisation.id;
                return user.value.id === props.asset.createdBy.id;
            }
            return true;
        });

        const hasOrganisation = ref<boolean>(!!user.value.organisationId);
        const acquiringAsset = ref<boolean>(false);
        const loadedRelatedAssets = ref<any[]>([]);
        const activeAssetTab = ref<string>('overview');
        const selectedRelatedAsset = ref<any>(null);
        const temporalCoverage = ref<string | null>(null);
        const spatialCoverage = ref<string | null>(null);

        const acquireAsset = (id: number) => {
            acquiringAsset.value = true;
            const payload = {
                isBundle: false,
                contracts: [{ fields: [], duration: { type: 'forever', number: null }, assetId: id }],
            };
            exec(ContractsAPI.create(payload))
                .then(() => {
                    props.activePendingContracts.push(id);
                    acquiringAsset.value = false;
                    (root as any).$toastr.s('Asset acquired!', 'Success');

                    if (isInWishList.value) {
                        removeAssetFromWishList(id);
                    }
                    emit('reload-active-pending');
                })
                .catch(() => {
                    acquiringAsset.value = false;
                    (root as any).$toastr.e('Asset acquisition failed.', 'Error');
                });
        };

        const buyAsset = (id: number) => {
            root.$router.push({ name: 'contract-details', params: { type: 'create', id: `${id}` } });
        };

        // computed property for related assets
        const relatedAssets = computed(() => {
            if (!loadedRelatedAssets.value || loadedRelatedAssets.value.length === 0) return [];

            // retrieve the data if they exist, from the already fetched related assets
            let finalResults = loadedRelatedAssets.value.find((el: any) => el.assetId === props.asset?.id)?.data;

            if (!finalResults || finalResults.length === 0) return [];

            // transform data
            finalResults = finalResults.map((el: any) => {
                const newItem = { ...el?._source };

                newItem.accessibility = newItem.metadata?.distribution?.accessibility || [];
                newItem.license = newItem.metadata?.license;
                newItem.primaryConcept = newItem.structure?.primaryConcept;

                newItem.temporalCoverage = newItem.metadata.extent?.temporalCoverage;
                newItem.spatialCoverage = newItem.metadata.extent?.spatialCoverage;

                newItem.model = newItem.metadata.model || {};

                return newItem;
            });

            return finalResults;
        });

        // fetch the related assets for current viewed asset
        const fetchRelatedAssets = () => {
            // loading.value = true;
            // const assetId = props.asset?.id;
            // if (assetId) {
            //     exec(SearchAPI.getRelatedAssets(assetId))
            //         .then((response: any) => {
            //             loadedRelatedAssets.value.push({
            //                 assetId,
            //                 data: response.data,
            //             });
            //             loading.value = false;
            //         })
            //         .catch(() => {
            //             loading.value = false;
            //         });
            // }
        };

        const changeAssetTab = (tab?: string) => {
            if (tab) activeAssetTab.value = tab;

            if (activeAssetTab.value === 'overview') selectedRelatedAsset.value = null;
            else if (
                activeAssetTab.value === 'related' &&
                !loadedRelatedAssets.value.some((el: any) => el.assetId === props.asset?.id)
            )
                // check if related assets are already loaded so that we won't load them again
                fetchRelatedAssets();
        };

        const showSelectedRelatedAsset = (asset: any) => {
            selectedRelatedAsset.value = asset;
        };

        const closeRelatedAssetView = () => {
            selectedRelatedAsset.value = null;
        };

        watch(
            () => props.asset,
            () => {
                activeAssetTab.value = 'overview';
                selectedRelatedAsset.value = null;
                viewedAsset.value = props.asset;
                const { spatialCoverage: sc, temporalCoverage: tc } = useAssetExtentMetadata(viewedAsset);
                spatialCoverage.value = sc.value;
                temporalCoverage.value = tc.value;
                fetchWishListAssetIds();
            },
        );

        return {
            formatDate,
            formatLargeNumber,
            buyAsset,
            acquireAsset,
            ethNetwork,
            hasWallet,
            belongsToUserOrOrganisation,
            hasOrganisation,
            isBlockchainEnabled,
            temporalCoverage,
            spatialCoverage,
            addToCart,
            removeFromCart,
            cartAssetIds,
            assetTypeClass,
            assetTypeName,
            acquiringAsset,
            changeWishListState,
            toggleWishListState,
            wishListBtnName,
            assetDetailsTabs,
            activeAssetTab,
            loading,
            changeAssetTab,
            fetchRelatedAssets,
            loadedRelatedAssets,
            relatedAssets,
            showSelectedRelatedAsset,
            selectedRelatedAsset,
            closeRelatedAssetView,
            capitalise,
            isAssetPublic,
            activePendingContract,
            cartLimitReached,
            isInWishList,
        };
    },
});
