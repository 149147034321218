












































































































































































import { FormBlock } from '@/app/components';
import { useFilters } from '@/app/composable';
import { DocumentIcon } from '@vue-hero-icons/outline';
import { ExclamationIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { ValidationProvider } from 'vee-validate';
import { useHarvester } from '../composable';
import { SAMPLE_LIMIT } from '../constants';

export default defineComponent({
    name: 'SampleUpload',
    components: {
        FormBlock,
        ValidationProvider,
        DocumentIcon,
        ExclamationIcon,
    },
    props: {
        sampleTypes: {
            type: Array,
            default: () => ['csv', 'json', 'xml', 'parquet', 'other'],
        },
        configuration: {
            type: Object,
            required: true,
        },
        mappingStepExists: {
            type: Boolean,
            default: true,
        },
        disableFileTypeChange: {
            type: Boolean,
            default: true,
        },
        skipSampleRun: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        processedSample: {
            type: Array,
            required: false,
        },
        files: {
            type: Object,
            required: true,
        },
        completed: {
            type: Boolean,
            default: true,
        },
        isOnUpdate: {
            type: Boolean,
            default: false,
        },
        sample: {
            type: [Array, Object],
            required: false,
        },
        canUploadMore: {
            type: Boolean,
            default: false,
        },
        isOnPremise: {
            type: Boolean,
            default: false,
        },
        showFormatSelections: {
            type: Boolean,
            default: true,
        },
        formatTitle: {
            type: String,
            default: 'Format',
        },
        formatDescription: {
            type: String,
            default: 'Select the format of the file(s) you will use',
        },
        sampleTitle: {
            type: String,
            default: 'Sample Upload',
        },
        sampleDescription: {
            type: String,
            default: 'Upload a sample of your data to be used in next steps.',
        },
    },
    setup(props, { root, emit }) {
        const { formatBytes } = useFilters();
        const sampleFile = computed(() => props.files.sample);
        const sampleCroppedMessage = computed(() => (props.configuration.isSampleCropped ? 'cropped, ' : ''));
        const fileTypeRef = computed(() => props.configuration.fileType);
        const sampleUploadRef = ref<any>(null);

        const {
            parseJSON,
            checkInvalidXML,
            parseXML,
            acceptedFiles,
            parseCSV,
            invalidFormat,
            emptyFile,
            valPercentage,
            noCSVData,
            duplicateHeaders,
            checkInvalidCSV,
            checkInvalidParquet,
            showValidationBar,
        } = useHarvester(root, emit, fileTypeRef);

        const disableBrowseSample = computed(() => props.completed || props.isOnUpdate);

        const getSampleCSV = async (file: any) => {
            if (!file) return;
            const results = await parseCSV(file, false, 50);
            emit('set-configuration-params', R.pick(['delimiter', 'linebreak', 'fields'], results.meta));
            emit('sample-uploaded', results.data);
        };

        const sampleUploaded = async (event: any) => {
            const file = event.target.files[0];
            if (file.size > SAMPLE_LIMIT) {
                (root as any).$toastr.e(`The sample file should not exceed 500KB.`, 'Sample file too large!');
                emit('files-changed', { sample: null });
                emit('clear-error-alert');
                await sampleUploadRef.value.validate();
                return;
            }
            emit('set-configuration-params', R.pick(['uploadQueryId'], props.configuration.params));
            await emit('sample-cropped', false);
            await emit('clear-files');

            switch (props.configuration.fileType) {
                case 'csv':
                    emit('files-changed', { sample: null });
                    await checkInvalidCSV(file);
                    if (invalidFormat.value || duplicateHeaders.value || emptyFile.value) {
                        const message = emptyFile.value
                            ? 'Empty CSV file!'
                            : invalidFormat.value
                            ? 'Invalid CSV format!'
                            : 'Duplicate columns have been detected in the file!';
                        (root as any).$toastr.e(message, 'Error');
                        emit(
                            'set-error-alert',
                            duplicateHeaders.value
                                ? `Duplicate columns have been detected in the Sample file "${file.name}"`
                                : null,
                        );
                    } else if (noCSVData.value) {
                        (root as any).$toastr.e('The CSV file contains no data!', 'No Data!');
                    } else {
                        await emit('files-changed', { sample: file });
                        await getSampleCSV(file);
                        emit('clear-error-alert');
                        emit('clear-actual-file');
                    }
                    break;
                case 'json':
                    await emit('files-changed', { sample: file });
                    await parseJSON(file);
                    emit('clear-actual-file');
                    break;
                case 'xml':
                    await checkInvalidXML(file);
                    if (emptyFile.value) {
                        emit('files-changed', { sample: null });
                        (root as any).$toastr.e('Empty XML file!', 'Error');
                    } else if (invalidFormat.value) {
                        emit('files-changed', { sample: null });
                        (root as any).$toastr.e('Invalid XML format!', 'Error');
                    } else {
                        await emit('files-changed', { sample: file });
                        await parseXML(file);
                        emit('clear-actual-file');
                    }
                    break;
                case 'parquet':
                    await checkInvalidParquet(file);
                    if (emptyFile.value) {
                        emit('files-changed', { sample: null });
                        (root as any).$toastr.e('Empty parquet file!', 'Error');
                    } else {
                        await emit('files-changed', { sample: file });
                        await getSampleParquet(file);
                        emit('clear-actual-file');
                    }
                    break;

                default:
                // Do nothing
            }
            await sampleUploadRef.value.validate();
        };

        const getSampleParquet = async (file: any) => {
            if (!file) return;
            emit('sample-uploaded', props.processedSample);
        };

        const clearFiles = () => {
            emit('files-changed', { sample: null, data: null });
            emit('set-configuration-params', {});
            emit('clear-error-alert');
            noCSVData.value = false;
        };

        const sampleMayBeCroppedMessage = computed(() => {
            if (props.configuration.fileType && props.configuration.fileType !== 'csv') {
                return '- Sample may be cropped if required. Ensure that a small sample contains all necessary fields.';
            }
            return null;
        });

        return {
            sampleUploadRef,
            formatBytes,
            disableBrowseSample,
            showValidationBar,
            valPercentage,
            noCSVData,
            sampleUploaded,
            clearFiles,
            sampleFile,
            acceptedFiles,
            sampleMayBeCroppedMessage,
            sampleCroppedMessage,
        };
    },
});
