import { Status } from '@/modules/data-model/constants';
import { AxiosRequestConfig } from 'axios';

const predictorURL = '/api/predictor';
const dataModelURL = '/api/data-model';

export default {
    all: () => ({ method: 'GET', url: `${dataModelURL}/full` } as AxiosRequestConfig),
    mappingPrediction: (data: any) => ({ method: 'POST', url: predictorURL, data } as AxiosRequestConfig),
    domains: () => ({ method: 'GET', url: dataModelURL } as AxiosRequestConfig),
    domainOfConcept: (id: number) =>
        ({ method: 'GET', url: `${dataModelURL}/concept/${id}/domain` } as AxiosRequestConfig),
    concepts: (id: number, status?: Status) =>
        ({
            method: 'GET',
            url: `${dataModelURL}/domains/${id}/concepts`,
            params: status ? { status } : {},
        } as AxiosRequestConfig),
    conceptNames: (id: number) =>
        ({ method: 'GET', url: `${dataModelURL}/domains/${id}/concept-names` } as AxiosRequestConfig),
    completeModel: (id: number) => ({ method: 'GET', url: `${dataModelURL}/domains/${id}` } as AxiosRequestConfig),
    conceptTree: (id: number) => ({ method: 'GET', url: `${dataModelURL}/domains/${id}` } as AxiosRequestConfig),
    conceptsUids: (data: any) => ({ method: 'POST', url: `${dataModelURL}/concepts/uid`, data } as AxiosRequestConfig),
    createSuggestion: (data: any) =>
        ({ method: 'POST', url: `${dataModelURL}/suggestions`, data } as AxiosRequestConfig),
    domainLatestMapping: (id: number) =>
        ({ method: 'GET', url: `${dataModelURL}/domains/${id}/mapping-latest` } as AxiosRequestConfig),
};
