import store from '@/app/store';
import { computed, Ref } from '@vue/composition-api';
import dayjs from 'dayjs';
import { Asset } from '../types';

export function useAssetExtentMetadata(asset?: Ref<Asset | undefined>) {
    const locationOptions = computed(() => store.getters.locations.locationOptions);

    const spatialCoverageCountries = computed(() => {
        if (
            asset?.value?.metadata?.extent?.spatialCoverage?.values &&
            locationOptions.value &&
            locationOptions.value.length
        ) {
            const { values } = asset.value?.metadata.extent.spatialCoverage;
            const global = locationOptions.value[0];

            if (values.includes(global.id)) return [global.label];

            if (global.children)
                return global.children.reduce((acc: string[], continent: any) => {
                    if (values.includes(continent.id)) acc.push(continent.label);
                    else
                        continent.children.forEach((country: any) => {
                            if (values.includes(country.id)) acc.push(country.label);
                        });
                    return acc;
                }, []);
        }
        return [];
    });

    const spatialCoverage = computed(() => {
        if (asset?.value?.metadata?.extent?.spatialCoverage?.unit) {
            const { unit, coordinates, values, value } = asset.value.metadata.extent.spatialCoverage;
            switch (unit) {
                case 'Specific Continent/Countries':
                    return spatialCoverageCountries.value.join(', ');
                case 'Exact Location':
                    return `Latitude: ${coordinates.lat}, Longitude: ${coordinates.lon}`;
                case 'Calculated based on data':
                    return values?.length > 0 ? values.join(', ') : 'N/A';
                case 'Not applicable':
                    return unit;
                default:
                    return value;
            }
        }
        return null;
    });

    const spatialCoverageDescription = computed(() => {
        if (asset?.value?.metadata?.extent?.spatialCoverage?.unit) {
            const { unit } = asset.value.metadata.extent.spatialCoverage;
            switch (unit) {
                case null:
                case 'Not applicable':
                    return 'The location/area the data refer to or were collected from; either defined directly (e.g. geographical area) or indirectly (i.e. place of interest or an activity that is the subject of the collection).';
                case 'Specific Continent/Countries':
                    return 'The spatial coverage of the asset, in terms of countries to which the asset refers.';
                case 'Exact Location':
                    return 'The spatial coverage of the asset, in terms of exact location(s) to which the asset refers.';
                case 'Calculated based on data':
                    return 'The spatial coverage of the asset as extracted from a specific concept/field in the data.';
                default:
                    return `The spatial coverage of the asset, in terms of ${unit
                        .substring(8, unit.length)
                        .toLowerCase()}(s) to which the asset refers.`;
            }
        }
        return '';
    });

    const hiddenSpatialValuesCount = computed(() => {
        if (asset?.value?.metadata?.extent?.spatialCoverage?.unit === 'Calculated based on data') {
            const { values, count } = asset.value.metadata.extent.spatialCoverage;
            return count - values?.length;
        }
        return 0;
    });

    const temporalCoverage = computed(() => {
        if (asset?.value?.metadata?.extent?.temporalCoverage?.unit) {
            const { unit, min, max, field, value } = asset.value.metadata.extent.temporalCoverage;
            switch (unit) {
                case 'Time Period':
                    return `${dayjs(min).format('MMM D, YYYY')} ~ ${dayjs(max).format('MMM D, YYYY')}`;
                case 'Single Date':
                    return dayjs(min).format('MMM D, YYYY');
                case 'Calculated based on data':
                    if (min && max && field) {
                        let temporalField = null;
                        if (asset?.value.structure?.temporalFields) {
                            temporalField = asset.value.structure.temporalFields.find(
                                (tField: any) => tField.uid === field.uid && tField.name === field.name,
                            );
                        } else {
                            temporalField = asset.value.schema?.find(
                                (tField: any) =>
                                    tField.uid === field.uid &&
                                    [...tField.path, tField.field].join('__') === field.name,
                            );
                        }
                        if (temporalField) {
                            const fieldType = temporalField.type;
                            switch (fieldType) {
                                case 'date':
                                    return `${dayjs(min).format('MMM D, YYYY')} ~ ${dayjs(max).format('MMM D, YYYY')}`;
                                case 'time':
                                    return `${dayjs(`1970-01-01T${min}`).utc().format('HH:mm:ss UTC')} ~ ${dayjs(
                                        `1970-01-01T${max}`,
                                    )
                                        .utc()
                                        .format('HH:mm:ss UTC')}`;
                                case 'datetime':
                                    return `${dayjs(min).utc().format('MMM D, YYYY HH:mm:ss UTC')} ~ ${dayjs(max)
                                        .utc()
                                        .format('MMM D, YYYY HH:mm:ss UTC')}`;
                                default:
                                    return 'N/A';
                            }
                        }
                    }
                    return 'N/A';
                case 'Not applicable':
                    return unit;
                default:
                    return `${value} ${unit}`;
            }
        }
        return null;
    });

    const temporalCoverageDescription = computed(() => {
        if (asset?.value?.metadata?.extent?.temporalCoverage?.unit) {
            const { unit } = asset.value.metadata.extent.temporalCoverage;
            switch (unit) {
                case null:
                case 'Not applicable':
                    return 'The time period during which the data were collected or the time period the data are referring to.';
                case 'Time Period':
                case 'Single Date':
                    return 'A named period, date, or date range that the asset covers.';
                case 'Calculated based on data':
                    return 'The period that the asset covers is extracted from a specific concept/field in the data.';
                default:
                    return `The number of indicative ${unit.toLowerCase()} to which the asset refers.`;
            }
        }
        return '';
    });

    return {
        spatialCoverage,
        temporalCoverage,
        spatialCoverageDescription,
        temporalCoverageDescription,
        hiddenSpatialValuesCount,
    };
}
