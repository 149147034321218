import { Status } from '@/modules/data-model/constants';
import { Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { FieldConfiguration } from '../views/mapping/mapping.types';
import { useMappingFields } from './mapping-fields';

export function useMappingMigration(sample: Ref<any[]>, rootConcept: Ref<any>, basePath: Ref<string[] | undefined>) {
    const { createFieldConfiguration, initEmptyField } = useMappingFields(sample, rootConcept, basePath);
    /**
     * Migrates an unfinished mapping to a newer version of the model
     *
     * @param domain - The mapped model
     * @param concept - The mapped concept
     * @param fields - The mapped fields
     * @param idMappings - Mappings from old ids to new concepts
     * @param mainConceptDeprecated - Whether the parent concept is deprecated or not
     */
    const migrate = (
        domain: any,
        concept: any,
        fields: FieldConfiguration[],
        idMappings: any,
        mainConceptDeprecated = false,
    ): { fields: any[]; domain: any; concept: any; deprecatedFields: string[] } => {
        // reconstructing the mapped fields
        const mappedFields = [];
        const deprecatedFields: string[] = [];
        for (let idx = 0; idx < fields.length; idx++) {
            const field: FieldConfiguration = fields[idx];

            // if the parent is not deprecated and we have a target id
            // then we recreate the field with the new concept object
            if (!mainConceptDeprecated) {
                if (field.target.id && field.target.title) {
                    const newConcept = idMappings[field.target.id];
                    if (newConcept && newConcept.status !== Status.Deprecated) {
                        // if concept exists and is not deprecated then recreate clean configuration
                        mappedFields.push(createFieldConfiguration(field, newConcept, field.prediction, true));
                    } else {
                        // otherwise clean it up
                        deprecatedFields.push(field.target.title);
                        mappedFields.push(initEmptyField(field.source));
                    }
                } else {
                    // otherwise we store a blank field
                    mappedFields.push(initEmptyField(field.source));
                }
            }
        }

        let newDomain = R.clone(domain);
        let newConcept = R.clone(concept);
        // replace old fields with new ones
        if (!mainConceptDeprecated) {
            // if concept is not deprecated
            // replace the domain and concept information
            newDomain.name = idMappings[domain.id].name;
            newDomain.id = idMappings[domain.id].id;
            newConcept.name = idMappings[concept.id].name;
            newConcept.id = idMappings[concept.id].id;
        } else {
            newDomain = null;
            newConcept = null;
        }

        return {
            fields: mappedFields,
            domain: newDomain,
            concept: newConcept,
            deprecatedFields,
        };
    };

    return {
        migrate,
    };
}
