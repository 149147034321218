import { AxiosRequestConfig } from 'axios';

const endpoint = '/api/runner';

export default {
    all: () => ({ method: 'GET', url: `${endpoint}` } as AxiosRequestConfig),
    generateToken: (data: any) => ({ method: 'POST', url: `${endpoint}`, data } as AxiosRequestConfig),
    getLatestBinaries: () => ({ method: 'GET', url: `${endpoint}/binaries` } as AxiosRequestConfig),
    delete: (id: string) => ({ method: 'DELETE', url: `${endpoint}/${id}` } as AxiosRequestConfig),
    retrieve: (id: string) => ({ method: 'GET', url: `${endpoint}/${id}` } as AxiosRequestConfig),
};
