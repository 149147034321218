export enum StatusCode {
    Configuration = 'configuration',
    Idle = 'idle',
    Queued = 'queued',
    Running = 'running',
    Failed = 'failed',
    Cancelled = 'cancelled',
    Completed = 'completed',
    Deprecated = 'deprecated',
    Update = 'update',
    Suspended = 'suspended',
}
