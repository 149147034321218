export enum ContractStatus {
    Active = 'active',
    Draft = 'draft',
    Negotiate = 'negotiate',
    Request = 'request',
    Declined = 'declined',
    Signed = 'signed',
    Rejected = 'rejected',
    Expired = 'expired',
    Withdrawn = 'withdrawn',
    Revise = 'revise', // draft + revise=true
    Reactivate = 'reactivate', // draft + reactivate=true
    Extend = 'extend', // draft + extend=true
}

export enum BundleStatus {
    Draft = 'draft',
    Rejected = 'rejected',
    Active = 'active',
    Expired = 'expired',
}

export enum DerivationStatus {
    Request = 'request',
    Declined = 'declined',
    Draft = 'draft',
    Rejected = 'rejected',
    Negotiate = 'negotiate',
    Active = 'active',
    Inactive = 'inactive',
    Withdrawn = 'withdrawn',
}

export enum Currency {
    Euro = 'EUR',
    Dollar = 'USD',
    Pound = 'GBP',
    Ether = 'ETH',
}

export enum ContractTypes {
    Acquisition = 'acquisition',
    Derivation = 'derivation',
    Public = 'public',
}

export const currencySymbol = {
    [Currency.Euro]: '€',
    [Currency.Dollar]: '$',
    [Currency.Pound]: '£',
};

export enum ContractDurationType {
    Day = 'day',
    Month = 'month',
    Year = 'year',
    Forever = 'forever',
}

export enum ContractAction {
    Extend = 'extend',
    Revise = 'revise',
    Reactivate = 'reactivate',
}

export enum ContractTransactionStatus {
    Completed = 'completed',
    Failed = 'failed',
    Pending = 'pending',
}
