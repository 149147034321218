import { useAxios } from '@/app/composable';
import { Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { ModelAPI } from '../api';
import { FieldConfiguration, MappingConfig } from '../views/mapping/mapping.types';

export function useMappingPrediction(configuration: Ref<MappingConfig>, basePath: Ref<string[] | undefined>) {
    const { loading, exec, cancel } = useAxios(true);

    /**
     * Prepares the payload to be used with the predictor backend
     * @param fields The list of the selected fields
     * @param domain The domain id for this mapping
     * @param standard The standard (if any) the data conforms to
     * @param concept The concept id these fields belong to
     */
    const getPredictionPayload = (fields: Readonly<any[]>, domain: number, standard: any, concept: number) => ({
        metadata: {
            domain,
            standard,
            concept,
        },
        fields,
    });

    const getSourcePath = (path: string[]) => {
        if (basePath?.value?.length && path.join('.').replaceAll('[]', '').startsWith(basePath.value.join('.')))
            return path.map((item: string, idx: number) => {
                const clearItem = item.replaceAll('[]', '');
                if (basePath?.value && idx < basePath.value.length && basePath.value[idx] === clearItem)
                    return clearItem;
                return item;
            });
        return path;
    };

    const predict = async (fields: any[], conceptId: number | null | undefined, domainId?: number) => {
        if (!configuration?.value?.domain) throw Error('No domain defined');
        if (!conceptId) throw Error('No concept defined');

        const payload = getPredictionPayload(
            fields.map((obj: FieldConfiguration) => {
                return {
                    ...R.omit(['sample', 'path'], obj.source),
                    path: getSourcePath(obj.source.path),
                    targetPath: obj.target.path,
                };
            }),
            !R.isNil(domainId) ? domainId : configuration.value.domain.id,
            configuration.value.standard,
            conceptId,
        );

        const predictionResponse = await exec(ModelAPI.mappingPrediction(payload));
        return predictionResponse?.data;
    };

    return { loading, predict, cancel };
}
