import { useAxios } from '@/app/composable';
import store from '@/app/store';
import { computed, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { WishListAPI } from '../api';

export function useWishList(root: any) {
    const { exec, loading } = useAxios(true);

    const viewedAsset = ref<any>(null);

    const wishListAssetsIds = computed(() => store.state.sharing.wishListAssetsIds);

    const isInWishList = computed(() => {
        if (wishListAssetsIds.value && viewedAsset.value) {
            return wishListAssetsIds.value.includes(viewedAsset.value.id);
        }
        return true;
    });

    const wishListBtnName = computed(() => {
        return !isInWishList.value ? 'Add to Wish List' : 'Remove from Wish List';
    });

    const addAssetToWishList = (id: number) => {
        exec(WishListAPI.addAssetToWishList(id))
            .then(() => {
                store.commit.sharing.ADD_ASSET_TO_WISH_LIST(id);
            })
            .catch(() => {
                (root as any).$toastr.e('Error occured while adding asset to Wish List', 'Error');
            });
    };

    const removeAssetFromWishList = (id: number) => {
        if (wishListAssetsIds.value.includes(id))
            exec(WishListAPI.removeAssetFromWishList(id))
                .then(() => {
                    store.commit.sharing.REMOVE_ASSET_FROM_WISH_LIST(id);
                })
                .catch(() => {
                    (root as any).$toastr.e('Error occured while removing asset from Wish List', 'Error');
                });
    };

    const toggleWishListState = (id: number) => {
        if (!isInWishList.value) {
            addAssetToWishList(id);
        } else {
            removeAssetFromWishList(id);
        }
    };

    const fetchWishListAssetIds = () => {
        if (wishListAssetsIds.value.length === 0) return;

        exec(WishListAPI.getWishListAssetIds()).then((res: any) => {
            const response: { assetId: number }[] = res.data;
            const assetsIds = R.pluck('assetId', response);
            store.commit.sharing.SET_WISH_LIST_ASSETS_IDS(assetsIds);
        });
    };

    return {
        isInWishList,
        wishListBtnName,
        viewedAsset,
        loading,
        toggleWishListState,
        addAssetToWishList,
        removeAssetFromWishList,
        fetchWishListAssetIds,
    };
}
